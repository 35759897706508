.filter-model-text-heading {
  font-size: 1.5rem;
  font-weight: 700;
}
.filter-model-text {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  padding: 0.1rem 0.5rem;
}
.filter-model-text-input {
  background-color: #f6f6f6 !important;
  font-family: Poppins !important;
}
.filter-model-text-input p {
  padding-left: 1rem !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  font-family: Poppins !important;
}
.sort-text,
.age-text {
  padding-left: 1rem !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  font-family: Poppins !important;
}
.apply-button {
  width: 9rem !important;
  font-family: Poppins !important;
  border-radius: 50px !important;
  background-color: #5f22d9 !important;
  color: white !important;
}
.reset-button {
  width: 9rem !important;
  border: 2px solid black;
  border-radius: 50px !important;
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  font-family: Poppins !important;
}
.apply-button:hover {
  background-color: #5f22d9e7 !important;
}
.reset-button:hover {
  background-color: #d4d4d4 !important;
  color: #000000 !important;
}
.reset-button:active,
.apply-button:active {
  transform: scale(0.95) !important;
}
.css-edb818[aria-checked="true"],
.css-edb818[data-checked] {
  background-color: #5f22d9 !important ;
}

@media screen and (max-width: 480px) {
  .model-body-full {
    max-width: 20rem !important;
  }
  .reset-button,
  .apply-button {
    width: 8rem !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 2000px) {
  .model-body-full {
    max-width: 28rem !important;
  }
  .apply-button {
    margin-right: 0.8rem;
  }
  .reset-button,
  .apply-button {
    width: 10rem !important;
  }
}
