.team-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.25rem;
}

.team-profile-image {
  width: 12.1875rem;
  height: 12.1875rem;
  fill: var(--neutrals-scorpion, #5b5b5b);
}

.team-profile-name {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.team-profile-position {
  color: var(--neutrals-scorpion, #5b5b5b);
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
