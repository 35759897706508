.country-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.country-container-image {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 4.375rem;
  background: lightgray 50% / cover no-repeat;
}

.country-container-name {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (min-width: 300px) and (max-width: 600px) {
  .country-container-name {
    font-size: 10px;
  }
  .country-container-image {
    width: 1rem;
    height: 1rem;
    margin-left: 10px;
  }
  .country-container {
    gap: 2px;
  }
}
@media screen and (min-width: 600px) and (max-width: 850px) {
  .country-container-name {
    font-size: 1.1rem;
  }
  .country-container-image {
    width: 2.1rem;
    height: 2.1rem;
    margin-left: 5px;
  }
  .country-container {
    gap: 5px;
  }
}
