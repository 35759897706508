.login-body-container {
  display: flex;
  width: 90rem;
  flex-direction: column;
  align-items: center;
}

.login-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rectangle-container {
  width: 70rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.375rem;
  background: var(--light-mode-grey-100, #f6f6f6);
  gap: 2.5rem;
  padding: 2rem;
}

.back-btn {
  border-radius: 3.0625rem;
  position: relative;
  padding-top: 1rem !important;
}

.back-btn span {
  color: var(--light-mode-grey-300, #858585);
  text-align: center;
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-container {
  height: auto;
  width: 47rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.email-phone-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.6875rem;
}

.email-phone-container span {
  color: var(--light-mode-grey-300, #858585);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-phone-container input {
  display: flex;
  width: 39.125rem;
  height: 1.5rem;
  padding: 1.125rem 1.3125rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.9375rem;
  background: var(--light-mode-grey-200, #e5e5e5);
}

.back-icon {
  background-color: #e5e5e5;
  border-radius: 50px;
  left: -39%;
  top: 3rem;
  position: relative;
  padding: 0rem 0.4rem;
}

.back-icon span {
  font-size: 12px;
  margin-left: -8px;
}

.back-icon button {
  font-size: 10px !important;
}

.email-phone-container input::placeholder {
  color: var(--light-mode-grey-400, #5a5a5a);
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer-navigation {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
}

.social-media-links {
  display: flex;
  align-items: flex-start;
  gap: 5rem;
}

.footer-span-or-tab {
  color: var(--light-mode-grey-300, #858585);
  text-align: right;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dont-have-an-account {
  color: var(--light-mode-grey-300, #858585);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 320px) {
  .login-body-container {
    width: 20rem !important;
    padding: 0.1rem 2.1rem;
  }

  .rectangle-container {
    width: 18rem;
    gap: 1rem;
    padding: 0.2rem 1rem;
  }

  .form-container {
    width: 100% !important;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.6rem 1rem !important;
  }

  .email-phone-container span {
    font-size: 14px !important;
  }

  .form-error {
    font-size: 0.8rem !important;
  }

  .login-btn {
    gap: 0.225rem !important;
    height: 0.5rem !important;
    width: 100% !important;
  }

  .login-btn button {
    font-size: 1rem !important;
  }

  .back-btn {
    padding: 0rem 0.1rem !important;
  }

  .back-btn span {
    font-size: 2rem !important;
  }

  .footer-navigation {
    padding: 1rem 0rem !important;
    width: 100%;
    gap: 1rem;
  }

  .social-media-links {
    gap: 2rem !important;
  }

  .dont-have-an-account {
    padding: 0rem 0.2rem !important;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 0.9rem !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .login-body-container {
    width: 20rem !important;
    padding: 0.1rem 1.1rem;
  }

  .rectangle-container {
    width: 21rem;
    gap: 1.2rem;
    padding: 0.2rem 1.1rem;
  }

  .form-container {
    width: auto !important;
    gap: 0.8rem !important;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.6rem 1rem !important;
  }

  .email-phone-container span {
    font-size: 16px !important;
  }

  .form-error {
    font-size: 0.9rem !important;
  }

  .login-btn {
    margin-top: 1.2rem;
    gap: 0.225rem !important;
    height: 2.1rem !important;
    width: 9rem !important;
  }

  .login-btn button {
    font-size: 1.3rem !important;
  }

  .back-btn {
    padding: 0rem 0.1rem;
  }

  .back-btn span {
    font-size: 2rem !important;
  }

  .footer-navigation {
    padding: 1.1rem 0rem;
    width: 100%;
    gap: 1.1rem !important;
  }

  .social-media-links {
    gap: 0.8rem !important;
  }

  .dont-have-an-account {
    padding: 0rem 0.4rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 0.9rem !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 520px) {
  .login-body-container {
    width: 22rem !important;
    padding: 0.1rem 1.1rem;
  }

  .rectangle-container {
    width: 24rem;
    gap: 5px;
    padding: 0.2rem 1.2rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1rem;
  }

  .email-phone-container {
    width: 80%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.4rem 0.8rem;
  }

  .email-phone-container span {
    font-size: 1rem;
  }

  .form-error {
    font-size: 0.88rem !important;
  }

  .login-btn {
    margin-top: 1rem;
    gap: 0.225rem !important;
    height: 2rem !important;
    width: 12rem !important;
  }

  .login-btn button {
    font-size: 1rem !important;
  }

  .back-btn {
    padding: 0rem 0.1rem;
  }

  .back-btn span {
    font-size: 1.7rem;
  }

  .footer-navigation {
    padding: 1.1rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 3em;
  }

  .dont-have-an-account {
    padding: 0rem 0.6rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 520px) and (max-width: 620px) {
  .login-body-container {
    width: 24rem !important;
    padding: 0.1rem 1.1rem;
  }

  .rectangle-container {
    width: 26rem;
    gap: 0.8rem;
    padding: 0.2rem 1.38rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1rem;
  }

  .email-phone-container {
    width: 90%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.2rem;
  }

  .form-error {
    font-size: 1rem;
  }

  .login-btn {
    margin-top: 1rem;
    gap: 0.225rem !important;
    height: 3rem !important;
    width: 12rem !important;
  }

  .login-btn button {
    font-size: 1.4rem !important;
  }

  .back-btn {
    padding: 1rem 0.1rem;
  }

  .back-btn span {
    font-size: 2.2rem;
  }

  .footer-navigation {
    padding: 1rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 3rem;
  }

  .dont-have-an-account {
    padding: 0rem 0.8rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 620px) and (max-width: 768px) {
  .login-body-container {
    width: 28rem !important;
    padding: 0.1rem 1.5rem;
  }

  .rectangle-container {
    width: 25rem;
    gap: 0.8rem;
    padding: 0.2rem 1.1rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1.6rem;
  }

  .email-phone-container {
    width: 90%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.2rem;
  }

  .form-error {
    font-size: 0.91rem;
  }

  .login-btn {
    gap: 0.225rem !important;
    height: 2.7rem !important;
    width: 12rem !important;
    margin-top: 1rem;
  }

  .login-btn button {
    font-size: 1.3rem !important;
  }

  .back-btn {
    padding: 1rem 0.1rem;
  }

  .back-btn span {
    font-size: 2.2rem;
  }

  .footer-navigation {
    padding: 1rem 0rem;
    width: 100%;
    gap: 1.7rem;
  }

  .social-media-links {
    gap: 4rem;
  }

  .dont-have-an-account {
    padding: 0rem 1rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .login-body-container {
    width: 30rem !important;
    padding: 0.1rem 1.5rem;
  }

  .rectangle-container {
    width: 32rem;
    gap: 0.8rem;
    padding: 0.2rem 1.5rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1.4rem;
  }

  .email-phone-container {
    width: 90%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.2rem;
  }

  .form-error {
    font-size: 0.8rem;
  }

  .login-btn {
    margin-top: 1rem;
    gap: 0.225rem !important;
    height: 3rem !important;
    width: 12rem !important;
  }

  .login-btn button {
    font-size: 1.3rem !important;
  }

  .back-btn {
    padding: 1rem 0.1rem;
  }

  .back-btn span {
    font-size: 2rem;
  }

  .footer-navigation {
    padding: 1rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 6rem;
  }

  .dont-have-an-account {
    padding: 0rem 1rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .login-body-container {
    width: 38rem !important;
    padding: 0.1rem 1.6rem;
  }

  .rectangle-container {
    width: 50rem;
    gap: 0.8rem;
    padding: 0.2rem 1.6rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1rem;
  }

  .email-phone-container {
    width: 80%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.3rem;
  }

  .form-error {
    font-size: 0.88rem;
  }

  .login-btn {
    gap: 0.225rem !important;
    height: 3rem !important;
    width: 12rem !important;
    margin-top: 1rem;
  }

  .login-btn button {
    font-size: 1.3rem !important;
  }

  .back-btn {
    padding: 1rem 0.1rem;
  }

  .back-btn span {
    font-size: 2.2rem;
  }

  .footer-navigation {
    padding: 1rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 5rem;
  }

  .dont-have-an-account {
    padding: 0rem 1rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1240px) {
  .login-body-container {
    width: 44rem !important;
    padding: 0.1rem 1.6rem;
  }

  .rectangle-container {
    width: 55rem;
    gap: 1rem;
    padding: 0.2rem 1.6rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1rem;
  }

  .email-phone-container {
    width: 80%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.35rem;
  }

  .form-error {
    font-size: 1rem;
  }

  .login-btn {
    gap: 0.225rem !important;
    height: 3.2rem !important;
    margin-top: 1rem;
    width: 12rem !important;
  }

  .login-btn button {
    font-size: 1.33rem !important;
  }

  .back-btn {
    padding: 2rem 0.1rem;
  }

  .back-btn span {
    font-size: 2rem;
  }

  .footer-navigation {
    padding: 0.4rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 6rem;
  }

  .dont-have-an-account {
    padding: 0rem 1rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1240px) and (max-width: 1420px) {
  .login-body-container {
    width: 50rem !important;
    padding: 0.1rem 1.6rem;
  }

  .rectangle-container {
    width: 60rem;
    gap: 1rem;
    padding: 0.2rem 1.6rem;
  }

  .form-container {
    width: 100% !important;
    gap: 0.9rem;
  }

  .email-phone-container {
    width: 80%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.35rem;
  }

  .form-error {
    font-size: 1rem;
  }

  .login-btn {
    gap: 0.225rem !important;
    height: 3.2rem !important;
    margin-top: 1rem;
    width: 12rem !important;
  }

  .login-btn button {
    font-size: 1.4rem !important;
  }

  .back-btn {
    padding: 1rem 0.1rem;
  }

  .back-btn span {
    font-size: 2rem;
  }

  .footer-navigation {
    padding: 1.5rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 6rem;
  }

  .dont-have-an-account {
    padding: 0rem 1rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 1420px) and (max-width: 1520px) {
  .login-body-container {
    width: 48rem !important;
    padding: 0.1rem 1.6rem;
  }

  .rectangle-container {
    width: 44rem;
    gap: 1rem;
    padding: 0.2rem 1.6rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1.9rem;
  }

  .email-phone-container {
    width: 80%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.6rem;
  }

  .form-error {
    font-size: 1rem;
  }

  .login-btn {
    gap: 0.225rem !important;
    height: 3.2rem !important;
    width: 12rem !important;
  }

  .login-btn button {
    font-size: 1.5rem !important;
  }

  .back-btn {
    padding: 1rem 0.1rem;
  }

  .back-btn span {
    font-size: 2.26rem;
  }

  .footer-navigation {
    padding: 1.5rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 6rem;
  }

  .dont-have-an-account {
    padding: 0rem 1rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1420px) and (max-width: 1520px) {
  .login-body-container {
    width: 51rem !important;
    padding: 0.1rem 1.6rem;
  }

  .rectangle-container {
    width: 50rem;
    gap: 1rem;
    padding: 0.2rem 1.6rem;
  }

  .form-container {
    width: 100% !important;
    gap: 1.5rem;
  }

  .email-phone-container {
    width: 80%;
  }

  .email-phone-container input {
    width: 100%;
    height: auto;
    padding: 0.7rem 1rem;
  }

  .email-phone-container span {
    font-size: 1.4rem;
  }

  .form-error {
    font-size: 1rem;
  }

  .login-btn {
    gap: 0.225rem !important;
    height: 3.2rem !important;
    width: 12.5rem !important;
  }

  .login-btn button {
    font-size: 1.5rem !important;
  }

  .back-btn {
    padding: 1rem 0.1rem;
  }

  .back-btn span {
    font-size: 2.29rem;
  }

  .footer-navigation {
    padding: 1.5rem 0rem;
    width: 100%;
    gap: 2rem;
  }

  .social-media-links {
    gap: 6rem;
  }

  .dont-have-an-account {
    padding: 0rem 1rem;
    width: 100%;
    text-align: center;
  }

  .dont-have-an-account span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 200px) and (max-width: 425px) {
  .back-icon {
    top: 1rem !important;
    left: -36% !important;
    padding: 0rem 0.3rem !important;
  }
}