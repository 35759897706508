.bg-drk-color-white {
  background-color: #858585 !important;
  color: white !important;
  border-radius: 10px !important;
}

.bg-light-color-dark {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 10px !important;
}
.css-13cymwt-control {
  background-color: #f6f6f6 !important;
  border-radius: 10px !important;
}

.css-1dimb5e-singleValue {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  font-family: Poppins !important;
}
