.contact-us-hero-section {
  display: flex;
  height: 36.375rem;
  flex-direction: column;
  align-items: center;
  gap: 13.5rem;
  margin-top: -1rem;
  align-self: stretch;
  background: #5f22d9;
}

.herosection-content {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.heroseciton-heading-contact span {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.heroseciton-desc-contact p {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: 140%; 2.45rem */
}

@media only screen and (max-width: 380px) {
  .contact-us-hero-section {
    height: auto;
    gap: 4rem;
  }
  .herosection-content {
    margin-bottom: 3rem;
  }
  .heroseciton-heading-contact span {
    font-size: 2.3rem;
  }
  .heroseciton-desc-contact p {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
  }
}
@media only screen and (min-width: 381px) and (max-width: 480px) {
  .contact-us-hero-section {
    height: auto;
    gap: 4rem;
  }
  .herosection-content {
    margin-bottom: 3rem;
  }
  .heroseciton-heading-contact span {
    font-size: 2.4rem;
  }
  .heroseciton-desc-contact p {
    font-size: 1.1rem;
    padding: 0.3rem 0.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 520px) {
  .contact-us-hero-section {
    height: auto;
    gap: 4.1rem;
  }
  .herosection-content {
    margin-bottom: 3.3rem;
  }
  .heroseciton-heading-contact span {
    font-size: 2.45rem;
  }
  .heroseciton-desc-contact p {
    font-size: 1.12rem;
    padding: 0.3rem 0.5rem;
  }
}
@media only screen and (min-width: 521px) and (max-width: 767px) {
  .contact-us-hero-section {
    height: auto;
    gap: 4.1rem;
  }
  .herosection-content {
    margin-bottom: 3.43rem;
  }
  .heroseciton-heading-contact span {
    font-size: 2.6rem;
  }
  .heroseciton-desc-contact p {
    font-size: 1.22rem;
    padding: 0.3rem 0.5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .contact-us-hero-section {
    height: auto;
    gap: 4.4rem;
  }
  .herosection-content {
    margin-bottom: 5.53rem;
  }
  .heroseciton-heading-contact span {
    font-size: 2.91rem;
  }
  .heroseciton-desc-contact p {
    font-size: 1.42rem;
    padding: 0.3rem 0.5rem;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1220px) {
  .contact-us-hero-section {
    gap: 4.4rem;
  }
  .herosection-content {
    margin-bottom: 5.53rem;
  }
  .heroseciton-heading-contact span {
    font-size: 2.99rem;
  }
  .heroseciton-desc-contact p {
    font-size: 1.52rem;
    padding: 0.3rem 0.5rem;
  }
}
@media only screen and (min-width: 1221px) and (max-width: 1440px) {
  .contact-us-hero-section {
    gap: 4.67rem;
  }
  .herosection-content {
    margin-bottom: 5.63rem;
    margin-top: 10rem;
  }
  .heroseciton-heading-contact span {
    font-size: 3.7rem !important;
  }
  .heroseciton-desc-contact p {
    font-size: 1.74rem;
    padding: 0.5rem 1rem;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .contact-us-hero-section {
    gap: 6.67rem;
  }
  .herosection-content {
    margin-bottom: 7.63rem;
    margin-top: 11.4rem !important;
  }
  .heroseciton-heading-contact span {
    font-size: 3.99rem;
  }
  .heroseciton-desc-contact p {
    font-size: 1.9rem;
    padding: 0.5rem 1rem;
  }
}
