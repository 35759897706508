.team-section-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5.125rem;
  align-self: stretch;
  padding-bottom: 2rem;
}

.team-section-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
}

.team-section-heading-text {

  text-align: center;

  /* SecHead */
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.team-section-heading-subheading {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 420px) {
  .team-section-main-container {
    gap: 1.4rem;
  }

  .team-section-heading span {
    font-size: 3rem;
  }

  .team-section-heading {
    font-size: 1.2rem;
    width: 100%;
  }

  .team-profile-image {
    width: 8rem !important;
    height: 8rem !important;
  }

  .team-profile-name {
    font-size: 1.5rem !important;
  }

  .team-profile-position {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 420px) and (max-width: 520px) {
  .team-section-main-container {
    gap: 1.4rem;
  }

  .team-section-heading span {
    font-size: 3.1rem;
  }

  .team-section-heading {
    font-size: 1.3rem;
    width: 100%;
  }

  .team-profile-image {
    width: 8rem !important;
    height: 8rem !important;
  }

  .team-profile-name {
    font-size: 1.5rem !important;
  }

  .team-profile-position {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 520px) and (max-width: 1024px) {
  .team-section-main-container {
    gap: 1.4rem;
  }

  .team-section-heading span {
    font-size: 3.2em;
  }

  .team-section-heading {
    font-size: 1.35rem;
    width: 100%;
  }

  .team-profile-image {
    width: 8.1rem !important;
    height: 8.1rem !important;
  }

  .team-profile-name {
    font-size: 1.56rem !important;
  }

  .team-profile-position {
    font-size: 1.46rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1660px) {
  .team-section-main-container {
    gap: 1.45rem;
  }

  .team-section-heading span {
    font-size: 3.4em;
  }

  .team-section-heading {
    font-size: 1.5rem;
    width: 100%;
  }

  .team-profile-image {
    width: 8.4rem !important;
    height: 8.4rem !important;
  }

  .team-profile-name {
    font-size: 1.59rem !important;
  }

  .team-profile-position {
    font-size: 1.49rem;
  }
}