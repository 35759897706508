.resource-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  margin-bottom: 2rem;
}

.resource-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5625rem;
}
