.mentor-section-conatiner {
  display: flex;
  padding: 3rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 2.75rem;
  flex: 1 0 0;
  background: var(--light-mode-grey-100, #f6f6f6);
}

.mentor-section-heading {
  display: flex;
  align-items: center;
  gap: 13.875rem;
}
.mentor-section-heading p {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mentor-section-heading button {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4.1875rem;
  background: var(--primary-purple-heart, #5f22d9);
  width: 16.4375rem;
  height: 3.75rem;
  flex-shrink: 0;
  border: 0px;
}
.top-mentor-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 4.125rem;
}

.top-mentor-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.9375rem;
}
.top-mentor-heading span {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mentor-title {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mentor-viewall {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mentor-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 1rem 0rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.mentor-container::-webkit-scrollbar {
  display: none;
}
.top-mentor-first {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .top-mentor-first {
    grid-template-rows: repeat(1, minmax(250px, 1fr));
  }
}
@media screen and (max-width: 610px) {
  .top-mentor-first {
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    gap: 16px;
  }
}

@media only screen and (max-width: 380px) {
  /* Mentor Section */
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 0.5rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    width: 100%;
    gap: 3rem !important;
  }
  .mentor-section-heading p {
    font-size: 1.7rem;
  }
  .mentor-section-heading button {
    width: 12rem;
    height: 2.8rem;
    font-size: 1rem;
  }
  .top-mentor-section {
    gap: 1rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -20%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    font-size: 1.2rem;
  }
  .mentor-viewall {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 380px) and (max-width: 430px) {
  /* Mentor Section */
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 1.7rem;
  }
  .mentor-section-heading button {
    width: 12rem;
    height: 2.8rem;
    font-size: 1rem;
  }
  .top-mentor-section {
    gap: 1rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -30%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    font-size: 1.2rem;
  }
  .mentor-viewall {
    font-size: 1.3rem;
  }
  .top-mentor-first {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 0.1rem 1rem;
  }
}
@media only screen and (min-width: 430px) and (max-width: 520px) {
  .mentor-each-card-liked-icon {
    margin-left: 40% !important;
  }
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 1.77rem;
  }
  .mentor-section-heading button {
    width: 12rem;
    height: 2.8rem;
    font-size: 1rem;
  }
  .top-mentor-section {
    gap: 1rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -35%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    font-size: 1.2rem;
  }
  .mentor-viewall {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 520px) and (max-width: 680px) {
  .mentor-each-card-liked-icon {
    margin-left: 0 !important;
  }
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 1.8rem;
  }
  .mentor-section-heading button {
    width: 16rem;
    height: 2.8rem;
    font-size: 1.3rem;
  }
  .top-mentor-section {
    gap: 1rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -40%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    font-size: 1.2rem;
  }
  .mentor-viewall {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 680px) and (max-width: 768px) {
  .mentor-each-card-liked-icon.mentor {
    margin-left: 20% !important;
  }
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1rem;
  }

  .mentor-section-heading {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 2.1rem;
  }
  .mentor-section-heading button {
    width: 10rem;
    height: 2.8rem;
    font-size: 1rem;
  }

  .top-mentor-heading {
    position: relative;
    left: -40%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    font-size: 1.2rem;
  }
  .top-mentor-section {
    gap: 1rem;
  }

  .mentor-viewall {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .top-mentor-first {
    gap: 2rem;
  }
  .mentor-each-card-liked-icon.mentor {
    margin-left: 20% !important;
  }
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 2.3rem;
  }
  .mentor-section-heading button {
    width: 11rem;
    height: 2.8rem;
    font-size: 1rem;
  }

  .top-mentor-heading {
    position: relative;
    left: -40%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    font-size: 1.5rem;
  }
  .top-mentor-section {
    gap: 1.2rem;
  }
  .mentor-viewall {
    font-size: 1.49rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .top-mentor-first {
    gap: 2.5rem;
  }
  /* .mentor-each-card-container {
    padding: 2rem !important;
  } */
  /* .mentor-each-card-liked-icon {
    margin-left: 15% !important;
  } */
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 2.4rem;
  }
  .mentor-section-heading button {
    width: 12rem;
    height: 2.8rem;
    font-size: 1rem;
  }

  .top-mentor-section {
    gap: 1.2rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -40%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    font-size: 1.5rem;
  }
  .mentor-viewall {
    font-size: 1.49rem;
  }
}

@media only screen and (min-width: 1220px) and (max-width: 1440px) {
  .top-mentor-first {
    gap: 3rem;
  }
  /* .mentor-each-card-container {
    padding: 2.2rem !important;
  } */
  /* .mentor-each-card-liked-icon {
    margin-left: 15% !important;
  } */
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 2.6rem;
  }
  .mentor-section-heading button {
    width: 14rem;
    height: 2.8rem;
    font-size: 1.2rem;
  }
  .top-mentor-section {
    gap: 1.23rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -40%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    text-align: center;
    font-size: 1.5rem;
  }
  .mentor-viewall {
    font-size: 1.59rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1520px) {
  .top-mentor-first {
    gap: 4rem;
  }
  /* .mentor-each-card-container {
    padding: 3.1rem !important;
  } */
  /* .mentor-each-card-liked-icon {
    margin-left: 15% !important;
  } */
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1rem 1.2rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 2.6rem;
  }
  .mentor-section-heading button {
    width: 14rem;
    height: 2.8rem;
    font-size: 1.2rem;
  }
  .top-mentor-section {
    gap: 1.23rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -40%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    text-align: center;
    font-size: 1.5rem;
  }
  .mentor-viewall {
    font-size: 1.59rem;
  }
}
@media only screen and (min-width: 1520px) and (max-width: 1750px) {
  .top-mentor-first {
    gap: 5rem;
  }
  /* .mentor-each-card-container {
    padding: 4rem !important;
  } */
  /* .mentor-each-card-liked-icon {
    margin-left: 15% !important;
  } */
  .mentor-section-conatiner {
    width: 100% !important;
    padding: 1.3rem 1.2rem;
  }
  .mentor-section-heading {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    width: 100%;
    gap: 2rem !important;
  }
  .mentor-section-heading p {
    font-size: 3.5rem;
  }
  .mentor-section-heading button {
    width: 16rem;
    height: 3rem;
    font-size: 1.3rem;
  }
  .top-mentor-section {
    gap: 2.23rem;
  }
  .top-mentor-heading {
    position: relative;
    left: -40%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0;
  }
  .top-mentor-heading span {
    text-align: center;
    font-size: 1.5rem;
  }
  .mentor-viewall {
    font-size: 2rem;
  }
}
