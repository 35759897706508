.event-type-container {
  display: flex;

  padding-left: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}

.event-type-heading {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 2.1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-cards {
  display: flex;

  align-items: center;
  gap: 2rem;
  justify-content: center;
}
.arrows {
  display: flex;
  align-items: flex-start;
  gap: 1.375rem;
  padding: 1;
}
.arrow1-left,
.arrow2-right {
  cursor: pointer;
}

@media only screen and (max-width: 320px) {
  .event-type-heading {
    font-size: 1.34rem;
  }
  .arrows {
    gap: 0.7rem;
  }
  .arrow1-left,
  .arrow2-right {
    width: 30px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .event-type-heading {
    font-size: 1.44rem;
  }
  .arrows {
    gap: 0.8rem;
  }
  .arrow1-left,
  .arrow2-right {
    width: 34px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .event-type-heading {
    font-size: 1.64rem;
  }
  .arrows {
    gap: 1rem;
  }
  .arrow1-left,
  .arrow2-right {
    width: 40px;
  }
}
