.not-found-page-title {
  color: #5f22d9 !important ;
  font-size: 4.4rem !important;
  font-weight: 500 !important;
  font-family: Poppins !important;
  font-style: italic !important;
}
.not-found-page-sub-title {
  color: #000000 !important ;
  font-size: 3.2rem !important;
  font-weight: 600 !important;
  font-family: Poppins !important;
}

.not-found-page-description {
  color: #000000 !important ;
  font-size: 2rem !important;
  font-weight: 500 !important;
  font-family: Poppins !important;
  text-align: center;
}

.access-back-btn {
  color: #ffffff !important ;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  font-family: Poppins !important;
  text-align: center;
  border-radius: 40px !important;
  padding: 0.5rem 2rem !important;
  background-color: #5f22d9 !important;
}

@media only screen and (max-width: 320px) {
  .not-found-page-title {
    font-size: 2.3rem !important;
  }
  .not-found-page-sub-title {
    font-size: 1.8rem !important;
  }
  .not-found-page-description {
    font-size: 1.3rem !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 425px) {
  .not-found-page-title {
    font-size: 2.5rem !important;
  }
  .not-found-page-sub-title {
    font-size: 2rem !important;
  }
  .not-found-page-description {
    font-size: 1.45rem !important;
  }
}
