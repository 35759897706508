.hero-section-main-container{
    display: flex;
height: 61.375rem;
flex-direction: column;
align-items: center;
gap: 13.5rem;
align-self: stretch;
background: #5F22D9;
}

.find-mentor-btn{
width: 24.4375rem;
height: 3.75rem;
padding: 1.0625rem 7.6875rem;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 4.1875rem;
background: var(--light-mode-white, #FFF);
color: var(--light-mode-black, #000);
text-align: center;
font-family: Poppins;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: normal;
cursor: pointer;
}