/* all the css applied on the form in forms folder are linked from here!! */
.contact-section-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.625rem;
}

.contact-us-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us-heading-main-heading {
  color: var(--primary-purple-heart, #5f22d9);
  /* SecHead */
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contact-us-heading-sub {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 3.15rem */
}

.contact-us-content-conatiner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* gap: 20.5625rem; */
}

.contact-details {
  display: flex;
  /* padding-top: 103px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 2.1875rem;
}

.form-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.0625rem;
}

.contact-phone {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.contact-email {
  display: flex;
  align-items: center;
  gap: 1.875rem;
}

.contact-phone-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -0.3125rem;
}

.phone-icon {
  width: 2.3125rem;
  height: 2.5rem;
}

.contact-us-phone-text {
  color: var(--base-black, #000);
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: bold;
  line-height: 180%; /* 3.6rem */
}

.contact-us-phone-number {
  color: var(--base-black, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  line-height: 180%; /* 2.7rem */
}
.fullname-input {
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
}
.email-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}
.message-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.fullname-input-first-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.fullname-input-last-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.first-name-input {
  display: flex;
  width: 18.9375rem;
  padding: var(--gap-16, 1rem) 0.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: var(--light-mode-grey-200, #e5e5e5);
  border: 0px;
  font-size: large;
  font-family: Poppins;
}

.emailinput {
  display: flex;
  padding: var(--gap-16, 1rem) 0.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: var(--light-mode-grey-200, #e5e5e5);
  border: 0px;
  font-size: large;
  font-family: Poppins;
}

.messageinput {
  display: flex;
  width: 100% !important;
  height: auto;
  min-height: 10rem !important;
  padding: 0.9375rem 0.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: var(--light-mode-grey-200, #e5e5e5);
  border: 0px;
  font-size: large;
  font-family: Poppins;
}

.messageinput::placeholder {
  position: absolute;
  top: 1rem;
  font-family: Poppins;
  font-size: 1.5rem;
}

.input-label {
  color: var(--base-black, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 180%; /* 2.7rem */
}

.btn-submit-enter {
  display: flex;
  width: 11rem;
  height: 3rem;
  font-size: 1.4rem;
  padding: 1rem 2.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.625rem;
  background: var(--primary-purple-heart, #5f22d9);
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  overflow: hidden;
  position: relative;
  transition: background-color 0.3s ease;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 2rem;
}
.btn-submit-enter:active {
  background-color: #5308e8 !important;
}

.form-error {
  font-weight: 500;
  color: red;
  font-family: Poppins;
}
.form-section-container-full-side {
  padding: 1rem 1rem;
}
@media only screen and (max-width: 320px) {
  .fullname-input-first-name {
    padding: 0.12rem 0.12rem;
  }
  .input-label {
    font-size: 16px;
    font-weight: 500;
  }
  .first-name-input,
  .emailinput {
    width: 100% !important;
    font-size: medium !important;
    padding: 0.5rem 1rem !important;
  }

  .messageinput {
    min-height: 2rem !important;
  }

  .first-name-input::placeholder,
  .messageinput::placeholder,
  .emailinput {
    font-size: 14px !important;
  }
  .btn-submit-enter {
    width: 8rem;
    height: 2rem;
    font-size: 1.3rem;
  }
  .contact-us-heading {
    gap: 1rem;
  }
  .contact-us-heading-main-heading {
    font-size: 2.3rem;
  }
  .contact-us-heading-sub {
    font-size: 1.3rem;
  }
  .contact-details {
    gap: 1rem;
  }
  .contact-phone,
  .contact-email {
    gap: 4rem;
  }
  .contact-us-phone-text {
    font-size: 1rem;
  }
  .contact-us-phone-number {
    font-size: 0.8rem;
  }
  .phone-icon {
    width: 1rem;
    height: 1rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 420px) {
  .fullname-input-first-name {
    padding: 0.4rem 0.3rem;
  }
  .input-label {
    font-size: 1rem;
  }
  .first-name-input,
  .emailinput {
    width: 100% !important;
    font-size: medium !important;
    padding: 0.7rem 0.7rem !important;
  }
  .messageinput {
    min-height: 2.4rem !important;
  }
  .first-name-input::placeholder,
  .messageinput::placeholder,
  .emailinput {
    font-size: 15px !important;
  }
  .btn-submit-enter {
    width: 8rem;
    height: 2.2rem;
    font-size: 1.3rem;
  }
  .contact-us-heading {
    gap: 1rem;
  }
  .contact-us-heading-main-heading {
    font-size: 2.3rem;
  }
  .contact-us-heading-sub {
    font-size: 1.3rem;
  }
  .contact-details {
    gap: 1rem;
  }
  .contact-phone,
  .contact-email {
    gap: 4rem;
  }
  .contact-us-phone-text {
    font-size: 1.2rem;
  }
  .contact-us-phone-number {
    font-size: 0.9rem;
  }
  .phone-icon {
    width: 1.4rem;
    height: 1.4rem;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .fullname-input-first-name {
    padding: 0.4rem 0.3rem;
  }
  .input-label {
    font-size: 1rem;
  }
  .first-name-input,
  .emailinput {
    width: 100% !important;
    font-size: medium !important;
    padding: 0.7rem 0.7rem !important;
  }
  .messageinput {
    min-height: 2.44rem !important;
  }
  .first-name-input::placeholder,
  .messageinput::placeholder,
  .emailinput {
    font-size: 15.3px !important;
  }
  .btn-submit-enter {
    width: 9rem;
    height: 2.2rem;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 420px) and (max-width: 480px) {
  .contact-us-heading {
    gap: 1.2rem;
  }
  .contact-us-heading-main-heading {
    font-size: 2.4rem;
  }
  .contact-us-heading-sub {
    font-size: 1.3rem;
  }
  .contact-details {
    gap: 1rem;
  }
  .contact-phone,
  .contact-email {
    gap: 6rem;
  }
  .contact-us-phone-text {
    font-size: 1.4rem;
  }
  .contact-us-phone-number {
    font-size: 0.99rem;
  }
  .phone-icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .contact-us-heading {
    gap: 1rem;
  }
  .contact-us-heading-main-heading {
    font-size: 2rem;
  }
  .contact-us-heading-sub {
    font-size: 1.1rem;
  }
  .contact-details {
    gap: 2rem;
  }
  .contact-phone,
  .contact-email {
    gap: 1rem;
  }
  .contact-us-phone-text {
    font-size: 1rem;
  }
  .contact-us-phone-number {
    font-size: 0.89rem;
  }
  .phone-icon {
    width: 1rem;
    height: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .contact-us-heading {
    gap: 1.3rem;
  }
  .contact-us-heading-main-heading {
    font-size: 2.6rem;
  }
  .contact-us-heading-sub {
    font-size: 1.6rem;
  }
  .contact-details {
    gap: 2.45rem;
  }
  .contact-phone,
  .contact-email {
    gap: 2rem;
  }
  .contact-us-phone-text {
    font-size: 1.7rem;
  }
  .contact-us-phone-number {
    font-size: 0.99rem;
  }
  .phone-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contact-us-heading {
    gap: 1.5rem;
  }
  .contact-us-heading-main-heading {
    font-size: 3.5rem;
  }
  .contact-us-heading-sub {
    font-size: 1.9rem;
  }
  .contact-details {
    gap: 2.55rem;
  }
  .contact-phone,
  .contact-email {
    gap: 2.4rem;
  }
  .contact-us-phone-text {
    font-size: 1.75rem;
  }
  .contact-us-phone-number {
    font-size: 1.2rem;
  }
  .phone-icon {
    width: 1.7rem;
    height: 1.7rem;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1820px) {
  .contact-us-heading {
    gap: 1.5rem;
  }
  .contact-us-heading-main-heading {
    font-size: 4rem;
  }
  .contact-us-heading-sub {
    font-size: 2rem;
  }
  .contact-details {
    gap: 2.55rem;
  }
  .contact-phone,
  .contact-email {
    gap: 4rem;
  }
  .contact-us-phone-text {
    font-size: 1.95rem;
  }
  .contact-us-phone-number {
    font-size: 1.25rem;
  }
  .phone-icon {
    width: 1.9rem;
    height: 1.9rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 880px) {
  .fullname-input-first-name {
    padding: 0.4rem 0.3rem;
  }
  .input-label {
    font-size: 1rem;
    max-width: 300px;
  }
  .rows-inputs-full-screen {
    align-items: center !important;
  }
  .first-name-input {
    width: 100% !important;
    max-width: 300px;
    font-size: medium !important;
    padding: 0.7rem 0.7rem !important;
  }
  .messageinput {
    min-height: 2.8rem !important;
  }
  .first-name-input::placeholder,
  .messageinput::placeholder {
    font-size: 15.6px !important;
  }
}
@media only screen and (min-width: 881px) and (max-width: 1024px) {
  .fullname-input-first-name {
    padding: 0.4rem 0.3rem;
  }
  .input-label {
    font-size: 1.2rem;
    max-width: 300px;
    padding-left: 0.5rem;
  }
  .rows-inputs-full-screen {
    align-items: center !important;
  }

  .first-name-input {
    width: 100% !important;
    font-size: medium !important;
    padding: 0.7rem 0.7rem !important;
  }
  .messageinput {
    min-height: 2.86rem !important;
  }
  .first-name-input::placeholder,
  .messageinput::placeholder {
    font-size: 15.65px !important;
  }
  .btn-submit-enter {
    width: 11rem;
    height: 3rem;
    font-size: 1.4rem;
  }
}

.dark-mode-inputs {
  color: white !important;
  background: var(--light-mode-grey-300, #858585) !important;
}

.dark-mode-inputs::placeholder {
  color: white !important;
}
