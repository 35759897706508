.pricing-headling-text-headline {
  color: var(--base-black, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 3.4rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.pricing-headling-text-bold {
  color: var(--base-purple-heart, #5f22d9) !important;
  font-weight: 550 !important;
}
.pricing-headling-text-description {
  color: var(--base-black, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}

.pricing-page-tabs-heading-text {
  color: var(--base-black, #000) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 1.6rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}
