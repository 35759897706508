.request-mentor-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.request-button {
  justify-content: start !important;
}
.mentor-combined {
  display: flex;
  flex-direction: row;
  padding: 4rem;
}
.mentor-container-second {
  padding-right: 0px;
  color: var(--base-black, #000);
  font-family: Poppins;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.color-change {
  color: #5f22d9;
  font-weight: bold;
}
.text-matter {
  color: var(--base-black, #000);
  font-family: Poppins;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line !important;
  text-align: start !important;
  margin-bottom: 3.2rem;
}
.request-mentor-content-footer {
  text-align: center;
  color: var(--base-black, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  white-space: pre-line !important;
  text-align: start !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
  align-self: stretch;
  margin-bottom: 10px !important;
}
.request-mentor-content-footer-button {
  display: flex;
  padding: 1.0625rem 3.25rem;
  align-items: center;
  justify-content: center;
  gap: -0.5625rem;
  border-radius: 4.1875rem;
  background: var(--primary-purple-heart, #5f22d9) !important;
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.request-mentor-image {
  height: 35.875rem;
  flex-shrink: 0;
  padding: 5px;
}

.smiling-man-rectangle-background {
  width: 12.76625rem;
  height: 12.76625rem;
  transform: rotate(180deg);
  flex-shrink: 0;
}

.smiling-man-standing {
  width: 22.82413rem;
  height: 30.875rem;
  flex-shrink: 0;
  border-radius: 15.25rem 15.25rem 0rem 0rem;
  background: var(--primary-lavender-floral, #ac73ff);
}

@media screen and (min-width: 250px) and (max-width: 550px) {
  .mentor-combined {
    flex-direction: column;
    padding: 5px !important;
  }

  .text-matter {
    margin-bottom: 5px !important;
  }
  .request-button {
    justify-content: center !important;
    text-align: center !important;
  }
}
@media screen and (min-width: 550px) and (max-width: 1023px) {
  .mentor-combined {
    flex-direction: row;
  }
  .text-matter {
    margin-bottom: 10px !important;
  }
  .request-mentor-content-footer-button {
    padding: 1rem !important;
  }
}
@media (max-width: 500px) {
  .request-mentor-content-footer {
    text-align: center;
    font-size: 1rem !important;
  }
  .hero-section-main-conatiner {
    gap: 0 !important;
  }

  .request-mentor-content-footer-button {
    height: 2rem;
    padding: 0rem 2.6875rem;
    font-size: 1rem;
  }
  .request-mentor-content {
    width: auto;
  }
  .text-matter {
    font-size: 1.4rem;
  }
  .request-mentor-content-footer {
    font-size: 1rem !important;
  }
  .request-mentor-image {
    height: inherit;
  }
  .smiling-man-standing {
    height: auto;
  }
  .request-mentor-content-footer button {
    max-width: 200px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .text-matter-content {
    max-width: 700px;
    margin-bottom: 2rem !important;
  }
  .request-mentor-content-footer-main {
    max-width: 700px;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .text-matter-content {
    max-width: 700px;
    font-size: 1rem;
  }
  .text-matter {
    font-size: 2.5rem !important;
  }
  .request-mentor-content-footer {
    font-size: 1.8rem !important;
  }
  .request-mentor-content-footer-main {
    max-width: 700px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1024px) and (max-width: 1600px) {
  .mentor-container-second {
    padding: 1rem;
    font-size: 3.2rem !important;
    line-height: 150%;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1720px) {
  .text-matter {
    font-size: 2.7rem !important;
  }
  .request-mentor-content-footer {
    font-size: 2rem !important;
  }
  .hero-section-footer p {
    font-size: 2.1rem !important;
  }
  .hero-section-headline-tabs ul li {
    font-size: 2rem;
  }
  .text-matter-content {
    max-width: 700px;
    font-size: 1rem;
  }
  .mentor-combined {
    gap: 1rem;
  }

  .request-mentor-content-footer-main {
    max-width: 700px;
    margin-right: 5px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (min-aspect-ratio: 16/10) and (min-width: 1720px) and (max-width: 2560px) {
  .mentor-container-second {
    max-width: 40rem;
    margin-left: 1rem;
  }
  .mentor-combined {
    gap: 2rem;
  }
  .request-mentor-content-footer {
    margin-bottom: 2rem !important;
  }
  .request-button {
    margin-top: 2rem;
  }
  .request-mentor-content-footer-button {
    padding: 2rem;
    font-size: 3rem;
    letter-spacing: normal;
  }
}
@media screen and (min-width: 690px) and (max-width: 768px) {
  .request-mentor-content-footer-main {
    margin-bottom: 1rem;
  }
  .mentor-combined {
    padding: 1rem !important;
  }
  .request-mentor-content-footer-button {
    font-size: 1rem;
    padding: 0.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 821px) {
  .request-mentor-content-footer-main {
    margin-bottom: 2rem;
    max-width: 700px;
  }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
  .mentor-combined {
    margin-bottom: -6rem;
  }
}
@media screen and (min-width: 785px) and (max-width: 920px) {
  .mentor-combined {
    padding: 2rem !important;
  }
  .text-matter {
    margin-bottom: 1.3rem !important;
  }
  .smiling-man-standing {
    width: 20rem;
    height: 28rem;
  }
  .request-mentor-content-footer-main {
    margin-bottom: 2rem !important;
  }
  .mentor-combined {
    margin-bottom: -4rem !important;
  }
}
@media screen and (min-width: 920px) and (max-width: 1023px) {
  .mentor-combined {
    margin-bottom: -3rem;
    gap: 2rem;
  }

  .request-mentor-content-footer-main {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 767px) and (max-width: 768px) {
  .request-mentor-section {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 768px) {
  .mentor-container-second {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .request-mentor-content-footer-main {
    margin-bottom: 1rem !important;
  }
  .mentor-combined {
    margin-top: 2rem !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
  .smiling-man-standing {
    width: 18rem !important;
    height: 28rem !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 550px) {
  .mentor-container-second {
    padding: 1rem;
  }
}
