.youtube-image-main-container {
  background: #f2f2f2;
}
.youtube-image-text {
  padding: 0.5rem 0.5rem;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.youtube-image-main-container img {
  border-radius: 0.375rem;
  background: lightgray 0px 0px / 100% 100.635% no-repeat;
}
@media screen and (max-width: 480px) {
  .youtube-image-text {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 481px) and (max-width: 769px) {
  .youtube-image-text {
    font-size: 1.6rem;
  }
}
