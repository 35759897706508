.community-hero-section {
  background-color: white;
  margin-bottom: 20px;
}

.community-hero-section-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.community-hero-section-main {
  display: flex;
  flex-direction: column;
}
.community-hero-section-desc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* gap: 10rem !important; */
}
.motion-text {
  max-width: 500px;
}
.Image-Responsive {
  max-width: 100%;
  height: auto;
}

.image-community {
  width: 100%;
  height: auto;
}

.community-hero-section-text-bold {
  color: var(--primary-purple-heart, #5f22d9);
  font-family: Poppins;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.06rem;
}

.community-hero-section-text-light {
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: block;
  letter-spacing: -0.06rem;
  padding-top: 1rem;
}

.community-hero-section-footer {
  padding: 1rem 4rem;
  width: 100%;
  margin-bottom: 2rem;
}
.community-hero-section-footer-text {
  width: 100%;
  padding: 1rem 2rem;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: block;
  letter-spacing: -0.06rem;
}

.community-hero-section-footer-button {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 3.625rem;
  padding: 10px;
  transition: background-color 0.3s;
  background-color: var(--primary-purple-heart, #5f22d9);
}

.community-hero-section-footer-button:hover {
  background-color: #0056b3;
}

.motion-text {
  padding-bottom: 2.4rem;
  width: 100%;
  text-align: center;
}

.community-about-section {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.community-about-section-content {
  flex: 1;
}

.community-about-section-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.community-about-section-text-section {
  text-align: center;
}
.community-about-section-text {
  font-family: Poppins;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: block;
  letter-spacing: -0.06rem;
  padding-top: 1rem;
}
.community-about-section-text b {
  font-family: Poppins;
  font-size: 1.85rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: block;
  letter-spacing: -0.06rem;
  padding-top: 1rem;
}
.community-image-container {
  text-align: center;
  margin-top: 20px;
}

.community-image-file {
  max-width: 100%;
  height: auto;
}

.community-about-section-text-footer {
  text-align: center;
  font-family: Poppins;
  font-size: 1.64rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: block;
  letter-spacing: -0.06rem;
  padding-top: 1rem;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .community-about-section {
    flex-direction: column;
  }

  .community-about-section-text-section,
  .community-image-container {
    margin: 20px 0;
  }
}

@media (max-width: 480px) {
  .community-about-section-text-section,
  .community-image-container {
    margin: 10px 0;
  }
}
@media screen and (max-width: 420px) {
  .community-hero-section-text-bold {
    font-size: 1.42rem;
    max-width: 500px;
  }
}
@media only screen and (max-width: 420px) {
  .motion-text {
    padding-bottom: 1rem;
  }

  .community-hero-section-text-light {
    font-size: 1.18rem;
  }
  .community-hero-section-footer {
    padding: 0;
    width: 100%;
  }
  .community-hero-section-footer-text {
    padding: 1rem 0.2rem;
    display: block;
    line-height: 150%;
    max-width: 400px;
    text-align: center;
    font-size: 1.2rem;
  }
  .community-hero-section-footer-button {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .community-about-section-text {
    font-size: 1.17rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
  .community-about-section-text b {
    font-size: 1.19rem;
  }
  .community-about-section-text-footer {
    font-size: 1.17rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
  .community-about-section-text-footer b {
    font-size: 1.19rem;
  }
}

@media only screen and (min-width: 420px) and (max-width: 520px) {
  .community-hero-section-text-bold {
    font-size: 1.29rem;
    max-width: 400px;
  }

  .community-hero-section-text-light {
    font-size: 1.2rem;
  }
  .community-hero-section-footer {
    padding: 0;
    width: 100%;
  }
  .community-hero-section-footer-text {
    padding: 1rem 0.2rem;
    display: block;
    line-height: 150%;
    max-width: 400px;
    text-align: center;
    font-size: 1.29rem;
  }
  .community-hero-section-footer-button {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .community-about-section-text,
  .community-about-section-text-footer {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  }
  .community-about-section-text b,
  .community-about-section-text-footer b {
    font-size: 1.22rem;
  }
}

@media only screen and (min-width: 520px) and (max-width: 620px) {
  .motion-text {
    max-width: 300px;
    text-align: start;
    padding-top: 2rem;
  }
  .community-hero-section-text-bold {
    font-size: 1.24rem;
  }
  .responsove-grid-content {
    display: flex !important;
    justify-content: space-between !important;
  }
  .community-hero-section-text-light {
    font-size: 1.2rem;
  }
  .Image-Responsive {
    width: 50% !important;
    height: 20% !important;
  }
  .community-hero-section-footer {
    padding: 0;
    width: 100%;
  }
  .community-hero-section-footer-text {
    padding: 1rem 0.2rem;

    line-height: 150%;

    text-align: center;
    font-size: 1.29rem;
  }
  .community-hero-section-footer-button {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .community-about-section-text,
  .community-about-section-text-footer {
    font-size: 1.24rem;
  }
  .community-about-section-text b,
  .community-about-section-text-footer b {
    font-size: 1.247rem;
  }
}

@media only screen and (min-width: 620px) and (max-width: 768px) {
  .motion-text {
    max-width: 500px;
    text-align: start;
    padding-top: 2rem;
  }
  .community-hero-section-text-bold {
    font-size: 1.34rem;
  }
  .responsove-grid-content {
    display: flex !important;
    justify-content: space-between !important;
  }
  .community-hero-section-text-light {
    font-size: 1.2rem;
  }
  .Image-Responsive {
    width: 50% !important;
    height: 20% !important;
  }
  .community-hero-section-footer {
    padding: 0;
    width: 100%;
  }
  .community-hero-section-footer-text {
    padding: 1rem 0.2rem;

    line-height: 150%;

    text-align: center;
    font-size: 1.29rem;
  }
  .community-hero-section-footer-button {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .community-about-section-desc {
    flex-direction: row !important;
  }
  .community-image-container {
    margin-left: -5rem !important;
  }
  .community-about-section-text-section {
    padding-left: 1rem;
    max-width: 410px !important;
    text-align: start;
  }
  .community-about-section-text-footer {
    padding: 2rem;
  }
  .community-about-section-text,
  .community-about-section-text-footer {
    font-size: 1rem !important;
  }
  .community-about-section-text b,
  .community-about-section-text-footer b {
    font-size: 1.11rem !important;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .motion-text {
    max-width: 450px;
    text-align: start;
  }
  .community-hero-section-text-bold {
    font-size: 2rem;
  }

  .community-hero-section-text-light {
    font-size: 1.42rem;
  }
  .community-hero-section-footer {
    padding: 0;
    width: 100%;
  }
  .community-hero-section-footer-text {
    padding: 1rem 0.2rem;

    line-height: 150%;

    text-align: center;
    font-size: 1.29rem;
  }
  .community-hero-section-footer-button {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .community-about-section-desc {
    flex-direction: row !important;
  }
  .community-image-container {
    margin-left: -5rem !important;
  }
  .community-about-section-text-section {
    padding-left: 1rem;
    max-width: 450px !important;
    text-align: start;
  }
  .community-about-section-text-footer {
    padding: 2rem;
  }

  .community-about-section-text,
  .community-about-section-text-footer {
    font-size: 1.37rem;
  }
  .community-about-section-text b,
  .community-about-section-text-footer b {
    font-size: 1.38rem;
    padding: 0;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1320px) {
  .css-1mxb9jq {
    grid-template-columns: 1fr 1fr !important;
  }
  .motion-text {
    max-width: 500px;
    text-align: start;
  }
  .community-hero-section-text-bold {
    font-size: 3rem;
  }
  .responsove-grid-content {
    margin-top: 2rem !important;
  }
  .community-hero-section-text-light {
    font-size: 1.6rem;
  }
  .community-hero-section-footer {
    padding: 0;
    width: 100%;
  }
  .community-hero-section-footer-text {
    padding: 1rem 0.2rem;
    line-height: 150%;
    text-align: center;
    font-size: 1.6rem;
  }
  .community-hero-section-footer-button {
    font-size: 1.2rem;
    padding: 1rem;
  }
  .community-about-section-desc {
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .community-about-section-text-section {
    padding-left: 2rem;
    padding-top: 2rem;
    max-width: 700px !important;
    text-align: start;
  }
  .community-about-section-text-footer {
    padding: 2rem;
  }
  .community-about-section-text,
  .community-about-section-text-footer {
    font-size: 2rem;
  }
  .community-about-section-text b,
  .community-about-section-text-footer b {
    font-size: 2.1rem;
    padding: 0;
  }
  .community-image-container {
    margin-left: -5rem;
  }
}
@media only screen and (min-width: 1320px) and (max-width: 1700px) {
  .css-1mxb9jq {
    grid-template-columns: 1fr 1fr !important;
  }
  .community-image-container {
    margin-left: -6rem;
  }
  .motion-text {
    max-width: 600px;
    text-align: start;
  }
  .community-hero-section-text-bold {
    font-size: 2.6rem;
  }
  .responsove-grid-content {
    margin-top: 2rem !important;
  }
  .community-hero-section-text-light {
    font-size: 1.7rem;
  }
  .community-hero-section-footer {
    padding: 0;
    width: 100%;
  }
  .community-hero-section-footer-text {
    padding: 1rem 0.2rem;
    line-height: 150%;
    text-align: center;
    font-size: 1.6rem;
  }
  .community-hero-section-footer-button {
    font-size: 1.2rem;
    padding: 1rem;
  }
  .community-about-section-desc {
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  .community-about-section-text-section {
    padding-left: 2rem;
    padding-top: 4rem;
    max-width: 900px !important;
    text-align: start;
  }
  .community-about-section-text-footer {
    padding: 2rem;
  }
  .community-about-section-text,
  .community-about-section-text-footer {
    font-size: 1.6rem;
  }
  .community-about-section-text b,
  .community-about-section-text-footer b {
    font-size: 1.7rem;
    padding: 0;
  }
}
