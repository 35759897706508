.comments-sort-menu {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
}

.comments-sort-menu-itm {
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 21.6px */
}

.coment-usr-name-blogs {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

aside.EmojiPickerReact.epr-main {
  max-width: 100% !important;
  max-height: 300px;
  height: 450px;
  width: 100% !important;
}

.EmojiPickerReact .Flex.FlexRow {
  display: none !important;
}

textarea::-moz-resizer {
  display: none;
}

textarea::-webkit-resizer {
  display: none;
}

textarea::-ms-resizer {
  display: none;
}

textarea::resizer {
  display: none;
}

.liked-btn-trans {
  transform: scale(0.8);
}
