.dashboard-icon {
  width: 100% !important;
}

.dashboard-sidebar-main::-webkit-scrollbar {
  width: 4px !important;
}
.dashboard-sidebar-main::-webkit-scrollbar-thumb {
  background-color: transparent !important;
  border-radius: 5px;
}
.dashboard-sidebar-main::-webkit-scrollbar-track {
  background-color: transparent;
}
.dark-side-bar::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.small-sidebar-webkit-scroll::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.dashboard-sidebar-text-name {
  align-self: stretch;
  color: var(--gunmetal, #000000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 450;
}
.dashboard-sidebar-text-name:active {
  color: white !important;
}
.dashboard-home-btn-text {
  align-self: stretch;
  color: var(--gunmetal, #ffffff);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem !important;
  font-style: normal;
}
@media screen and (max-width: 320px) {
  .dashboard-home-btn-text {
    font-size: 12px !important;
  }
  .dashboard-sidebar-text-name {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 610px) {
  .sidebar-smaall-screen-searchbar {
    display: block;
  }
}
@media screen and (min-width: 610px) {
  .sidebar-smaall-screen-searchbar {
    display: none !important;
  }
}
.mentee-sidebar-small-home-link-text {
  font-size: 1.1rem;
  color: white !important;
  font-family: Poppins;
  font-weight: 500;
}
