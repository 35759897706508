.suggestion-card-container {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;

  gap: 1.5rem;
  border-radius: 0.5rem;
}

.suggestion-name-date {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.suggestion-desc {
  text-align: justify;
  font-family: Helvetica Now Display;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.suggestion-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.suggestion-name-text {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}
.suggestion-name-profile {
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.suggestion-follow-btn {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}
@keyframes hoverEffect {
  0% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes clickEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.suggestion-follow-btn:hover {
  animation-name: hoverEffect;
  animation-fill-mode: forwards;
}

.suggestion-follow-btn:active {
  animation-name: clickEffect;
  animation-fill-mode: forwards;
}
@media screen and (max-width: 768px) {
  .suggestion-card-container {
    padding: 1rem;
  }
  .suggestion-name-text {
    font-size: 1rem;
  }
  .suggestion-name-profile {
    font-size: 0.9rem;
  }
  .suggetion-profile-image {
    width: 50px;
    height: 50px;
  }
  .suggestion-follow-btn {
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
  }

  .suggestion-desc {
    font-size: 1.2rem;
  }
  .btn-container-suggest {
    padding: 1rem 0.2rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .suggestion-card-container {
    padding: 1.3rem;
  }
  .suggestion-name-text {
    font-size: 1.2rem;
  }
  .suggestion-name-profile {
    font-size: 1rem;
  }
  .suggetion-profile-image {
    width: 70px;
    height: 70px;
  }
  .suggestion-follow-btn {
    padding: 0.4rem 0.8rem;
    font-size: 1.1rem;
  }

  .suggestion-desc {
    font-size: 1.33rem;
  }
  .btn-container-suggest {
    padding: 1rem 0.3rem;
  }
}
