.small-blog-card-main-heading-title {
  color: var(--light-mode-black, #000) !important;
  font-family: Helvetica Now Display !important;
  font-size: 1.8rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 108% !important;
}
.small-blog-card-tag {
  background-color: #e5e5e5 !important;
  color: black !important;
  border-radius: 30px !important;
}
.small-blog-card-text-description {
  color: var(--neutrals-dusty-grey, #9c9c9c) !important;
  font-family: Source Serif Pro !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.role-response {
  font-size: 1rem !important;
  font-style: normal !important;
  line-height: normal !important;
  font-family: Poppins !important;
  color: black !important;
}
.small-blog-username {
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  font-family: Poppins !important;
  color: black !important;
}
.small-blog-connect-button {
  color: var(--light-mode-white, #fff) !important;
  font-family: Helvetica Now Display !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  background: var(--primary-purple-heart, #5f22d9) !important;
  line-height: normal;
  height: auto !important;
  border-radius: 10px !important;
  padding: 5px 6px !important;
}
.small-blog-datetime-set {
  color: var(#000000) !important;
  font-family: Nunito !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
p {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 768px) {
  .small-blog-card-main-heading-title {
    font-size: 1.2rem !important;
  }
  .small-blog-card-text-description {
    font-size: 16px !important;
  }
  .small-blog-card-tag {
    font-size: 14px !important;
  }
  .small-blog-datetime-set {
    font-size: 12px !important;
  }
  .small-blog-username {
    font-size: 0.9rem !important;
  }
  .role-response {
    font-size: 13px !important;
  }
  .small-blog-connect-button {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .small-blog-card-main-heading-title {
    font-size: 1.2rem !important;
  }
  .small-blog-card-text-description {
    font-size: 16px !important;
  }
  .small-blog-card-tag {
    font-size: 14px !important;
  }
  .small-blog-datetime-set {
    font-size: 10px !important;
  }
  .small-blog-username {
    font-size: 0.8rem !important;
  }
  .role-response {
    font-size: 12px !important;
  }
  .small-blog-connect-button {
    font-size: 12px !important;
  }
}
ul {
  padding-left: 0px !important;
}

.blog-tags-wrap-item ul {
  padding-left: 0px !important;
  gap: 8px !important;
}
