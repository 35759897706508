.service-content-text-one {
  color: #000;
  width: auto;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 2.25rem */
}
.service-content-text-two {
  color: #000;
  width: auto;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 149.5%;
}
.image-youtube {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 520px) {
  .service-content-text-one {
    font-size: 1.2rem;
  }
  .service-content-text-two {
    font-size: 1rem;
  }
}
@media screen and (min-width: 521px) and (max-width: 810px) {
  .service-content-text-one {
    font-size: 1.4rem;
  }
  .service-content-text-two {
    font-size: 1.11rem;
  }
}
@media screen and (min-width: 811px) and (max-width: 1025px) {
  .service-content-text-one {
    font-size: 1.55rem;
  }
  .service-content-text-two {
    font-size: 1.23rem;
  }
}
