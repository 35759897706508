.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5.5rem;
  min-height: 50vh;
  margin-bottom: 3rem;
}

.login-btn {
  display: flex;
  width: 12.25rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 3.625rem;
  background: var(--primary-purple-heart, #5f22d9);
}
.forgot-password-link {
  font-family: Poppins !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: #858585 !important;
}
.password-reset-mentee-btn {
  background: var(--primary-purple-heart, #5f22d9) !important;
  border-radius: 3.625rem !important;
  color: white !important;
  font-family: Poppins !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
  width: 15rem !important;
  height: 2.5rem !important;
}
.signup-mentee-btn {
  background: var(--primary-purple-heart, #5f22d9) !important;
  border-radius: 3.625rem !important;
  color: white !important;
  /* padding: 0.5rem 1.5rem !important; */
  font-family: Poppins !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
  width: 10rem !important;
  height: 2.5rem !important;
}
.login-btn button {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: 0px;
}
.phone-input-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.country-code-label {
  padding: 0 10px;
  font-weight: bold;
}

.phone-input-label {
  flex: 1;
}

/* Add more CSS for styling as needed */
