.form-section-main {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.form-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section-header-title {
  color: var(--primary-purple-heart, #5f22d9);

  /* SecHead */
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.form-section-header-desc {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 3.15rem */
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.3125rem;
}

@media only screen and (max-width: 320px) {
  .entrepreneurship-main-container {
    gap: 1.6rem;
  }
  .form-section-main {
    gap: 1.5rem !important;
  }
  .form-section-header-title {
    text-align: center;
    font-size: 2rem;
  }
  .form-section-header-desc {
    font-size: 0.96rem;
    padding: 0.6rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 420px) {
  .entrepreneurship-main-container {
    gap: 2rem;
  }
  .form-section-main {
    gap: 1.6rem !important;
  }
  .form-section-header-title {
    text-align: center;
    font-size: 2.2rem;
    padding: 0.8rem;
  }
  .form-section-header-desc {
    font-size: 1rem;
    padding: 0.6rem;
  }
}
@media only screen and (min-width: 421px) and (max-width: 520px) {
  .entrepreneurship-main-container {
    gap: 2.4rem;
  }
  .form-section-header-title {
    text-align: center;
    font-size: 2.4rem;
    padding: 1rem;
  }
  .form-section-header-desc {
    font-size: 1.2rem;
    padding: 0.9rem;
  }
}
@media only screen and (min-width: 521px) and (max-width: 768px) {
  .entrepreneurship-main-container {
    gap: 2.4rem;
  }
  .form-section-header-title {
    text-align: center;
    font-size: 2.45rem;
    padding: 1.2rem;
  }
  .form-section-header-desc {
    font-size: 1.24rem;
    padding: 0.89rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .entrepreneurship-main-container {
    gap: 3rem;
  }
  .form-section-header-title {
    text-align: center;
    font-size: 2.6rem;
    padding: 1.34rem;
  }
  .form-section-header-desc {
    font-size: 1.34rem;
    padding: 0.99rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1880px) {
  .entrepreneurship-main-container {
    gap: 3.4rem;
  }
  .form-section-header-title {
    font-size: 3.57rem;
    padding: 2rem;
  }
  .form-section-header-desc {
    font-size: 2rem;
    padding: 1rem;
  }
  .chakra-wrap__list {
    gap: 2rem !important;
  }
}
