.mentor-profile-main-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.bottom-line-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.25rem;
}
.bottom-line-heading {
  color: var(--light-mode-black, #000) !important;
  font-family: Poppins !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem;
}
.bottom-line-heading-para {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.04rem;
}

.bottom-line-button {
  color: var(--base-white, #fff) !important;
  background: var(--primary-purple-heart, #5f22d9) !important;
  font-family: Poppins !important;
  font-size: 1.4rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  border-radius: 30px !important;
  padding: 1.4rem !important;
}
@media screen and (max-width: 480px) {
  .bottom-line-heading {
    font-size: 1.1rem !important;
  }
  .bottom-line-heading-para {
    font-size: 14px !important;
  }
  .bottom-line-button {
    padding: 1rem !important;
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .bottom-line-heading {
    font-size: 1.4rem !important;
  }
  .bottom-line-heading-para {
    font-size: 16px !important;
  }
  .bottom-line-button {
    padding: 1.2rem !important;
    font-size: 1.2rem !important;
  }
}
