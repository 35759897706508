.mentee-dashboard-container {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  display: flex;
  background: var(--base-white, #fff);
}

.dashboard-left-side-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  flex: 1;
}
.container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

aside {
  flex: 0 0 20%;
}

main {
  flex: 1;
}
@media (max-width: 768px) {
  /* Adjust layout for smaller screens */
  .container {
    flex-direction: column;
  }

  aside {
    flex: 1; /* Allow the sidebar to take full width on small screens */
  }
}

@media screen and (max-width: 1023px) {
  .sidebar-mentee-full-screen-visibility {
    display: none !important;
  }
}
