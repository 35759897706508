.notification-body-main-containers::-webkit-scrollbar {
  width: 3px !important;
  border-bottom-left-radius: 13px;
}
.notification-header-title {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 1.33rem;
}
.dark-notification {
  color: white !important;
  transition: all 0.3s ease-in-out;
}
.notification-header-clear-btn {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 1rem !important;
}
.notification-header-no-notification {
  color: var(--light-mode-black, #000);

  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 1rem !important;
}
.notification-content-title {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 1.1rem !important;
}
.is-read {
  color: gray !important;
}
.notification-content-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 16px !important;
  font-style: italic;
}
.notification-content-time {
  color: var(--light-mode-gray, gray);
  font-family: Poppins;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  font-size: 14px !important;
}
.notification-loading-text {
  color: var(--light-mode-black, rgb(0, 0, 0));
  font-family: Helvetica;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  font-size: 18px !important;
}
.notification-loadmore-btn {
  color: var(--light-mode-black, rgb(0, 0, 0));
  font-family: Poppins;
  font-weight: 500;
}

@media only screen and (max-width: 340px) {
  .notification-header-title {
    font-size: 1rem !important;
  }

  .notification-header-clear-btn,
  .notification-header-no-notification {
    font-size: 12px !important;
  }
  .notification-content-title {
    font-size: 13.5px !important;
  }
  .notification-content-description {
    font-size: 12.5px !important;
  }
  .notification-content-time {
    font-size: 11px !important;
  }
}

@media only screen and (min-width: 341px) and (max-width: 420px) {
  .notification-header-title {
    font-size: 1.1rem !important;
  }
  .notification-header-clear-btn,
  .notification-header-no-notification {
    font-size: 12.8px !important;
  }
  .notification-content-title {
    font-size: 15.5px !important;
  }
  .notification-content-description {
    font-size: 14px !important;
  }
  .notification-content-time {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 421px) and (max-width: 769px) {
  .notification-header-title {
    font-size: 1.22rem !important;
  }
  .notification-header-clear-btn,
  .notification-header-no-notification {
    font-size: 14.8px !important;
  }
  .notification-content-title {
    font-size: 17px !important;
  }
  .notification-content-description {
    font-size: 15px !important;
  }
  .notification-content-time {
    font-size: 12.5px !important;
  }
}

@media only screen and (min-width: 770px) and (max-width: 92px) {
  .notification-header-title {
    font-size: 1.26rem !important;
  }
  .notification-header-clear-btn,
  .notification-header-no-notification {
    font-size: 16.8px !important;
  }
  .notification-content-title {
    font-size: 18px !important;
  }
  .notification-content-description {
    font-size: 16px !important;
  }
  .notification-content-time {
    font-size: 12.8px !important;
  }
}
