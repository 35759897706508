.calender-react-booking-start {
    max-width: 550px !important;
    padding: 1rem !important;
}


.calender-react-booking-start {
    background-color: #f6f6f6 !important;
}

/* 
.calender-react-booking-start .react-calendar__month-view__weekdays__weekday {
    margin-bottom: 10px !important;
} */

.calender-react-booking-start .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;

}

.calender-react-booking-start .react-calendar__navigation__label span {
    color: rgb(0, 0, 0) !important;
    font-family: Poppins !important;
    margin-bottom: 15px !important;
    font-size: 1.4rem !important;
}

.calender-react-booking-start .react-calendar__tile {
    padding: 10px !important;
    margin-top: 10px !important;
}

.calender-react-booking-start .react-calendar__tile:disabled {
    background-color: #e5e5e5 !important;
    font-family: Poppins !important;
    opacity: 0.6 !important;
    cursor: not-allowed !important;
}

.calender-react-booking-start .react-calendar__tile:disabled abbr {
    color: #303030 !important;
}

.calender-react-booking-start .react-calendar__tile {
    color: #5a5a5a !important;
    transition: all 0.3 ease-in-out !important;
}

.calender-react-booking-start .react-calendar__tile:focus,
.calender-react-booking-start .react-calendar__tile:active {
    background-color: #2a05b1 !important;
    color: #ffffff !important;
}

.calender-react-booking-start .react-calendar__tile:hover {
    background-color: #2a05b17a !important;
    color: #ffffff !important;
}

.calender-react-booking-start .react-calendar__navigation button:disabled {
    background-color: #858585 !important;
    display: none !important;
    color: white !important;
}

.dark-md-calendar-bg-cl {
    background-color: #5a5a5a !important;
}

.dark-md-calendar-bg-cl .react-calendar__navigation__label span {
    color: white !important;
}

.dark-md-calendar-bg-cl .react-calendar__tile:disabled {
    background-color: #000000 !important;
    opacity: 0.4 !important;
}

.dark-md-calendar-bg-cl .react-calendar__tile:disabled abbr {
    color: #ffffff;
}

.dark-md-calendar-bg-cl .react-calendar__tile {
    color: white !important;
}

.dark-md-calendar-bg-cl .react-calendar__navigation button:disabled {
    background-color: #858585 !important;
    display: none !important;
    color: white !important;
}

.mentee-booking-settings-reschedule-booking-available-time-text {
    font-family: Poppins;
    font-size: 1.5rem;
}

.mentee-booking-settings-reschedule-booking-available-time-date-tag {
    font-family: Poppins;
    font-size: 1rem !important;
}