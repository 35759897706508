.recomendation-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.625rem;
}

.navigation-recomendation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
}

.recomendation-navigation-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.search {
  display: flex;
  width: 11rem;
  height: 0.5rem;
  padding: 0.8125rem 1.8125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
  background-color: lightgrey;
}

.search-frame {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.search-frame input {
  outline: none;
  border: 0px;
  width: 7rem;
  background-color: lightgray;
}
.span {
  font-family: Helvetica Now Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06rem;
}
.tags-container {
  padding: 1rem 0.6rem;
}
.tags-recomendation {
  background-color: #e5e5e5 !important;
}
.tags-recomendation-lable {
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  color: var(--base-black, #000);
  font-family: Helvetica Now Display;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06rem;
}
@media screen and (max-width: 320px) {
  .recomendation-container {
    padding: 1rem 1rem;
  }
  .span {
    font-size: 15px;
    font-weight: 550;
  }
  .search-suggestion {
    max-width: 125px !important;
  }
}
@media screen and (min-width: 321px) and (max-width: 420px) {
  .recomendation-container {
    padding: 1rem 1rem;
  }
  .span {
    font-size: 1rem;
    font-weight: 550;
  }
  .search-suggestion {
    padding: 0.2rem;
    max-width: 130px !important;
  }
}
@media screen and (min-width: 421px) and (max-width: 510px) {
  .recomendation-container {
    padding: 1rem 1rem;
  }
  .span {
    font-size: 1.18rem;
    font-weight: 550;
  }
  .search-suggestion {
    padding: 0.3rem;
    max-width: 135px !important;
  }
}
@media screen and (min-width: 511px) and (max-width: 1024px) {
  .recomendation-container {
    padding: 1rem 1rem;
  }
  .span {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .search-suggestion {
    padding: 0.7rem;
    max-width: 180px !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1770px) {
  .span {
    font-size: 1.8rem !important;
    font-weight: 600;
  }
  .search-suggestion {
    padding: 0.7rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1220px) {
  .recomendation-container {
    padding: 1rem 2.4rem;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1770px) {
  .tags-container {
    padding: 1rem 2rem;
  }
  .recomendation-container {
    padding: 1rem 2.2rem !important;
  }
}
