.logo {
  color: var(--primary-purple-heart, #5f22d9);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  line-height: 177.5%; /* 3.55rem */
  letter-spacing: 0.06rem;
}
.logo-text {
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 177.5%; /* 3.55rem */
  letter-spacing: 0.06rem;
}
.footer-home-link {
  color: var(--neutrals-scorpion, #5b5b5b);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; /* 3rem */
  letter-spacing: 0.045rem;
  text-decoration: none;
}
.footer-links-tab {
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 200%; /* 3rem */
  letter-spacing: 0.045rem;
  margin-bottom: 10px;
}
@media screen and (max-width: 500px) {
  .logo-text {
    font-size: 1rem;
  }
  .footer-links-tab {
    font-size: 1rem;
  }
  .footer-responsive {
    width: 100% !important;
    justify-content: space-between !important;
  }
}

@media screen and (min-width: 501px) and (max-width: 1023px) {
  .logo-text {
    font-size: 1rem;
  }
  .footer-home-link {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .footer-responsive {
    flex-direction: row !important;
  }
}
@media screen and (min-width: 501px) and (max-width: 767px) {
  .footer-responsive {
    flex-direction: row !important;
    width: 100%;

    gap: 3rem !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 375px) {
  .footer-home-link {
    font-size: 0.87rem !important;
    font-weight: 400 !important;
  }
  .footer-responsive {
    display: flex !important;
    flex-direction: row !important;
    gap: 2.5rem !important;
    flex: 1;
    justify-content: space-between;
  }
  .askme-any-thing-heading {
    font-size: 0.92rem !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
  .footer-responsive {
    display: flex !important;
    flex-direction: row !important;
    gap: 2.8rem !important;
    flex: 1;
    justify-content: space-between;
  }
}
@media screen and (min-width: 425px) and (max-width: 480px) {
  .footer-responsive {
    display: flex !important;
    flex-direction: row !important;
    gap: 4.3rem !important;
    flex: 1;
    justify-content: space-between;
  }
}
@media screen and (min-width: 480px) and (max-width: 510px) {
  .footer-responsive {
    display: flex !important;
    flex-direction: row !important;
    gap: 2rem !important;
    flex: 1;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 375px) and (max-width: 500px) {
  .footer-home-link {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
}

.dark-mode-sec {
  color: white !important;
}
