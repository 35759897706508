.dash-board-nav-container {
  display: flex;
  width: 100%;
  padding: 2.25rem 0rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.625rem;
  background: var(--light-mode-white, #fff);
}

.nav-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18rem;
  width: 100%;
  /* flex: 1 0 0; */
}

.search-box {
  display: flex;
  height: 3rem;
  padding: 0.8125rem 1.8125rem;
  gap: 1rem;
  flex: 1 0 0;
  border-radius: 3.125rem;
  background: var(--neutrals-scorpion, #5b5b5b);
  align-items: center;
  outline: none;
  border: 0px;
}
.userbox {
  display: flex;
  align-items: center;
  gap: var(--gap-16, 1rem);
}

.notification {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
.user-name-icon {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}

.search-box-frame {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.search-box-frame input {
  padding: 0.8125rem 1.8125rem;
  background: var(--neutrals-scorpion, #5b5b5b);
  border: 0px;
  outline: none;
}

.search-box-frame input::placeholder {
  color: var(--light-mode-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.User-name-heading {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.User-name-profile {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard-dropdown:hover {
  display: flex;
  justify-content: space-evenly;
}

.dashboard-dropdown {
  height: auto;
  position: absolute;
  right: 0.5rem;
  top: 3rem;
}
.dropdown-submenu {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  font-weight: 500;
}

.dropdown-submenu:hover {
  background-color: lightgray;
  cursor: pointer;
}
.mentordash-drop-text-name {
  color: var(--light-mode-black, #000) !important;
  font-family: Poppins !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal;
}

.dashboard-search-input {
  color: white;
  font-family: Poppins !important;
  font-size: 1rem !important;
}

.dashboard-search-input::placeholder {
  color: var(--light-mode-white, #fff) !important;
  font-family: Poppins !important;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dashboard-search-input:active {
  outline: none !important;
}
.search-box-input-dashboard {
  background-color: #5b5b5b !important;
  border-radius: 50px !important;
}
.dashboard-user-name-text {
  color: var(--light-mode-black, #000);

  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dashboard-user-type-text {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 320px) {
  .mentee-dash-notif-icon,
  .mentee-dash-user-icon {
    width: 25px !important;
  }
  .dashboard-user-name-text {
    font-size: 14.5px;
    white-space: nowrap;
  }
  .dashboard-user-type-text {
    font-size: 11px;
  }
  .mentordash-drop-text-name {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 321px) and (max-width: 390px) {
  .mentee-dash-notif-icon,
  .mentee-dash-user-icon {
    width: 30px !important;
  }
  .dashboard-user-name-text {
    font-size: 14.5px;
    white-space: nowrap;
  }
  .dashboard-user-type-text {
    font-size: 12.4px;
  }
  .mentordash-drop-text-name {
    font-size: 14px !important;
    text-align: start !important;
  }
}

@media screen and (min-width: 391px) and (max-width: 420px) {
  .mentee-dash-notif-icon,
  .mentee-dash-user-icon {
    width: 33px !important;
  }
  .dashboard-user-name-text {
    font-size: 17.5px;
    white-space: nowrap;
  }
  .dashboard-user-type-text {
    font-size: 14.4px;
  }
  .mentordash-drop-text-name {
    font-size: 16.2px !important;
    text-align: start !important;
  }
}

@media screen and (min-width: 421px) and (max-width: 520px) {
  .mentee-dash-notif-icon,
  .mentee-dash-user-icon {
    width: 35px !important;
  }
  .dashboard-user-name-text {
    font-size: 19.5px;
    white-space: nowrap;
  }
  .dashboard-user-type-text {
    font-size: 14.4px;
  }
  .mentordash-drop-text-name {
    font-size: 16.2px !important;
    text-align: start !important;
  }
}

@media screen and (min-width: 521px) and (max-width: 769px) {
  .mentee-dash-notif-icon,
  .mentee-dash-user-icon {
    width: 40px !important;
  }
  .dashboard-user-name-text {
    font-size: 20.5px;
    white-space: nowrap;
  }
  .dashboard-user-type-text {
    font-size: 15.4px;
  }
  .mentordash-drop-text-name {
    font-size: 17.2px !important;
    text-align: start !important;
  }
}

@media screen and (max-width: 375px) {
  .theme-fr-small-scr {
    display: none;
  }
}
