.mentor-section {
  width: 100% !important;
}
.mentor-section-container {
  display: flex;
  margin-top: 1rem;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  width: 100% !important;
  background: var(--light-mode-grey-100, #f6f6f6);
}
.mentor-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 0.6875rem; */
  align-self: stretch;
}
.slick-slider .slick-next,
.slick-slider .slick-prev {
  visibility: hidden;
}
.mentor-section-comes-from-countries {
  width: 75rem;
}
/* CSS for screens between 300px and 600px wide (typical tablet screens) */
@media (min-width: 300px) and (max-width: 600px) {
  .mentor-section-comes-from {
    gap: 0.4rem !important;
  }
  .mentor-section-comes-from-countries {
    max-width: 18rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 850px) {
  .mentor-section-comes-from-countries {
    max-width: 45rem !important;
  }
  .slick-slider {
    max-width: 45.1rem !important;
  }
}
@media screen and (min-width: 851px) and (max-width: 1024px) {
  .mentor-section-comes-from-countries {
    max-width: 51rem !important;
  }
  .slick-slider {
    max-width: 51.1rem !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1224px) {
  .mentor-section-comes-from-countries {
    max-width: 59rem !important;
  }
  .slick-slider {
    max-width: 59.1rem !important;
  }
}
.mentor-section-header-head {
  color: var(--gunmetal, #253344);
  text-align: center;

  /* Body/Heading/semibold02 */
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
}
.mentor-section-header-foot {
  align-self: stretch;
  color: var(--gunmetal, #253344);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;

  /* line-height: normal; */
}

.mentor-sector-cards-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.mentor-section-viewall {
  color: var(--light-mode-black, #000);
  text-align: center;
  background: black;
  font-family: Poppins;
  font-size: 2.1rem;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  margin-bottom: 15px;
}
.mentor-section-comes-from-heading {
  color: #000;
  text-align: center;

  /* Body/Heading/semibold02 */
  font-family: Poppins;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.icon-right {
  font-size: 2.25rem;
}

.mentor-section-comes-from {
  display: flex;
  /* padding: 2.1875rem 2.6875rem; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.25rem;
}

.mentor-section-comes-from-countries {
  display: flex;
  /* width: 80rem; */
  height: 4rem;
  justify-content: center;
  align-items: center;
  gap: 7.625rem;
}
@media screen and (max-width: 500px) {
  .mentor-section-header-foot {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .mentor-sector-cards-container {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .mentor-sector-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .mentor-sector-cards-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 550px) {
  .mentor-section-header-foot {
    padding: 1rem;
  }
}

@media screen and (min-width: 200px) and (max-width: 320px) {
  .mentor-section-comes-from-heading {
    font-size: 1.2rem !important;
  }
  .mentor-section-viewall {
    margin-bottom: 0;
    font-size: 1rem;
  }
  .mentor-section-container {
    gap: 1rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 380px) {
  .mentor-section-comes-from-heading {
    font-size: 1.4rem !important;
  }
  .mentor-section-viewall {
    margin-bottom: 0;
    font-size: 1.3rem;
  }
  .mentor-section-container {
    gap: 1.2rem;
  }
}

@media screen and (min-width: 380px) and (max-width: 500px) {
  .mentor-section-comes-from-heading {
    font-size: 1.4rem !important;
  }
  .mentor-section-viewall {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  .mentor-section-container {
    gap: 1.3rem;
  }
}
.mentor-container {
  padding: 0rem 0 !important;
  border-radius: 10px !important;
}

.Mentors-sliders-home-dash {
  max-width: 100%;
  overflow: auto;
}

.Mentors-sliders-home-dash::-webkit-scrollbar-thumb {
  display: none !important;
  background-color: transparent !important;
  width: 0 !important;
}
.Mentors-sliders-home-dash::-webkit-scrollbar-corner {
  display: none;
  background-color: transparent !important;

  width: 0 !important;
}

.Mentors-sliders-home-dash::-webkit-scrollbar-track {
  display: none !important;
  background-color: transparent !important;
  width: 0 !important;
}
