.mentee-addional-infor-page {
  background-color: #f6f6f6 !important;
}

.mentee-addional-infor-back-btn {
  background-color: #333333 !important;
  color: white !important;
  text-transform: uppercase;
  font-size: 1rem !important;
  border-radius: 40px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  height: 2.1rem !important;
}
.css-1wh2kri {
  margin: 0px !important;
}
.mentee-addional-infor-lable-text {
  color: #333333 !important;
  font-size: 1rem !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
}
.mentee-addional-infor-input {
  border-radius: 10px !important;
  background-color: #e5e5e5 !important;
  color: rgb(0, 0, 0) !important;
  font-family: Poppins !important;
}
.mentee-addional-infor-input:active {
  border-color: #e5e5e5 !important;
}
.css-5uowow:focus-visible,
.css-5uowow[data-focus-visible] {
  box-sizing: none !important;
  border: none !important;
}

.mentee-addional-infor-input::placeholder {
  border-color: #e5e5e5 !important;
  color: black !important;
  font-family: Poppins !important;
}
.mentee-addional-infor-update-btn {
  background-color: #5f22d9 !important;
  color: white !important;
  font-size: 1.1rem !important;
  border-radius: 40px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  height: 2.1rem !important;
  width: 8rem !important;
}

.mentee-addional-infor-cancel-btn {
  background-color: transparent !important;
  color: rgb(0, 0, 0) !important;
  border: 1.4px solid black !important;
  font-size: 1.1rem !important;
  border-radius: 40px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  height: 2.1rem !important;
  width: 8rem !important;
}
.mentee-addional-infor-add-remove-social-link-btn {
  font-size: 12px !important;
  text-decoration: underline !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
