.navbar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-16, 0.625rem);
  border-radius: 0.475rem;
}

.navbar-icon-laptop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-14, 0.445rem);
}

.navbar-icon span {
  font-weight: bold;
}
.navbar-icon-laptop span {
  font-weight: bold;
}
.connect-border-link {
  text-decoration: none;
  font-size: large;
  font-family: Poppins;
  text-transform: uppercase;
  color: #5f22d9 !important;
  /* color: white !important; */
}
/* .nav-super {
  background-color: #5f22d9;
} */
.connect-border-linknormal {
  color: #5f22d9 !important;
  /* color: white !important; */
  text-transform: uppercase;
  /* color: #5f22d9 !important; */
}
#Vector_2_dark_white,
#Vector_dark_white {
  fill: white;
}
.connect-border-link-laptop {
  text-decoration: none;
  font-family: Poppins;
  color: #5f22d9 !important;
  /* color: white; */
  text-transform: uppercase;
}

.connect-border-icon-laptop.normal {
  background-color: transparent;
}
#dark,
#dark {
  fill: #5f22d9;
}
.navbar-list-links {
  text-decoration: none;
  font-size: large;
  color: rgb(0, 0, 0);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
  transition: 0.3s;
}
.navbar-list-links-laptop {
  text-decoration: none;
  color: rgb(0, 0, 0);

  font-family: Poppins;
  font-size: 0.956rem;
  font-style: normal;
  line-height: normal;
  transition: 0.3s;
}
.navbar-list-links:active {
  color: #5f22d9;
}
.navbar-list-links-laptop:active {
  color: #5f22d9;
}
.btn-price {
  border: 2px solid #000000;
  border-radius: 2rem;
  padding: 5px;
  width: 5rem;
  background-color: rgb(255, 255, 255);
  color: #000000;
  cursor: pointer;
}
.hum-icon {
  color: black !important;
  /* background-color: white; */
}
.btn-price-laptop {
  border: 2px solid #000000;
  border-radius: 2rem;
  padding: 5px;
  background-color: white;
  color: #000000;
  cursor: pointer;
}

.btn-login {
  border: 2px solid #5f22d9;
  border-radius: 2rem;
  padding: 5px;
  width: 5rem;
  background-color: black;
  color: white;
  cursor: pointer;
}
.btn-login-laptop {
  border: 2px solid #5f22d9;
  border-radius: 2rem;
  padding: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
}

.connect-border-icon {
  width: 2.735rem;
  height: 3.125rem;
}
.connect-border-icon-laptop {
  width: 2rem;
  height: 2.9rem;
}
.navbar-dashboard-link-btn {
  border-radius: 10px !important;
  border: 1px solid black !important;
  background-color: #5f22d9 !important;
  color: rgb(255, 255, 255) !important;
  font-family: Poppins !important;
  font-size: 0.956rem !important;
  font-style: normal !important;
  line-height: normal !important;
  transition: 0.3s !important;
  max-width: 10rem !important;
}
/* Responsive */
.Header-version {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 1rem;
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 341px) {
  .navbar-res-icon-text {
    justify-content: space-between !important;
    width: 100% !important;
  }
  .connect-border-icon {
    width: 2rem;
    height: 3rem;
  }
}
@media screen and (min-width: 341px) and (max-width: 375px) {
  .navbar-res-icon-text {
    justify-content: space-between !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 425px) {
  .connect-border-linknormal {
    font-size: 1rem !important;
  }
  .connect-border-icon {
    width: 2.3rem;
    height: 3.2rem;
  }
}
.hum-icon {
  color: white !important;
}

@media screen and (min-width: 341px) and (max-width: 600px) {
  .connect-border-icon {
    width: 2.1rem;
    height: 3rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 374px) {
  .navbar-res-icon-text {
    justify-content: space-between !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 423px) {
  .navbar-res-icon-text {
    justify-content: space-between !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 424px) and (max-width: 600px) {
  .navbar-res-icon-text {
    justify-content: space-between !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 551px) and (max-width: 765px) {
  .tab-navbar-css {
    gap: 2rem !important;
  }
}
@media screen and (min-width: 765px) and (max-width: 1023px) {
  .tab-navbar-css {
    gap: 7rem !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  .navbar-res-icon-text {
    gap: 5rem;
  }
  .connect-border-icon {
    width: 2.2rem;
    height: 3.2rem;
  }

  .links-tab {
    gap: 1rem !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1120px) {
  .navbar-list-links-laptop {
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1220px) {
  .nav-super {
    padding: 0.9rem;
  }
  .navbar-icon-laptop {
    margin-right: 2rem;
  }
  .navbar-list-links-laptop {
    font-size: 1.2rem;
  }
  .btn-login-laptop {
    font-size: 1rem;
    width: 4rem;
  }
  .btn-price-laptop {
    width: 4rem;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1400px) {
  .navbar-full-screen {
    gap: 0.4rem !important;
    margin-left: -3rem;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1480px) {
  .navbar-full-screen {
    gap: 1.1rem !important;
    margin-left: -5rem;
  }
  .navbar-stack-full {
    gap: 2.1rem !important;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1395px) {
  .nav-super {
    padding: 1rem;
  }
  .navbar-icon-laptop {
    margin-right: 2.1rem;
  }

  .navbar-list-links-laptop {
    font-size: 1.3rem;
  }
  .navbar-icon-laptop {
    margin-left: 1rem;
  }
  .btn-login-laptop {
    font-size: 1.1rem !important;
    width: 5rem !important;
  }

  .navbar-icon-laptop span {
    font-size: 1.2rem;
  }
  .connect-border-icon-laptop {
    width: 3rem;
    height: 3.2rem;
  }

  .btn-price-laptop {
    width: 5rem !important;
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 1395px) and (max-width: 1440px) {
  .nav-super {
    padding: 1rem;
  }
  .navbar-icon-laptop {
    margin-right: 2.1rem;
  }

  .navbar-list-links-laptop {
    font-size: 1.3rem;
  }
  .navbar-icon-laptop {
    margin-left: 1rem;
  }
  .btn-login-laptop {
    font-size: 1.1rem !important;
    width: 5rem !important;
  }

  .navbar-icon-laptop span {
    font-size: 1.2rem;
  }
  .connect-border-icon-laptop {
    width: 3rem;
    height: 3.2rem;
  }

  .btn-price-laptop {
    width: 5rem !important;
    font-size: 1rem !important;
  }
}
@media (min-width: 1440px) and (max-width: 1600px) {
  .navbar-full-screen {
    gap: 1rem !important;
  }
  .navbar-icon-laptop {
    margin-right: 2.2rem;
  }
  .full-screen-combined {
    gap: 2rem !important;
  }
  .btn-price-laptop {
    width: 6rem;
    font-size: 1.2rem;
  }
  .btn-login-laptop {
    width: 6rem;
    font-size: 1.3rem;
  }
  .navbar-list-links-laptop {
    font-size: 1.3rem;
  }
  .navbar-icon-laptop span {
    font-size: 1.23rem;
  }
  .connect-border-icon-laptop {
    width: 4rem;
    height: 4.2rem;
  }
}

@media screen and (min-aspect-ratio: 16/10) and (min-width: 1700px) and (max-width: 2560px) {
  .navbar-full-screen {
    gap: 1rem !important;
  }
  .full-screen-combined {
    gap: 3rem !important;
  }
  .btn-price-laptop {
    width: 6rem;
    font-size: 1.3rem;
  }
  .btn-login-laptop {
    width: 6rem;
    font-size: 1.4rem;
  }
  .navbar-list-links-laptop {
    font-size: 1.5rem;
  }
  .navbar-icon-laptop span {
    font-size: 1.7rem;
  }
  .connect-border-icon-laptop {
    width: 6rem;
    height: 5.2rem;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1240px) {
  .navbar-list-links-laptop {
    font-size: 1rem !important;
  }
  .navbar-stack-full {
    gap: 0px !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1090px) {
  .navbar-full-screen {
    gap: 0 !important;
  }
}
