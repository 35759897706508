.congrats-container {
  display: inline-flex;
  padding: 2.6rem;

  height: auto;
  flex-direction: column;
  align-items: center;
  gap: 3.375rem;
  border-radius: 1.375rem;
  background: var(--light-mode-grey-100, #f6f6f6) !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading-congrats-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5625rem;
}

.congrats-prompt-heading {
  color: var(--light-mode-grey-500, #333) !important;
  text-align: center !important ;
  font-family: Poppins !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.heading-bottom {
  color: var(--light-mode-grey-500, #333) !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal;
}

.congrats-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5625rem;
}

.modal-start {
  display: flex;
  width: 7.75rem;
  height: 2.75rem;
  padding: 0.5rem 2.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.625rem;
  background: var(--primary-purple-heart, #5f22d9);
}

.modal-start button {
  color: var(--light-mode-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: 0px;
}
.modal-start-btn {
  border-radius: 3.625rem !important;
  color: white !important;
  font-family: Poppins !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  width: 10rem !important;
  background: var(--primary-purple-heart, #5f22d9) !important;
}
.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(189, 189, 189, 0.9);
}
.icon-animation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
