.event-card-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.event-card-content-container {
  width: 98%;
  display: flex;
  padding: 0.875rem;
  flex-direction: column;
  gap: 8.0625rem;
  border-radius: 0.375rem;
  background: var(--light-mode-grey-300, #858585);
}

.event-card-date-time {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
}

.event-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.1875rem;
}

.event-card-date-time span {
  color: var(--light-mode-white, #fff);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-tags {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
}

.event-tag-frame {
  display: flex;
  padding: 0.125rem var(--gap-16, 1rem);
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.8125rem;
  background: var(--light-mode-black, #000);
}

.event-tag-text {
  color: var(--light-mode-white, #fff);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event-card-footer {
  display: flex;
  align-items: center;
  gap: 3.75rem;
}

.event-host {
  display: flex;
  align-items: flex-start;
  gap: 0.875rem;
}

.event-joinnow {
  padding: 0.125rem var(--gap-16, 1rem);
  border-radius: 0.25rem;
  background: var(--Selective-Yellow, #ffb703) !important;
  color: var(--light-mode-grey-500, #333);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.event-title {
  color: var(--light-mode-white, #fff);
  font-family: Poppins;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.event-host-profile {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  background: lightgray 50% / cover no-repeat;
}

.event-host-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.event-host-name span {
  color: var(--light-mode-white, #fff);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.event-host-subtit {
  color: var(--light-mode-white, #fff);
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

@media only screen and (max-width: 320px) {
  .event-card-content-container {
    gap: 1rem;
  }
  .event-card-date-time {
    gap: 3rem;
    padding: 0.3rem 1.2rem;
  }
  .event-card-content-container {
    padding: 0.4rem;
  }
  .event-card-date-time span {
    font-size: 11.4px;
  }
  .event-tag-frame {
    padding: 0.4rem 0.4rem;
  }
  .event-title {
    font-size: 1.37rem;
  }
  .event-card-footer {
    gap: 0.6rem;
  }
  .event-host {
    gap: 0.5rem;
  }
  .event-host-name span {
    font-size: 0.89rem;
  }
  .event-host-subtit {
    font-size: 0.78rem;
  }
  .event-host-profile {
    background: none;
    width: 3.5rem;
    height: 3.5rem;
  }
  .event-joinnow {
    font-size: 0.8rem !important;
    padding: 0.7rem !important;
  }
  .event-joinnow-text {
    font-size: 14px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 520px) {
  .event-card-content-container {
    gap: 1rem;
  }
  .event-card-date-time {
    gap: 3.5rem;
    padding: 0.3rem 1.2rem;
  }
  .event-card-content-container {
    padding: 0.4rem;
  }
  .event-card-date-time span {
    font-size: 13.4px;
  }
  .event-tag-text {
    padding: 0.1rem 0.3rem;
  }
  .event-tag-frame {
    padding: 0.45rem 0.45em;
  }
  .event-title {
    font-size: 1.42rem;
  }
  .event-card-footer {
    gap: 0.65rem;
  }
  .event-host {
    gap: 0.5rem;
  }
  .event-host-name span {
    font-size: 0.99rem;
  }
  .event-host-subtit {
    font-size: 0.88rem;
  }
  .event-host-profile {
    background: none;
    width: 3.5rem;
    height: 3.5rem;
  }
  .event-joinnow {
    font-size: 0.8rem !important;
    padding: 0.8rem !important;
  }
  .event-joinnow-text {
    font-size: 16px;
  }
}
@media only screen and (min-width: 521px) and (max-width: 620px) {
  .event-card-content-container {
    gap: 2rem;
  }
  .event-card-date-time {
    gap: 4.5rem;
    padding: 1rem 2rem;
  }
  .event-content {
    padding: 1rem 2rem;
  }
  .event-card-content-container {
    padding: 0.4rem;
  }
  .event-card-date-time span {
    font-size: 15.4px;
  }
  .event-tag-text {
    padding: 0.1rem 0.3rem;
  }
  .event-tag-frame {
    padding: 0.45rem 0.45em;
  }
  .event-title {
    font-size: 2.2rem;
  }
  .event-card-footer {
    gap: 2rem;
  }
  .event-host {
    gap: 1.6rem;
  }
  .event-host-name span {
    font-size: 1.5rem;
  }
  .event-host-subtit {
    font-size: 0.88rem;
  }
  .event-host-profile {
    background: none;
    width: 3.5rem;
    height: 3.5rem;
  }
  .event-joinnow {
    font-size: 0.9rem !important;
    padding: 0.9rem !important;
  }
}
@media only screen and (min-width: 621px) and (max-width: 720px) {
  .event-card-content-container {
    gap: 1rem;
    margin-right: 1rem;
  }
  .event-card-date-time {
    gap: 3rem;
    padding: 0.3rem 0.5rem;
  }
  .event-card-content-container {
    padding: 0.3rem;
  }
  .event-card-date-time span {
    font-size: 12.4px;
  }
  .event-tag-text {
    padding: 0.1rem 0.3rem;
  }
  .event-tag-frame {
    padding: 0.3rem 0.3rem;
  }
  .event-title {
    font-size: 1.35rem;
  }
  .event-card-footer {
    gap: 0.5rem;
  }
  .event-host {
    gap: 0.4rem;
  }
  .event-host-name span {
    font-size: 0.89rem;
  }
  .event-host-subtit {
    font-size: 0.77rem;
  }
  .event-host-profile {
    background: none;
    width: 3rem;
    height: 3rem;
  }
  .event-joinnow {
    font-size: 0.7rem !important;
    padding: 0.5rem !important;
  }
}
@media only screen and (min-width: 721px) and (max-width: 820px) {
  .event-card-content-container {
    gap: 2.5rem;
    margin: 1.5rem;
  }
  .event-card-date-time {
    gap: 3rem;
    padding: 0.3rem 0.2rem;
  }
  .event-card-content-container {
    padding: 1rem;
  }
  .event-card-date-time span {
    font-size: 11.4px;
  }
  .event-tag-text {
    padding: 0.2rem 0.35rem;
  }
  .event-tag-frame {
    padding: 0.3rem 0.3rem;
  }
  .event-title {
    font-size: 1.55rem;
  }
  .event-card-footer {
    gap: 0.36rem;
  }
  .event-host {
    gap: 0.54rem;
  }
  .event-host-name span {
    font-size: 0.89rem;
  }
  .event-host-subtit {
    font-size: 0.77rem;
  }
  .event-host-profile {
    background: none;
    width: 3rem;
    height: 3rem;
  }
  .event-joinnow {
    font-size: 0.73rem !important;
    padding: 0.55rem !important;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1220px) {
  .event-card-content-container {
    gap: 3rem;
    margin: 0;
  }
  .event-card-date-time {
    gap: 3.3rem;
    padding: 0.35rem 0.25rem;
  }
  .event-card-content-container {
    padding: 1.2rem;
  }
  .event-card-date-time span {
    font-size: 13.4px;
  }
  .event-tag-text {
    padding: 0.2rem 0.45rem;
  }
  .event-tag-frame {
    padding: 0.35rem 0.35rem;
  }
  .event-title {
    font-size: 1.65rem;
  }
  .event-card-footer {
    gap: 1rem;
  }
  .event-host {
    gap: 0.74rem;
  }
  .event-host-name span {
    font-size: 0.99rem;
  }
  .event-host-subtit {
    font-size: 0.87rem;
  }
  .event-host-profile {
    background: none;
    width: 3rem;
    height: 3rem;
  }
  .event-joinnow {
    font-size: 0.73rem !important;
    padding: 0.55rem !important;
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1420px) {
  .event-card-content-container {
    gap: 3rem;
    margin: 0;
  }
  .event-card-date-time {
    gap: 3.3rem;
    padding: 0.35rem 0.25rem;
  }
  .event-card-content-container {
    padding: 1.2rem;
  }
  .event-card-date-time span {
    font-size: 13.4px;
  }
  .event-tag-text {
    padding: 0.2rem 0.45rem;
  }
  .event-tag-frame {
    padding: 0.35rem 0.35rem;
  }
  .event-title {
    font-size: 1.65rem;
  }
  .event-card-footer {
    gap: 1rem;
  }
  .event-host {
    gap: 0.74rem;
  }
  .event-host-name span {
    font-size: 0.99rem;
  }
  .event-host-subtit {
    font-size: 0.87rem;
  }
  .event-host-profile {
    background: none;
    width: 3rem;
    height: 3rem;
  }
  .event-joinnow {
    font-size: 0.73rem !important;
    padding: 0.55rem !important;
  }
}
@media only screen and (min-width: 1421px) and (max-width: 1820px) {
  .event-card-content-container {
    gap: 4rem;
    margin: 0.5rem;
  }
  .event-card-date-time {
    gap: 3.4rem;
    padding: 0.35rem 0.35rem;
  }
  .event-card-content-container {
    padding: 1.4rem;
  }
  .event-card-date-time span {
    font-size: 14.4px;
  }
  .event-tag-text {
    padding: 0.2rem 0.45rem;
  }
  .event-tag-frame {
    padding: 0.35rem 0.55rem;
  }
  .event-title {
    font-size: 2rem;
  }
  .event-card-footer {
    gap: 1rem;
  }
  .event-host {
    gap: 1rem;
  }
  .event-host-name span {
    font-size: 1.3rem;
  }
  .event-host-subtit {
    font-size: 0.98rem;
  }
  .event-host-profile {
    background: none;
    width: 4rem;
    height: 4rem;
  }
  .event-joinnow {
    font-size: 0.93rem !important;
    padding: 0.95rem !important;
  }
}
