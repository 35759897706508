.job-card-container {
  display: flex;
  padding: 0.875rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: var(--corner-radius-12, 0.75rem);
}

.job-card-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.25rem;
}

.job-btn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 4.5rem;
}
.job-button {
  background-color: white !important;
  border: none;
  cursor: pointer;
  padding: 0px 10px !important;
  font-size: 13px;
  border-radius: 50px !important;
  color: #000;
  border: 1px solid black;
  transition: background-color 0.3s, color 0.3s;
}

.job-tags {
  display: flex;
  align-items: flex-start;
  gap: 0.375rem;
}

.job-tags div {
  display: flex;
  padding: 0.125rem var(--gap-16, 0.5rem);
  justify-content: center;
  align-items: center;
  gap: 0.45rem;
  border-radius: 1.8125rem;
  background: var(--light-mode-grey-200, #e5e5e5);
  color: var(--light-mode-grey-500, #333);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.job-position {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 340px) {
  .job-card-box {
    gap: 2rem;
  }
  .job-position {
    font-size: 1.2rem;
    padding: 0.2rem 0.3rem;
  }
  .job-btn {
    gap: 0.8rem;
  }
  .job-tags div {
    font-size: 12px;
  }
  .job-button {
    padding: 0.2rem !important;
    font-size: 14px !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 341px) and (max-width: 420px) {
  .job-card-box {
    gap: 3rem;
  }
  .job-position {
    font-size: 1.2rem;
    padding: 0.6rem 0.5rem;
  }
  .job-btn {
    gap: 3.1rem;
  }
  .job-tags div {
    font-size: 13px;
  }
  .job-button {
    padding: 0.24rem !important;
    font-size: 16px !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 421px) and (max-width: 720px) {
  .job-card-box {
    gap: 3rem;
  }
  .job-position {
    font-size: 1.34rem;
    padding: 0.1rem 0.5rem;
  }
  .job-btn {
    gap: 4.1rem;
  }
  .job-tags div {
    font-size: 14px;
  }
  .job-button {
    padding: 0.34rem !important;
    font-size: 17x !important;
    height: auto !important;
  }
}

@media only screen and (min-width: 721px) and (max-width: 790px) {
  .job-card-box {
    gap: 3rem;
  }
  .job-position {
    font-size: 1.34rem;
    padding: 0.1rem 0.5rem;
  }
  .job-btn {
    gap: 4rem;
  }
  .job-tags div {
    font-size: 14px;
  }
  .job-button {
    padding: 0.34rem !important;
    font-size: 17x !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 791px) and (max-width: 1220px) {
  .job-card-box {
    gap: 4.2rem;
  }
  .job-position {
    font-size: 1.54rem;
    padding: 0.1rem 0.5rem;
  }
  .job-btn {
    gap: 4.4rem;
  }
  .job-tags div {
    font-size: 15px;
  }
  .job-button {
    padding: 0.44rem !important;
    font-size: 18x !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 1221px) and (max-width: 1890px) {
  .job-card-box {
    gap: 4.2rem;
  }
  .job-position {
    font-size: 1.54rem;
    padding: 0.1rem 0.5rem;
  }
  .job-btn {
    gap: 4.4rem;
  }
  .job-tags div {
    font-size: 15px;
  }
  .job-button {
    padding: 0.44rem !important;
    font-size: 18x !important;
    height: auto !important;
  }
}
.job-button {
  opacity: 0.8 !important;
}

.job-button:hover {
  opacity: 1 !important;
}
