.header-container {
  display: flex;
  align-items: center;
  gap: 3.25rem;
}

.header-container-image {
  height: 100%;
  width: 100%;
}

.mentor-profile-image {
  flex-shrink: 0;

  height: 100%;
  width: 100%;
  border-radius: 15.59638rem;
  background: linear-gradient(
      0deg,
      rgba(17, 17, 17, 0) 0%,
      rgba(17, 17, 17, 0) 100%
    ),
    lightgray 50% / cover no-repeat;
}

.mentor-profile-image-ourt-circle {
  width: 17.03238rem;
  height: 17.03238rem;
  flex-shrink: 0;
  border-radius: 17.03238rem;
  border: 4px solid var(--primary-purple-heart, #5f22d9);
  position: absolute;
}

.mentor-profile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8125rem;
}

.name-btn {
  display: flex;
  align-items: center;
  gap: 15.1875rem;
}

.social-links {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.name {
  display: flex;
  flex-direction: column;
}
.buttons {
  display: flex;
  align-items: center;
  gap: 1.5625rem;
}
.name-text {
  color: var(--neutrals-scorpion, #5b5b5b);
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}
.name-frame {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  margin-top: -10px;
}
.name-frame-profile {
  white-space: nowrap;
  color: var(--light-mode-grey-300, #858585);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mentor-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  /* padding: 0; */
}
.social-icon:nth-child(1) {
  color: #0077b5 !important;
  font-size: 24px;
  cursor: pointer;
}

.social-icon:nth-child(2) {
  color: #1da1f2 !important;
  font-size: 24px;
  cursor: pointer;
}

.social-icon:nth-child(3) {
  color: #1877f2 !important;
  font-size: 24px;
  cursor: pointer;
}

.mentor-container-text {
  color: var(--Selective-Yellow, #ffb703);
  text-align: center;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 177%; /* 1.99125rem */
}
.mentee-container-text {
  color: #ff477e;
  text-align: center;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 177%; /* 1.99125rem */
}
.buttons-follow-connect {
  color: var(--light-mode-white, #fff) !important;
  font-family: Helvetica Now Display;
  font-size: 1.3rem !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border: 0px;
  border-radius: 0.5rem !important;
  background: var(--primary-purple-heart, #5f22d9) !important;
}

.footer-text {
  color: var(--light-mode-black, #000);
  text-align: justify;
  font-family: Source Serif Pro;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.045rem;
}
.menu-text {
  color: var(--light-mode-black, #000);
  justify-content: center !important;
  font-family: Source Serif Pro !important;
  font-size: 1.3rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}
.menu-text-dange {
  color: red !important;
  justify-content: center !important;
  font-family: Source Serif Pro !important;
  font-size: 1.3rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}
.mentor-profile-image-profile {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 320px) {
  .mentor-profile-image-profile {
    width: 100px;
    height: 100px;
  }
  .name-text {
    font-size: 1.2rem;
  }
  .name-frame-profile {
    font-size: 0.89rem;
  }
  .mentor-container-text {
    font-size: 0.79rem;
  }
  .user-circle-mentor-profile {
    width: 14px;
  }
  .mentor-container {
    gap: 0.3rem;
  }
  .buttons-follow-connect {
    font-size: 1rem !important;
    height: auto !important;
    padding: 0.2rem 0.3rem !important;
  }
  .user-add-mentor {
    width: 20px;
  }
  .footer-text {
    font-size: 15px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .mentor-profile-image-profile {
    width: 150px;
    height: 150px;
  }
  .name-text {
    font-size: 1.44rem;
  }
  .name-frame-profile {
    font-size: 0.91rem;
  }
  .mentor-container-text {
    font-size: 0.89rem;
  }
  .user-circle-mentor-profile {
    width: 16px;
  }
  .mentor-container {
    gap: 0.3rem;
  }
  .buttons-follow-connect {
    font-size: 1.1rem !important;
    height: auto !important;
    padding: 0.2rem 0.4rem !important;
  }
  .user-add-mentor {
    width: 20px;
  }
  .footer-text {
    font-size: 17px;
  }
}
@media only screen and (min-width: 770px) and (max-width: 1025px) {
  .mentor-profile-image-profile {
    width: 190px;
    height: 190px;
  }
  .name-text {
    font-size: 1.54rem;
  }
  .name-frame-profile {
    font-size: 0.99rem;
  }
  .mentor-container-text {
    font-size: 0.92rem;
  }
  .user-circle-mentor-profile {
    width: 18px;
  }
  .mentor-container {
    gap: 0.3rem;
  }
  .buttons-follow-connect {
    font-size: 1.1rem !important;
    height: auto !important;
    padding: 0.2rem 0.4rem !important;
  }
  .user-add-mentor {
    width: 20px;
  }
  .footer-text {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1026px) and (max-width: 1225px) {
  .mentor-profile-image-profile {
    width: 200px;
    height: 200px;
  }
  .name-text {
    font-size: 1.6rem;
  }
  .name-frame-profile {
    font-size: 0.999rem;
  }
  .mentor-container-text {
    font-size: 0.95rem;
  }
  .user-circle-mentor-profile {
    width: 19px;
  }
  .mentor-container {
    gap: 0.3rem;
  }
  .buttons-follow-connect {
    font-size: 1.12rem !important;
    height: auto !important;
    padding: 0.2rem 0.4rem !important;
  }
  .user-add-mentor {
    width: 20px;
  }
  .footer-text {
    font-size: 21px;
  }
}
@media only screen and (min-width: 1226px) and (max-width: 1440px) {
  .mentor-profile-image-profile {
    width: 210px;
    height: 210px;
  }
  .name-text {
    font-size: 1.67rem;
  }
  .name-frame-profile {
    font-size: 1rem;
  }
  .mentor-container-text {
    font-size: 0.99rem;
  }
  .user-circle-mentor-profile {
    width: 20px;
  }
  .mentor-container {
    gap: 0.3rem;
  }
  .buttons-follow-connect {
    font-size: 1.12rem !important;
    height: auto !important;
    padding: 0.2rem 0.4rem !important;
  }
  .user-add-mentor {
    width: 20px;
  }
  .footer-text {
    font-size: 23px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1810px) {
  .mentor-profile-image-profile {
    width: 240px;
    height: 240px;
  }
  .name-text {
    font-size: 1.77rem;
  }
  .name-frame-profile {
    font-size: 1.1rem;
  }
  .mentor-container-text {
    font-size: 0.99rem;
  }
  .user-circle-mentor-profile {
    width: 20px;
  }
  .mentor-container {
    gap: 0.3rem;
  }
  .buttons-follow-connect {
    font-size: 1.12rem !important;
    height: auto !important;
    padding: 0.2rem 0.4rem !important;
  }
  .user-add-mentor {
    width: 20px;
  }
  .footer-text {
    font-size: 23px;
  }
}
