.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5.5rem;
  min-height: 50vh;
  margin-bottom: 3rem;
}

.login-btn {
  display: flex;
  width: 12.25rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 3.625rem;
  background: var(--primary-purple-heart, #5f22d9);
}

.login-btn button {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: 0px;
}

.signup-page-main-contain {
  border-radius: 15px !important;
}

.dark-mode-bg {
  background-color: #5a5a5a !important;
}