.blog-post-container {
  display: flex;
  padding: 2.25rem 5.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4375rem;
  max-width: 100%;
  width: 100%;
}

.blog-post-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
  align-self: stretch;
}

.blog-post-navigation-header {
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
  /* align-items: flex-start;
  align-self: stretch; */
}

.blog-post-navigation-list {
  display: flex;
  align-items: flex-start;
  gap: 4.4375rem;
}
.blog-post-navigation-list-a {
  color: var(--light-mode-black, #5f22d9) !important;
  font-family: Helvetica Now Display;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.blogs-container-page-blog {
  flex-direction: column;
}
.blog-section-options-button {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 570;
  line-height: normal;
  letter-spacing: 0.033rem;
  text-decoration: none;
}
.option-icons-images {
  width: 30px !important;
  font-weight: bolder;
  padding-right: 1rem;
}

@media screen and (max-width: 380px) {
  .blog-section-options-button span {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .blog-post-navigation-list-a {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 420px) and (max-width: 480px) {
  .blog-post-navigation-list-a {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 320px) {
  .blog-post-navigation-list {
    gap: 0.5rem;
  }
}
@media screen and (min-width: 321px) and (max-width: 375px) {
  .blog-post-navigation-list {
    gap: 1rem;
  }
}

@media screen and (min-width: 426px) and (max-width: 440px) {
  .blog-post-navigation-list {
    gap: 2.86rem !important;
  }
}
@media screen and (min-width: 440px) and (max-width: 455px) {
  .blog-post-navigation-list {
    gap: 3.8rem !important;
  }
}

@media screen and (min-width: 475px) and (max-width: 500px) {
  .blog-post-navigation-list {
    gap: 3.8rem !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 525px) {
  .blog-post-navigation-list {
    gap: 6.2rem !important;
  }
}
@media screen and (min-width: 525px) and (max-width: 575px) {
  .blog-post-navigation-list {
    gap: 6.2rem !important;
  }
  .css-1uodvt1 {
    gap: 1.4rem !important;
  }
}

@media screen and (min-width: 575px) and (max-width: 625px) {
  .blog-post-navigation-list {
    gap: 3rem !important;
  }
  .css-1uodvt1 {
    gap: 0.8rem !important;
  }
}
@media screen and (min-width: 625px) and (max-width: 675px) {
  .blog-post-navigation-list {
    gap: 2.3rem !important;
  }
  .css-1uodvt1 {
    gap: 2rem !important;
  }
}

@media screen and (min-width: 675px) and (max-width: 725px) {
  .blog-post-navigation-list {
    gap: 5rem !important;
  }
  .css-1uodvt1 {
    gap: 2rem !important;
  }
}
@media screen and (min-width: 725px) and (max-width: 769px) {
  .blog-post-navigation-list {
    gap: 9.8rem !important;
  }
  .css-1uodvt1 {
    gap: 1.5rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 800px) {
  .blog-post-navigation-list {
    gap: 11.8rem !important;
  }
  .css-1uodvt1 {
    gap: 1.5rem !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 845px) {
  .blog-post-navigation-list {
    gap: 13.8rem !important;
  }
  .css-1uodvt1 {
    gap: 1.5rem !important;
  }
}

@media screen and (min-width: 845px) and (max-width: 900px) {
  .blog-post-navigation-list {
    gap: 16.8rem !important;
  }
  .css-1uodvt1 {
    gap: 1.5rem !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  .blog-post-navigation-list {
    gap: 19.8rem !important;
  }
  .css-1uodvt1 {
    gap: 1.5rem !important;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1030px) {
  .blog-post-navigation-list {
    gap: 25.4rem !important;
  }
  .css-1uodvt1 {
    gap: 1.88rem !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 426px) {
  .blog-post-navigation-list {
    gap: 1.66rem !important;
  }
}
@media screen and (max-width: 480px) {
  .blog-post-container {
    padding: 0.7rem 0.4rem;
  }
  .blog-post-navigation {
    gap: 0.5rem;
  }

  .dot-blogs {
    width: 25px;
    height: auto;
    cursor: pointer !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .blog-post-container {
    padding: 1rem 1rem;
  }
  .blog-post-navigation {
    gap: 0.5rem;
  }
  .blog-post-navigation-list {
    gap: 2.4rem;
  }

  .dot-blogs {
    width: 35px;
    height: auto;
  }
}

@media screen and (min-width: 480px) and (max-width: 610px) {
  .blog-post-navigation-list-a {
    font-size: 1.2rem !important;
    font-weight: bolder !important;
  }
  .blog-post-container {
    padding: 0.6rem 0.6rem;
  }
}
@media screen and (min-width: 610px) and (max-width: 768px) {
  .blog-post-navigation-list-a {
    font-size: 1.7rem !important;
    font-weight: bolder !important;
  }
  .blog-post-container {
    padding: 1rem 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .blog-post-navigation-list-a {
    font-size: 1.71rem !important;
    font-weight: bolder !important;
  }
  .blog-post-container {
    padding: 1rem 1rem;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1700px) {
  .blog-post-container {
    padding: 1.1rem 1rem;
  }
  .blog-post-navigation-list-a {
    font-size: 1.758rem !important;
    font-weight: bolder !important;
  }
  .tabs-content {
    gap: 4rem !important;
  }
}
@media only screen and (max-width: 350px) {
  .blog-post-navigation-list-a {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1031px) and (max-width: 1075px) {
  .blog-post-navigation-list {
    gap: 22.2rem !important;
  }
  .css-1uodvt1 {
    gap: 3.4rem !important;
  }
}
@media screen and (min-width: 1075px) and (max-width: 1125px) {
  .blog-post-navigation-list {
    gap: 22.4rem !important;
  }
  .css-1uodvt1 {
    gap: 5.4rem !important;
  }
}
@media screen and (min-width: 1126px) and (max-width: 1170px) {
  .blog-post-navigation-list {
    gap: 22.4rem !important;
  }
}
@media screen and (min-width: 1170px) and (max-width: 1225px) {
  .blog-post-navigation-list {
    gap: 24.4rem !important;
  }
}
@media screen and (min-width: 1226px) and (max-width: 1275px) {
  .blog-post-navigation-list {
    gap: 27.4rem !important;
  }
  .css-1uodvt1 {
    gap: 6.4rem !important;
  }
}
@media screen and (min-width: 1275px) and (max-width: 1325px) {
  .blog-post-navigation-list {
    gap: 32.4rem !important;
  }
}
@media screen and (min-width: 1325px) and (max-width: 1375px) {
  .blog-post-navigation-list {
    gap: 34.4rem !important;
  }
}
@media screen and (min-width: 1375px) and (max-width: 1425px) {
  .blog-post-navigation-list {
    gap: 37.4rem !important;
  }
}

@media screen and (min-width: 1426px) and (max-width: 1441px) {
  .blog-post-navigation-list {
    gap: 39.4rem !important;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1475px) {
  .blog-post-navigation-list {
    gap: 39.4rem !important;
  }
}
@media screen and (min-width: 1476px) and (max-width: 1500px) {
  .blog-post-navigation-list {
    gap: 39.4rem !important;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1525px) {
  .blog-post-navigation-list {
    gap: 41.4rem !important;
  }
}
@media screen and (min-width: 1526px) and (max-width: 1575px) {
  .blog-post-navigation-list {
    gap: 41.4rem !important;
  }
}
@media screen and (min-width: 1575px) and (max-width: 1600px) {
  .blog-post-navigation-list {
    gap: 45.4rem !important;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1625px) {
  .blog-post-navigation-list {
    gap: 49.4rem !important;
  }
}
@media screen and (max-width: 1720px) {
  .blog-post-container {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}
