.blog-post-main-container {
  display: flex;
  background: var(--light-mode-grey-100, #f6f6f6);
  align-items: flex-start;
  gap: 0.625rem;
}

.blog-post-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5rem;
}

.blog-post-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.blog-post-heading {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-post-heading-second {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  align-items: center !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blog-post-content-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
}

.blog-post-more-mentor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.blog-post-more-mentor-heading {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.mentor-heading-text {
  flex-shrink: 0;
  color: var(--light-mode-black, #000);
  font-family: Helvetica Now Display;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06rem;
}

.blog-post-more-mentor-heading-viewall {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.viewall-text {
  color: var(--light-mode-black, #000);
  font-family: Helvetica Now Display;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0525rem;
}

.view-all-image {
  width: 2rem;
  height: 2rem;
}

.blog-post-more-card {
  display: flex;
  width: 75rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4rem 6.25rem;
  flex-wrap: wrap;
}

.blog-post-more-footer {
  display: flex;
  width: 75rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-top: 3rem;
}

.blog-post-more-footer-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 1rem;
}

.blog-post-more-footer-heading-first-para {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  align-self: stretch;
}

.blog-post-more-footer-heading-second-para {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.blog-post-more-footer-button {
  display: flex;
  padding: 0.875rem 3.25rem;
  justify-content: center;
  align-items: center;
  gap: -0.5625rem;
  border-radius: 4.1875rem;
  background: var(--primary-purple-heart, #5f22d9);
}

.blog-post-more-footer-button button {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--primary-purple-heart, #5f22d9);
  border: 0px;
}
.blog-heading-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}
@media (max-width: 450px) {
  .view-all-content {
    padding: 10px !important;
  }
  .mentor-heading-text {
    font-size: 1.4rem;
  }
  .viewall-text {
    font-size: 1rem;
  }
  .view-all-image {
    height: 20px;
    width: 20px;
  }
}
.view-all-image {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (min-width: 250px) and (max-width: 570px) {
  .blog-post-more-footer-heading-first-para {
    font-size: 1.3rem !important;
  }
  .blog-post-more-footer-heading-second-para {
    font-size: 1rem !important;
  }
  .blog-post-more-footer-button {
    padding: 0.575rem 1.25rem;
  }
  .blog-post-more-footer-button button {
    font-size: 1rem;
  }
  .blog-post-heading-second {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .main-blog-full {
    width: 82.7rem !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1470px) {
  .main-blog-full {
    width: 88.7rem !important;
  }
}
@media screen and (min-width: 1470px) and (max-width: 1700px) {
  .main-blog-full {
    width: 92.7rem !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 550px) {
  .blog-post-heading-second {
    padding: 1rem;
  }
  .blog-heading-title {
    padding: 1rem;
  }
  .mentor-heading-text {
    font-size: 1.1rem;
  }
  .viewall-text {
    font-size: 1rem;
  }
  .view-all-image {
    width: 1rem;
    height: 1rem;
  }
  .view-all-content {
    padding: 0.2rem 1.6rem !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1250px) {
  .main-blog-full {
    width: 58rem !important;
  }
  .blog-post-heading-second {
    width: 55rem !important;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .main-blog-full {
    width: 75rem !important;
  }
  .blog-post-heading-second {
    width: 70rem !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1770px) {
  .main-blog-full {
    width: 85rem !important;
  }
  .blog-post-heading-second {
    width: 87rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .main-blog-full {
    width: 40rem !important;
  }

  .blog-post-heading-second {
    font-size: 1.3rem;
    width: 40rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .view-all-content {
    padding: 0.2rem 1rem !important;
  }

  .mentor-heading-text {
    font-size: 2rem;
  }
  .viewall-text {
    font-size: 1.5rem;
  }
  .view-all-image {
    height: 1.6rem;
    width: 1.6rem;
  }
  .small-blog-card-text-heading {
    font-size: 1.3rem;
  }
}
.main-blogg {
  margin-top: 2rem;
  /* width: 0%; */
}

.view-all-content {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) and (max-width: 1220px) {
  .view-all-content {
    padding: 0.2rem 2.2rem !important;
  }
  .small-blogs-container {
    max-width: 60rem;
  }
  .mentor-heading-text {
    font-size: 2rem;
  }
  .viewall-text {
    font-size: 1.5rem;
  }
  .view-all-image {
    height: 1.6rem;
    width: 1.6rem;
  }
  .small-blog-card-text-heading {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1220px) and (max-width: 1310px) {
  .view-all-content {
    padding: 0.3rem 3.5rem !important;
  }
  .small-blogs-container {
    max-width: 75rem !important;
  }
  .mentor-heading-text {
    font-size: 2.1rem !important;
  }
  .viewall-text {
    font-size: 1.6rem !important;
  }
  .view-all-image {
    height: 1.7rem !important;
    width: 1.7rem !important;
  }
  .small-blog-card-text-heading {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1310px) and (max-width: 1440px) {
  .view-all-content {
    padding: 0.3rem 6.8rem !important;
  }
  .small-blogs-container {
    max-width: 77rem !important;
  }
  .mentor-heading-text {
    font-size: 2.2rem !important;
  }
  .viewall-text {
    font-size: 1.7rem !important;
  }
  .view-all-image {
    height: 1.7rem !important;
    width: 1.7rem !important;
  }
  .small-blog-card-text-heading {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1380px) {
  .view-all-content {
    padding: 0.3rem 6rem !important;
  }
}
.blog-end-footer {
  margin-top: 2rem !important;
}
@media screen and (min-width: 768px) and (max-width: 830px) {
  .small-blogs-container {
    max-width: 50rem !important;
  }
  .view-all-content {
    padding: 0.5rem 1rem !important;
  }
}
@media screen and (min-width: 1320px) and (max-width: 1400px) {
  .small-blogs-container {
    max-width: 80rem;
  }
  .view-all-content {
    padding: 0.5rem 3.5rem !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .small-blogs-container {
    max-width: 85rem;
  }
  .view-all-content {
    padding: 0.5rem 4rem;
  }
}

@media screen and (min-width: 1490px) and (max-width: 1690px) {
  .small-blogs-container {
    max-width: 85rem;
  }
  .view-all-content {
    padding: 0.5rem 5rem;
  }
}
@media screen and (max-width: 475px) {
  .main-blogg {
    margin-top: 1rem;
  }
  .main-blog-full {
    width: 100% !important;
  }
  .blog-heading-section {
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .blog-post-heading {
    font-size: 2rem;
  }
  .blog-post-heading-second {
    font-size: 1.2rem;
  }
  .blog-heading-title {
    padding: 0.6rem;
  }
}

.likeShrink-con {
  transition: all 0.3s ease-in-out;
}
.likeShrink {
  transform: scale(0.8);
}
.likeShrink-none {
  transform: scale(1);
}
