.password-update-form-heading-top {
  font-family: Poppins;
  font-weight: 600;
  font-size: 2rem;
}

.password-update-sub-heading-lable {
  color: var(--light-mode-grey-300, #858585);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.password-update-input-text-field {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
}
.password-update-input-text-field ::placeholder {
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 450;
}

.mail-to-admin-cb-text {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
}
