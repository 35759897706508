.ourmission-section {
  text-align: center;
}

.ourmission-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mission-section-title {
  padding: 1rem 1rem 0 1rem;
}

.mission-section-title span {
  color: var(--primary-purple-heart, #5f22d9);
  text-align: center;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mission-description {
  padding: 0.5rem 1.4rem;
  color: var(--base-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 168.5%;
}

.ourmission-content p b {
  color: var(--primary-purple-heart, #5f22d9);
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 168.5%;
  padding-bottom: 1rem;
}

.mission-image {
  width: 89.8125rem;
  height: 34rem;
  padding: 15.125rem 35.8125rem;
  border-radius: 1.5625rem;
  padding: 1rem 1rem;
}

.mission-image img {
  max-width: 100%;
}

.mission-image-text {
  padding-top: 1rem;
  color: var(--primary-purple-heart, #5f22d9);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 168.5%;
}

.mission-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.mission-button a {
  text-decoration: none !important;
  color: var(--base-white, #fff) !important;
  text-align: center;
  font-family: Poppins !important;
  font-size: 1.75rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4.1875rem;
  background: var(--button-primary-default, #0c58c6) !important;
  display: flex;
  width: 16.4375rem;
  padding: 1.0625rem;
  justify-content: center;
  align-items: center;
  gap: -0.5625rem;
  border: 0px;
  transition: background-color 0.3s ease;
}

.mission-button a:hover {
  background-color: var(--button-primary-hover, #0058a6) !important;
}

@media only screen and (max-width: 420px) {
  .mission-section-title span {
    font-size: 3rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.2rem;
  }

  .mission-image-text {
    font-size: 1.11rem;
  }

  .mission-image {
    width: 100%;
    height: 100%;
  }

  .mission-button a {
    width: 10rem;
    height: 1rem;
    font-size: 16px !important;
    padding: 1rem 1rem;
  }
}

@media only screen and (min-width: 420px) and (max-width: 520px) {
  .mission-section-title span {
    font-size: 3.1rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.1rem;
  }

  .mission-image-text {
    font-size: 1.15rem;
  }

  .mission-image {
    width: 100%;
    height: 100%;
  }

  .mission-button a {
    width: 10rem;
    height: 1.2rem;
    font-size: 16px !important;
    padding: 1rem 1rem;
  }
}

@media only screen and (min-width: 520px) and (max-width: 640px) {
  .mission-section-title span {
    font-size: 3.2rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.18rem;
  }

  .mission-image-text {
    font-size: 1.19rem;
  }

  .mission-image {
    width: 100%;
    height: 100%;
  }

  .mission-button a {
    width: 10rem;
    height: 1.2rem;
    font-size: 17px !important;
    padding: 1rem 1rem;
  }
}

@media only screen and (min-width: 640px) and (max-width: 720px) {
  .mission-section-title span {
    font-size: 3.23rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.18rem;
  }

  .mission-image-text {
    font-size: 1.19rem;
  }

  .mission-image {
    width: 100%;
    height: 100%;
  }

  .mission-button a {
    width: 11rem;
    height: 1.4rem;
    font-size: 1.2rem !important;
  }
}

@media only screen and (min-width: 720px) and (max-width: 768px) {
  .mission-section-title span {
    font-size: 3.3rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.2rem;
  }

  .mission-image-text {
    font-size: 1.21rem;
  }

  .mission-image {
    width: 75%;
    height: 100%;
  }

  .mission-button a {
    width: 11rem;
    height: 1.4rem;
    font-size: 1.22rem !important;
    padding: 1rem 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mission-section-title span {
    font-size: 3.5rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.22rem;
  }

  .mission-image-text {
    font-size: 1.23rem;
  }

  .mission-image {
    width: 75%;
    height: 100%;
  }

  .mission-button a {
    width: 11.4rem;
    height: 1.4rem;
    font-size: 1.32rem !important;
    padding: 1rem 1rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .mission-section-title span {
    font-size: 3.7rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.33rem;
  }

  .mission-image-text {
    font-size: 1.34rem;
  }

  .mission-image {
    width: 75%;
    height: 100%;
  }

  .mission-button a {
    width: 11rem;
    height: 2rem;
    font-size: 1.31rem !important;
  }
}

@media only screen and (min-width: 1220px) and (max-width: 1440px) {
  .mission-section-title span {
    font-size: 4rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.44rem;
  }

  .mission-image-text {
    font-size: 1.45rem;
  }

  .mission-image {
    width: 75%;
    height: 100%;
  }

  .mission-button a {
    width: 13rem;
    height: 2.1rem;
    font-size: 1.36rem !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1770px) {
  .mission-section-title span {
    font-size: 4.1rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.54rem;
  }

  .mission-image-text {
    font-size: 1.55rem;
  }

  .mission-image {
    width: 90%;
    height: 100%;
  }

  .mission-button a {
    width: 14rem;
    height: 2.3rem;
    padding: 1rem 1rem;
    font-size: 1.55rem !important;
  }
}

@media only screen and (min-width: 1771px) {
  .mission-section-title span {
    font-size: 4.1rem;
  }

  .mission-description,
  .ourmission-content p b {
    font-size: 1.54rem;
  }

  .mission-image-text {
    font-size: 1.55rem;
  }

  .mission-image {
    width: 100%;
    height: 100%;
  }

  .mission-button a {
    width: 14.2rem;
    height: 2.33rem;
    padding: 1rem 1rem;
    font-size: 1.58rem !important;
  }
}