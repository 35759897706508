.hero-section-main-conatiner {
  display: flex;
  padding: 0.875rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-shrink: 0;
}
.hero-section-headline {
  font-weight: bolder;
  font-family: poppins;
  font-size: 1.5rem;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 2rem; /* 152.381% */
  letter-spacing: 0.1575rem;
  text-transform: uppercase;
}

.dark-mode-sec {
  transition: all 0.3s ease-in-out;
  color: white !important;
}
.hero-section-banner-slider {
  display: flex;
  height: 50%;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  width: 90%;
  border: none;
}
.hero-section-banner-slider:hover {
  outline: none;
  border: none;
}
.Slide {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.hero-section-headline-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section-headline-tabs ul {
  display: flex;
  padding: 2px;
  gap: 3rem;
}
.hero-section-headline-tabs ul li {
  list-style: none;
  background: var(--light-mode-grey-200, #e5e5e5);
  padding: 0.7rem;
  border-radius: 50px;
  color: black;
  font-weight: 200;
}
.hero-section-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-16, 1rem);
  align-self: stretch;
  padding: 5px;
}

.hero-section-footer p {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.145rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hero-section-footer button {
  display: flex;
  width: 12.25rem;
  height: 3.125rem;
  padding: 1.0625rem 7.6875rem;
  justify-content: center;
  align-items: center;
  gap: -0.5625rem;
  border-radius: 4.1875rem;
  background: var(--primary-purple-heart, #5f22d9);
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 480px) {
  .hero-section-headline {
    font-size: 1rem;
    font-weight: bold;
    padding: 5px;
    line-height: 1.4rem;
  }
  .slick-slider {
    display: inherit !important;
    max-height: 200px;
    width: 98%;
  }
}
@media screen and (min-width: 481px) and (max-width: 660px) {
  .hero-section-headline-tabs ul li {
    font-size: 12px;
    padding: 0.3rem !important;
  }
}
@media screen and (max-width: 768px) {
  .hero-section-footer button {
    width: 5rem;
    height: 2rem;
    padding: 0rem 3.6875rem;
    font-size: 1rem;
  }
}
.carousel-container {
  padding: 20px;
}

.carousel-slide img {
  max-width: 100%;
}

@media (max-width: 768px) {
  .carousel-container {
    padding: 10px;
  }
}
@media (min-width: 500px) and (max-width: 700px) {
  .hero-section-headline {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 5px;
  }
}
@media (min-width: 700px) and (max-width: 1024px) {
  .hero-section-headline {
    font-size: 1.5rem !important;
  }
  .hero-section-headline-tabs ul li {
    font-size: 17px;
  }
}
@media (min-width: 1024px) and (max-width: 1320px) {
  .hero-section-footer p {
    font-size: 1.62rem !important;
  }
  .hero-section-headline {
    font-size: 2rem !important;
  }
  .hero-section-headline-tabs ul li {
    font-size: 20px;
  }
}
@media (min-width: 1320px) and (max-width: 1600px) {
  .hero-section-headline {
    font-size: 2.4rem !important;
  }
  .hero-section-headline-tabs ul li {
    font-size: 20px;
  }
}
@media screen and (min-width: 1470px) and (max-width: 1700px) {
  .hero-section-footer p {
    font-size: 1.5rem !important;
  }
  .hero-section-headline {
    font-size: 2.6rem !important;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1470px) {
  .hero-section-footer p {
    font-size: 1.6rem !important;
  }
}
@media (min-width: 200px) and (max-width: 330px) {
  .hero-section-headline-tabs ul {
    gap: 2px !important;
  }
  .hero-section-headline-tabs ul li {
    font-size: 10px !important;
    padding: 0.3rem !important;
  }
}
@media (min-width: 330px) and (max-width: 480px) {
  .hero-section-headline-tabs ul {
    gap: 5px;
  }
  .hero-section-headline-tabs ul li {
    font-size: 13px;
    padding: 0.1rem !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 550px) {
  .hero-section-headline {
    padding: 1rem;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .hero-section-headline-tabs ul li {
    font-size: 11px;
    padding: 0.2rem !important;
  }
}
