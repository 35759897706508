.heading-one {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading-two {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 650;
  line-height: 110%; /* 6.6rem */
  letter-spacing: 0.245rem;
}

.heading-Third {
  color: var(--primary-purple-heart, #5f22d9);
  font-family: Poppins;
  font-size: 4.2rem;
  font-style: normal;
  font-weight: 650;
  line-height: 110%;
}

@media only screen and (max-width: 320px) {
  .heading-one {
    font-size: 1rem;
  }
  .heading-two {
    font-size: 1.4rem;
    letter-spacing: 0.22rem;
  }
  .heading-Third {
    font-size: 1.5rem;
    letter-spacing: 0.12rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 399px) {
  .heading-one {
    font-size: 1.2rem;
  }
  .heading-two {
    font-size: 1.8rem;
    letter-spacing: 0.22rem;
  }
  .heading-Third {
    font-size: 1.9rem;
    letter-spacing: 0.12rem;
  }
}
@media only screen and (min-width: 400px) and (max-width: 600px) {
  .heading-one {
    font-size: 1.4rem;
  }
  .heading-two {
    font-size: 2.5rem;
    letter-spacing: 0.25rem;
  }
  .heading-Third {
    font-size: 2.6rem;
    letter-spacing: 0.2rem;
  }
}
@media only screen and (min-width: 521px) and (max-width: 820px) {
  .heading-one {
    font-size: 1.5rem;
  }
  .heading-two {
    font-size: 2.7rem;
    letter-spacing: 0.25rem;
  }
  .heading-Third {
    font-size: 2.8rem;
    letter-spacing: 0.2rem;
  }
}
