.heart-loader {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.2);
    box-shadow: 0 0 0 0 rgba(221, 0, 0, 0.7);
  }
  70% {
    transform: scale(0.7);
    box-shadow: 0 0 0 10px rgba(221, 0, 0, 0);
  }
  100% {
    transform: scale(0.6);
    box-shadow: 0 0 0 0 rgba(221, 0, 0, 0);
  }
}
