/* .Hero-Section {
  background: var(--light-mode-grey-100, #f6f6f6);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
} */

.landing-page-container {
  display: flex;
  height: 632rem;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}
.landing-page-youtube-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* Default styles for all screen sizes */
.youtube-section {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.landing-page-youtube-container {
  display: flex;
  flex-direction: column; /* Stack items in a column by default */
  align-items: center;
  justify-content: center;
}

.landing-page-youtube-image {
  width: 100%;
}

.landing-page-youtube-content {
  padding: 10px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .landing-page-youtube-container {
    flex-direction: row;
  }

  .landing-page-youtube-image {
    /* flex: 1; */
    margin-right: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .landing-page-youtube-image {
    margin-right: 40px;
  }
}

.landing-page-youtube-container {
  display: flex;
  margin-bottom: 2rem;
  /* background: var(--light-mode-grey-100, #f6f6f6); */

  justify-content: center;
  align-items: center;
  gap: 4.625rem;
}

.landing-page-youtube-content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.landing-page-youtube-content-first {
  color: #000;
  font-family: Poppins;
  text-align: start;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 500;
  word-wrap: normal;
  line-height: 110%; /* 2.2rem */
  transition: all 0.3s ease-in-out;
}
.dark-mode-sec {
  color: white !important;
}
.content-list-top {
  margin-top: 2rem !important;
}
.bullet {
  margin-right: 1rem;
}
.landing-page-youtube-content-second {
  color: #000;
  white-space: pre-line;
  text-align: start;
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 229.5%; /* 5.16375rem */
}
.landing-page-youtube-content-second li {
  list-style: none;
}
.landing-page-youtube-viewmore-button {
  display: flex;
  padding: 0.875rem 3.25rem;
  justify-content: center;
  align-items: center;
  gap: -0.5625rem;
  border-radius: 4.1875rem;
  background: var(--primary-purple-heart, #5f22d9);
}
.landing-page-youtube-viewmore-button button {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--primary-purple-heart, #5f22d9);
  border: 0px;
  cursor: pointer;
}
.comunity-section {
  padding: 2rem 4rem !important;
}
.landing-page-community-section {
  display: flex;
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  background-color: #f6f6f6;
}

.landing-page-community-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.875rem;
}

.landing-page-community-section-content-text {
  color: var(--base-black, #000);
  text-align: center;

  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 177.5%; /* 3.10625rem */
  letter-spacing: 0.0525rem;
}
.landing-page-community-section-content-button {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 177.5%; /* 2.6625rem */
  letter-spacing: 0.045rem;
  border-radius: 5.5rem;
  background: var(--primary-purple-heart, #5f22d9);
}

@media screen and (max-width: 768px) {
  .landing-page-youtube-container {
    flex-direction: column;
  }

  .landing-page-youtube-image {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 200px) and (max-width: 630px) {
  .landing-page-youtube-viewmore-button button {
    font-size: 1rem;
  }
  .landing-page-youtube-viewmore-button {
    padding: 0.5rem 1.25rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .landing-page-youtube-container {
    flex-direction: column;
  }
  .landing-page-youtube-content-first {
    font-size: 1.4rem !important;
    line-height: 2rem !important;
  }
  .landing-page-youtube-content-second {
    font-size: 1.2rem !important;
    margin-top: 0.8rem !important;
  }
  .landing-page-youtube-container {
    gap: 1.2rem !important;
  }
  .landing-page-community-section-content-text {
    font-size: 1rem;
  }
  .landing-page-community-section {
    padding: 1rem;
  }
  .landing-page-community-section-content-button {
    font-size: 1rem;
  }
}
@media screen and (min-width: 570px) and (max-width: 768px) {
  .landing-page-youtube-content-second {
    font-size: 1rem !important;
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .landing-page-youtube-content-first {
    font-size: 1.5rem !important;
  }
  .landing-page-youtube-content-second {
    font-size: 1rem !important;
    margin-top: 1rem !important;
  }
}
@media screen and (min-width: 920px) and (max-width: 1024px) {
  .landing-page-youtube-content-first {
    font-size: 2rem !important;
  }
  .landing-page-youtube-content-second {
    font-size: 1.2rem !important;
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .landing-page-youtube-container {
    flex-direction: row;
  }
  ul {
    padding-left: 0 !important;
  }
  .landing-page-youtube-viewmore-button {
    padding: 1rem !important;
  }
  .landing-page-youtube-viewmore-button button {
    font-size: 1rem;
  }

  .landing-page-youtube-container {
    gap: 1.3rem !important;
  }
}
@media screen and (min-width: 1224px) and (max-width: 1700px) {
  .landing-page-community-section-content-text {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1700px) {
  .landing-page-youtube-container {
    gap: 1rem !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .youtube-image-box {
    max-width: 30rem;
    overflow-x: scroll;
  }
}
@media screen and (min-width: 1024px) {
  .landing-page-youtube-content-first {
    font-size: 2.3rem;
  }
  .landing-page-youtube-content-second li {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1700px) {
  .youtube-section {
    padding: 5rem;
  }
}
@media screen and (min-width: 200px) and (max-width: 550px) {
  .comunity-section {
    padding: 1rem !important;
  }
}
