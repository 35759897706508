.signup-text {
  color: #858585;
  font-size: 2.6rem;
  font-family: Poppins !important;
  font-weight: 600 !important;
  font-style: normal !important;
}

.mentee-signup-form-lable-text {
  font-size: 1.35rem !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.mentee-signup-form-input-field {
  border-radius: 0.9375rem !important;
  background: var(--light-mode-grey-200, #e5e5e5) !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  color: black;
  font-size: 1.2rem !important;
  font-family: Poppins !important;
}

.mentee-signup-form-input-field::placeholder {
  color: #858585;
  font-size: 1rem !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
}

@media screen and (max-width: 520px) {
  .signup-text {
    font-size: 2rem;
  }
}