.askme-any-thing-heading {
  color: var(--neutrals-scorpion, #5b5b5b);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  line-height: 182.5%; /* 1.825rem */
  letter-spacing: 0.03rem;
}

.ask-me-anything-container {
  display: flex;
  width: 31.5625rem;
  padding: 0.4375rem 0.875rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 2.875rem;
  background: var(--light-mode-grey-200, #e5e5e5);
}

.input-text {
  color: var(--light-mode-grey-300, #858585) !important;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03rem;
  width: 100%;
  background-color: #e5e5e5;
  border: 0px;
  transition: #e5e5e5 0.3s ease-in-out;
}

.ask-me-anything-container input :focus {
  border-color: transparent;
}

.ask-me-anything-container Button {
  display: flex;
  padding: 0.1875rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: var(--base-white, #fff);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 182.5%; /* 1.825rem */
  letter-spacing: 0.03rem;
  border-radius: 1.9375rem;
  background: var(--primary-purple-heart, #5f22d9);
  border: 2px solid #5f22d9;
}
.askme-anything-btn {
  background-color: #5f22d9 !important;
  color: white !important;
  font-family: Poppins !important;
}
