.mentee-info-search-bar-input {
  background-color: #e5e5e5 !important;
  color: #333333 !important;
  border-radius: 40px !important;
}
.input-box-icon-mentee-info-page {
  padding-right: 5px !important;
}

.mentee-info-page-seach-text-lable {
  font-size: 1.15rem !important;
  font-family: Poppins !important;
  font-weight: 450 !important;
  color: #333333;
  white-space: nowrap;
}
.mentee-info-search-tags {
  font-size: 1rem !important;
  color: #333333 !important;
  font-family: Poppins !important;
  font-weight: 450 !important;
  white-space: nowrap !important;
}
.mentee-info-search-tag-contain {
  width: max-content !important;
  background-color: #e5e5e5 !important;
  border-radius: 40px !important;
  padding: 8px 14px !important;
}
.mentee-info-other-input-field {
  background-color: #e5e5e5 !important;
  font-family: Poppins !important;
  font-weight: 450 !important;
  font-size: 1rem !important;
}
.mentee-info-other-input-field::placeholder {
  font-family: Poppins !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  color: #333333 !important;
}

.mentee-info-other-input-lable-text {
  font-family: Poppins !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  color: #ffffff !important;
  padding: 0.5rem 1rem !important;
}

.hover-add-menteinfo {
  background-color: #5f22d9 !important;
  color: white !important;
}

@media screen and (max-width: 570px) {
  .mentee-info-page-seach-text-lable {
    font-size: 1.1rem !important;
    white-space: pre-line !important;
  }
}
