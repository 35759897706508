.leader-section {
  padding: 1rem 0rem;
  text-align: center;
  width: 100%;
}

.leader-section-heading h2 {
  color: var(--gunmetal, #253344);
  text-align: center;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.leader-section-description p {
  color: var(--gunmetal, #253344);
  text-align: center;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 420px) {
  .leader-section-heading h2 {
    font-size: 3rem;
  }

  .leader-section-description {
    max-width: 400px;
  }

  .leader-section-description p {
    font-size: 1.4rem;
    width: 100%;
  }
}

@media only screen and (min-width: 420px) and (max-width: 520px) {
  .leader-section-heading h2 {
    font-size: 3.1rem;
  }

  .leader-section-description {
    max-width: 500px;
  }

  .leader-section-description p {
    font-size: 1.25rem;
    width: 100%;
  }
}

@media only screen and (min-width: 520px) and (max-width: 640px) {
  .leader-section-heading h2 {
    font-size: 3.2rem;
  }

  .leader-section-description {
    max-width: 510px;
  }

  .leader-section-description p {
    font-size: 1.3rem;
    width: 100%;
  }
}

@media only screen and (min-width: 640px) and (max-width: 720px) {
  .leader-section-heading h2 {
    font-size: 3.23rem;
  }

  .leader-section-description p {
    font-size: 1.37rem;
    width: 100%;
  }
}

@media only screen and (min-width: 720px) and (max-width: 768px) {
  .leader-section-heading h2 {
    font-size: 3.3rem;
  }

  .leader-section-description p {
    font-size: 1.39rem;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .leader-section-heading h2 {
    font-size: 3.5rem;
  }

  .leader-section-description p {
    font-size: 1.48rem;
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .leader-section-info-container {
    gap: 4rem;
  }

  .leader-section-heading h2 {
    font-size: 3.7rem;
  }

  .leader-section-description p {
    font-size: 1.49rem;
    width: 100%;
  }
}

@media only screen and (min-width: 1220px) and (max-width: 1440px) {
  .leader-section-heading h2 {
    font-size: 4.65rem;
  }

  .leader-section-info-container {
    gap: 4.2rem;
  }

  .leader-section-description p {
    font-size: 1.55rem;
    width: 100%;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1740px) {
  .leader-section-heading h2 {
    font-size: 5.2rem;
  }

  .leader-section-info-container {
    gap: 4.6rem;
  }

  .leader-section-description p {
    font-size: 2rem;
    width: 100%;
  }
}

@media only screen and (min-width: 1740px) and (max-width: 2000px) {
  .leader-section-heading h2 {
    font-size: 6.2rem;
  }

  .leader-section-info-container {
    gap: 5rem;
  }

  .leader-section-description p {
    font-size: 2.4rem;
    width: 100%;
  }
}