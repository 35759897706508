.otp-form-container {
  height: auto;
  /* padding: 40px 30px; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.otp-form-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input_field_box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input_field_box input {
  border: none;
  max-width: 15%;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background: #e5e5e5;
  font-size: 25px;
}

.input_field_box input::-webkit-inner-spin-button,
.input_field_box input::-webkit-outer-spin-button {
  display: none;
}

/* .otp-form-form button {
  margin-top: 25px;
  width: 92%;
  color: #525252;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  pointer-events: none;
  cursor: pointer;
  background: #e9d585;
  transition: all 0.2s ease;
}
form button.active {
  background: #ffcc00;
  pointer-events: auto;
  color: #000000;
}
form button:hover {
  background: #e6b801;
} */
