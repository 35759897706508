.services-card-tag {
  border-radius: 30px !important;
  background-color: #5a5a5a;
  color: white !important;
  padding: 0.3rem 0.7rem !important;
  white-space: nowrap !important;
  font-size: 1.1rem !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.services-card-tag-descr {
  color: rgb(0, 0, 0);
  white-space: nowrap;
  font-size: 1.1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mentors-services-title {
  color: rgb(0, 0, 0);
  font-size: 1.7rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mentors-services-subtitle {
  color: #5a5a5a;
  font-size: 1.2rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mentors-serices-cost-btn {
  border-radius: 8px !important;
  border: 2px solid black;
  background-color: transparent !important;
  height: auto !important;
  padding: 0.5rem 0.5rem !important;
  white-space: nowrap !important;
  font-size: 1.1rem !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
  width: 10rem !important;
}
.mentors-serices-book-btn {
  border-radius: 8px !important;
  background-color: #4361ee !important;
  color: white !important;
  height: auto !important;
  width: 10rem !important;
  padding: 0.5rem 0.5rem !important;
  white-space: nowrap !important;
  font-size: 1.1rem !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}

@media only screen and (max-width: 320px) {
  .services-card-tag {
    padding: 0.4rem 0.4rem !important;
    font-size: 12px !important;
  }
  .mentors-services-title {
    font-size: 1rem !important;
  }
  .services-card-tag-descr {
    font-size: 0.75rem !important;
    white-space: normal !important;
    text-align: center;
  }
  .mentors-services-subtitle {
    font-size: 11px !important;
  }
  .mentors-serices-cost-btn {
    width: 6rem !important;
    font-size: 14px !important;
    padding: 0.5rem 0.7rem !important;
  }
  .mentors-serices-book-btn {
    width: 6.5rem !important;
    font-size: 14px !important;
    padding: 0.5rem 0.7rem !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .services-card-tag {
    padding: 0.6rem 0.6rem !important;
    font-size: 13px !important;
  }
  .mentors-services-title {
    font-size: 1.2rem !important;
  }
  .services-card-tag-descr {
    font-size: 0.85rem !important;
    white-space: normal !important;
    text-align: center;
  }
  .mentors-services-subtitle {
    font-size: 11.2px !important;
  }
  .mentors-serices-cost-btn {
    width: 6.5rem !important;
    font-size: 14px !important;
    padding: 0.5rem 0.8rem !important;
  }
  .mentors-serices-book-btn {
    width: 7rem !important;
    font-size: 14px !important;
    padding: 0.5rem 0.85rem !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .services-card-tag {
    padding: 0.5rem 0.9rem !important;
    font-size: 13.5px !important;
  }
  .mentors-services-title {
    font-size: 1.24rem !important;
  }
  .services-card-tag-descr {
    font-size: 0.95rem !important;
    white-space: normal !important;
    text-align: center;
  }
  .mentors-services-subtitle {
    font-size: 12.2px !important;
  }
  .mentors-serices-cost-btn {
    width: 8rem !important;
    font-size: 14px !important;
    padding: 0.5rem 0.8rem !important;
  }
  .mentors-serices-book-btn {
    width: 8.6rem !important;
    font-size: 14px !important;
    padding: 0.5rem 0.85rem !important;
  }
}
