.hero-section {
  background-color: rgb(95, 34, 217);
  padding: 1rem;
  padding-bottom: 5rem;
  margin-top: -1rem;
}

.hero-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.section-title {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section-description {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
}
@media only screen and (max-width: 420px) {
  .hero-section-about-us span {
    font-size: 2.4rem;
  }

  .hero-section-about-us p {
    font-size: 1rem;
    width: 100%;
  }
}
@media only screen and (min-width: 420px) and (max-width: 520px) {
  .hero-section-about-us span {
    font-size: 3.1rem;
  }

  .hero-section-about-us p {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 520px) and (max-width: 640px) {
  .hero-section-about-us span {
    font-size: 3.2rem;
  }

  .hero-section-about-us p {
    font-size: 1.18rem;
  }
}
@media only screen and (min-width: 640px) and (max-width: 720px) {
  .hero-section-about-us span {
    font-size: 3.23rem;
  }

  .hero-section-about-us p {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 720px) and (max-width: 768px) {
  .hero-section-about-us span {
    font-size: 3.3rem;
  }

  .hero-section-about-us p {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hero-section-about-us span {
    font-size: 3.5rem;
  }

  .hero-section-about-us p {
    font-size: 1.22rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .hero-section-about-us span {
    font-size: 3.7rem;
  }
  .hero-section-container {
    padding-top: 2.88rem;
  }
  .hero-section-about-us p {
    font-size: 1.33rem;
  }
}
@media only screen and (min-width: 1220px) and (max-width: 1440px) {
  .hero-section-about-us span {
    font-size: 4.65rem;
  }
  .hero-section-container {
    padding-top: 2.98rem;
  }
  .hero-section-about-us p {
    font-size: 1.44rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1770px) {
  .hero-section-about-us span {
    font-size: 5.5rem;
  }
  .hero-section-container {
    padding-top: 3.5rem;
  }
  .hero-section-about-us p {
    font-size: 1.66rem;
  }
}
