.jobs-page-container {
  display: flex;
  /* width: 90rem; */
  flex-direction: column;
  align-items: center;
  gap: 2.75rem;
}

.jobs-page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.75rem;
  margin-top: 2rem;
  align-self: stretch;
}

.jobs-page-header-text {
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.jobs-page-header-p {
  text-align: center;
  font-family: Poppins;
  font-size: 1.55rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.jobs-internship-container {
  display: flex;
  padding: 2.5625rem 0.3rem;
  flex-direction: column;
  /* align-items: center; */
  gap: 1.4375rem;
  align-self: stretch;
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}

.jobs-full-time {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  margin-bottom: 1rem;
}
.jobs-internship {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.125rem;
}

.jobs-full-time-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.25rem;
}

.jobs-card-full-time {
  display: flex;
  width: 76.1875rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 3.125rem 3.6875rem;
  flex-wrap: wrap;
  justify-content: center;
}

.bottom-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.75rem;
  align-self: stretch;
}

.bottom-head-span {
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dark-mode-heading {
  color: white !important;
}
.bottom-head-p {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 420px) {
  .jobs-page-container {
    gap: 2rem;
  }
  .jobs-page-header-text {
    font-size: 1.5rem;
    padding: 0.5rem 0.5rem;
  }
  .jobs-page-header p {
    font-size: 1rem;
    padding: 0 1rem;
  }
  .jobs-full-time {
    gap: 1.2rem;
  }
  .jobs-full-time-heading {
    font-size: 1rem;
    padding: 0 0.1rem;
  }
  .jobs-full-time {
    padding: 0.4rem 0.3rem;
  }
}

@media screen and (min-width: 421px) and (max-width: 520px) {
  .jobs-page-container {
    gap: 2rem;
  }
  .jobs-page-header-text {
    font-size: 1.5rem;
    padding: 0.5rem 0.5rem;
  }
  .jobs-page-header p {
    font-size: 1rem;
    padding: 0 1rem;
  }
  .jobs-full-time {
    gap: 1.2rem;
  }
  .jobs-full-time-heading {
    font-size: 1.2rem;
    padding: 0 0.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .bottom-head span {
    font-size: 1.4rem;
  }
  .bottom-head p {
    font-size: 1rem;
    padding: 0.2rem 0.4rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 720px) {
  .bottom-head span {
    font-size: 1.77rem;
  }
  .bottom-head p {
    font-size: 1.1rem;
    padding: 0.2rem 0.6rem;
  }
}
.all-jobs-open-btn {
  border-radius: 20px !important;
  background-color: rgb(95, 34, 217) !important;
  height: auto !important;
  color: white !important;
  padding: 0.4rem 0.6rem !important;
}
