.leader-card-container {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--49, 3.0625rem);
  width: 100%;
}

.leader-card-profile-info {
  display: flex;
  width: 26.6875rem;
  flex-direction: column;
  align-items: center;
  gap: 0.9375rem;
}

.leader-card-name {
  display: flex;
  align-items: center;
}

.leader-profile-desc {
  color: var(--gunmetal, #253344);
  text-align: center;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

.leader-name {
  color: var(--gunmetal, #253344);
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.leader-profile {
  color: var(--light-mode-grey-400, #5a5a5a);
  text-align: center;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.leader-profile-description {
  max-width: 100%;
}

.leader-social-links {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
}

.leader-social-link {
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
}

.dark-mode-icons path {
  fill: rgb(255, 255, 255) !important;
}

@media only screen and (max-width: 420px) {
  .leader-card-container {
    gap: 1.4rem;
  }

  .leader-card-profile-info {
    width: 100%;
  }

  .Leader-image {
    width: 6.4rem;
    height: 6.4rem;
  }

  .leader-name {
    font-size: 1.2rem;
  }

  .leader-profile {
    font-size: 1rem;
  }

  .leader-profile-desc {
    font-size: 1.12rem;
    max-width: 250px;
  }

  .leader-social-links {
    gap: 4rem;
  }

  .leader-social-link {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media only screen and (min-width: 420px) and (max-width: 520px) {
  .leader-card-container {
    gap: 1.76rem;
  }

  .leader-card-profile-info {
    width: 100%;
  }

  .Leader-image {
    width: 6.6rem;
    height: 6.6rem;
  }

  .leader-name {
    font-size: 1.26rem;
  }

  .leader-profile {
    font-size: 1.16rem;
  }

  .leader-profile-desc {
    font-size: 1.12rem;
    max-width: 260px;
  }

  .leader-social-links {
    gap: 4rem;
  }

  .leader-social-link {
    width: 1.4rem;
    height: 1.4rem;
  }
}

@media only screen and (min-width: 520px) and (max-width: 640px) {
  .leader-card-container {
    gap: 1.8rem;
  }

  .leader-card-profile-info {
    width: 100%;
  }

  .Leader-image {
    width: 6.8rem;
    height: 6.8rem;
  }

  .leader-name {
    font-size: 1.4rem;
  }

  .leader-profile {
    font-size: 1.3rem;
  }

  .leader-profile-desc {
    font-size: 1.1rem;
    max-width: 300px;
  }

  .leader-social-links {
    gap: 4rem;
  }

  .leader-social-link {
    width: 1.6rem;
    height: 1.6rem;
  }
}

@media only screen and (min-width: 640px) and (max-width: 1024px) {
  .leader-card-container {
    gap: 1.82rem;
  }

  .leader-card-profile-info {
    width: 100%;
  }

  .Leader-image {
    width: 6.83rem;
    height: 6.83rem;
  }

  .leader-name {
    font-size: 1.43rem;
  }

  .leader-profile {
    font-size: 1.29rem;
  }

  .leader-profile-desc {
    font-size: 1.14rem;
    max-width: 310px;
  }

  .leader-social-links {
    gap: 4rem;
  }

  .leader-social-link {
    width: 1.6rem;
    height: 1.6rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .leader-card-container {
    gap: 1.86rem;
  }

  .leader-card-profile-info {
    width: 100%;
  }

  .Leader-image {
    width: 6.9rem;
    height: 6.9rem;
  }

  .leader-name {
    font-size: 1.53rem;
  }

  .leader-profile {
    font-size: 1.41rem;
  }

  .leader-profile-desc {
    font-size: 1.21rem;
    max-width: 350px;
  }

  .leader-social-links {
    gap: 4rem;
  }

  .leader-social-link {
    width: 1.6rem;
    height: 1.6rem;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2000px) {
  .leader-card-container {
    gap: 1.89rem;
  }

  .leader-card-profile-info {
    width: 100%;
  }

  .Leader-image {
    width: 7.1rem;
    height: 7.1rem;
  }

  .leader-name {
    font-size: 1.62rem;
  }

  .leader-profile {
    font-size: 1.54rem;
  }

  .leader-profile-desc {
    font-size: 1.25rem;
    max-width: 370px;
  }

  .leader-social-links {
    gap: 4.2rem;
  }

  .leader-social-link {
    width: 1.67rem;
    height: 1.67rem;
  }
}