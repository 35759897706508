.faq-question-container-main {
  background-color: #e5e5e5;
  max-width: 60rem !important;
  padding: 5px;
}
.dark-mode-sec {
  color: white !important;
  fill: white !important;
}
.faq-question-title {
  color: var(--base-black, #000);
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 182.5%;
  letter-spacing: 0.0525rem;
}
.faq-question-description {
  color: var(--base-black, #000);

  font-family: Poppins;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 400;
  line-height: 142.5%;
}
.pin-faq-main {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 325px) {
  .faq-question-container-main {
    padding: 0.5rem;
    width: 100% !important;
  }
  .faq-question-title {
    font-size: 16px;
  }
  .faq-question-description {
    font-size: 13.4px;
  }
}
@media screen and (min-width: 326px) and (max-width: 420px) {
  .faq-question-container-main {
    padding: 0.2rem;
    width: 100% !important;
  }
  .faq-question-title {
    font-size: 16.8px;
  }
  .faq-question-description {
    font-size: 13.7px;
  }
}
@media screen and (min-width: 421px) and (max-width: 521px) {
  .faq-question-container-main {
    padding: 0.1rem;
    width: 100% !important;
  }
  .faq-question-title {
    font-size: 18.8px;
  }
  .faq-question-description {
    font-size: 15.7px;
  }
}
@media screen and (min-width: 522px) and (max-width: 630px) {
  .faq-question-container-main {
    padding: 0rem !important;
    width: 100% !important;
  }
  .faq-question-title {
    font-size: 19.8px;
  }
  .faq-question-description {
    font-size: 15.99px;
  }
}
@media screen and (min-width: 631px) and (max-width: 769px) {
  .faq-question-container-main {
    padding: 0rem !important;
    width: 100% !important;
  }
  .faq-question-title {
    font-size: 19.8px;
  }
  .faq-question-description {
    font-size: 15.99px;
  }
}
