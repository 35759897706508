.resource-card-heading-title {
  color: black;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 43.2px */
}

.resource-card-tag-text {
  background-color: #ac73ff;
  border-radius: 48px;
  color: white;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.resource-card-price-text {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.resource-card-btn-text {
  border-radius: 45.023px;
  background: #5f22d9;
  color: white;
  text-align: center;
  font-family: Poppins;
  font-size: 15.525px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 340px) {
  .resource-card-heading-title {
    font-size: 16px;
  }
  .resource-card-btn-text {
    font-size: 14px;
  }
  .resource-card-tag-text {
    font-size: 10px;
  }
  .resource-card-price-text {
    font-size: 15px;
  }
}
