.mentee-info-details-lable-text {
  font-size: 1.3rem !important;
  font-family: Poppins !important;
  white-space: nowrap !important;
  font-weight: 500 !important;
}
.mentee-info-details-description-text {
  font-size: 1rem !important;
  font-family: Poppins !important;
  font-weight: 450 !important;
}
.mentee-info-details-heading-text {
  font-size: 1.4rem !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  color: #333333;
}
