.categories-main-container-text {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 560;
  line-height: normal;
}

.tab-title-name-categories {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 1.4rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.045rem;
}

.active-tab {
  color: black !important;
  font-weight: 600 !important;
  border-bottom: 4px solid #000 !important; /* Customize the border color as needed */
}

/* Prevent the tab from changing color when active */
.active-tab:focus {
  color: black !important;
}
.active-tab :active {
  color: black !important;
}
.dark :active {
  color: blue !important;
}
.dark :focus {
  color: blue !important;
}
.dark {
  color: blueviolet !important;
}

.tablist-containerfull::-webkit-scrollbar {
  display: none !important;
}
@media only screen and (max-width: 341px) {
  .categories-main-container-text {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  .tablist-containerfull {
    max-width: 17.5rem !important;
    overflow-x: auto;
  }
  .tab-title-name-categories {
    font-size: 1.2rem !important;
  }
}
@media only screen and (min-width: 341px) and (max-width: 400px) {
  .categories-main-container-text {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  .tablist-containerfull {
    max-width: 17.5rem !important;
    overflow-x: auto;
  }
  .tab-title-name-categories {
    font-size: 1.2rem !important;
  }
}

@media only screen and (min-width: 401px) and (max-width: 520px) {
  .categories-main-container-text {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  .tablist-containerfull {
    max-width: 22.5rem !important;
    overflow-x: auto;
  }
  .tab-title-name-categories {
    font-size: 1.2rem !important;
  }
}
@media only screen and (min-width: 521px) and (max-width: 620px) {
  .categories-main-container-text {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  .tablist-containerfull {
    max-width: 25.5rem !important;
    overflow-x: auto;
  }
  .tab-title-name-categories {
    font-size: 1.2rem !important;
  }
}
@media only screen and (min-width: 621px) and (max-width: 767px) {
  .categories-main-container-text {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  .tablist-containerfull {
    max-width: 30.5rem !important;
    overflow-x: auto;
  }
  .tab-title-name-categories {
    font-size: 1.2rem !important;
  }
}

.dark-mode-sec {
  color: white !important;
  transition: all 0.3s ease-in-out;
}
