@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Nunito:wght@500&display=swap");

* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}
html {
  overflow-x: hidden;
  touch-action: manipulation;
}
.view-all-content {
  padding: 0 !important;
  margin-top: 2rem !important;
}
ol,
ul {
  padding-left: 1rem !important;
}
.effect {
  position: relative;
  overflow: hidden;
}

.effect::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ec8c0f;
  transition: width 0.4s;
}

.effect:hover::before {
  width: 100%;
}

/* custom scroller */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #565252;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #565252;
}
.switch-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  justify-content: center;
  margin: auto;
  height: 30px;
}

.switch-button .switch-outer {
  height: 100%;
  background: #252532;
  width: 55px;
  border-radius: 165px;
  -webkit-box-shadow: inset 0px 5px 10px 0px #16151c, 0px 3px 6px -2px #403f4e;
  box-shadow: inset 0px 5px 10px 0px #16151c, 0px 3px 6px -2px #403f4e;
  border: 1px solid #32303e;
  padding: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.switch-button .switch-outer input[type="checkbox"] {
  opacity: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
}

.switch-button .switch-outer .button-toggle {
  height: 15px;
  width: 15px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3b3a4e),
    to(#272733)
  );
  background: -o-linear-gradient(#3b3a4e, #272733);
  background: linear-gradient(#3b3a4e, #272733);
  border-radius: 100%;
  -webkit-box-shadow: inset 0px 5px 4px 0px #424151, 0px 4px 15px 0px #0f0e17;
  box-shadow: inset 0px 5px 4px 0px #424151, 0px 4px 15px 0px #0f0e17;
  position: relative;
  z-index: 2;
  -webkit-transition: left 0.3s ease-in;
  -o-transition: left 0.3s ease-in;
  transition: left 0.3s ease-in;
  left: 0;
}

.switch-button
  .switch-outer
  input[type="checkbox"]:checked
  + .button
  .button-toggle {
  left: 58%;
}

.switch-button
  .switch-outer
  input[type="checkbox"]:checked
  + .button
  .button-indicator {
  -webkit-animation: indicator 1s forwards;
  animation: indicator 1s forwards;
}

.switch-button .switch-outer .button {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.switch-button .switch-outer .button-indicator {
  height: 10px;
  width: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  border: 3px solid #ef565f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  right: 5px;
  position: relative;
}

@-webkit-keyframes indicator {
  30% {
    opacity: 0;
  }

  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    border: 3px solid #60d480;
    left: -68%;
  }
}

@keyframes indicator {
  30% {
    opacity: 0;
  }

  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    border: 3px solid #60d480;
    left: -68%;
  }
}

/* Button.css */

.pricing-button {
  background-color: #ffffff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.stylish-button.clicked {
  animation: fadeEffect 1s ease;
}

@keyframes fadeEffect {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  50% {
    transform: scaleX(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}
.calender-react-booking-start {
  max-width: 550px !important;
}
.dark-md-calendar-bg-cl {
  background-color: #5a5a5a !important;
}
.dark-md-calendar-bg-cl .react-calendar__navigation__label span {
  color: white !important;
}
.dark-md-calendar-bg-cl .react-calendar__tile:disabled {
  background-color: #000000 !important;
  opacity: 0.4 !important;
}
.dark-md-calendar-bg-cl .react-calendar__tile:disabled abbr {
  color: #ffffff;
}

.dark-md-calendar-bg-cl .react-calendar__tile {
  color: white !important;
}
.dark-md-calendar-bg-cl .react-calendar__navigation button:disabled {
  background-color: #858585 !important;
  display: none !important;
  color: white !important;
}
.toggle-label-switch {
  width: 100px;
  height: 40px;
  position: relative;
  display: block;
  background: transparent !important;
  border-radius: 50px;
  border: 0.9px solid #858585 !important;
  cursor: pointer;
  /* transition: all 0.1s ease-in-out; */
}
.dark {
  border: 0.9px solid #ffffff !important;
}
.toggle-label-switch:after {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  left: 5px;
  right: -5px !important;
  background-color: #858585;
  border-radius: 180px;
}
.dark::after {
  background-color: #ffffff !important;
  /* transition: all 0.3s ease-in-out; */
}
.toggle-input-box-theme {
  width: 0;
  height: 0;
  visibility: hidden !important;
}
input:checked + label:after {
  left: 85px;
  transform: translateX(-95%);
}
.toggle-label-switch,
.toggle-label-switch:after {
  transition: 0.4s;
}
.toggle-label-switch:active:after {
  width: 50px;
}

.toggle-label-switch svg {
  position: absolute;
  width: 30px;
  top: -6px;
  z-index: 100;
}
.toggle-label-switch svg.sun {
  left: 57px;
  fill: #ffb703 !important;
  transition: 0.3s;
}
