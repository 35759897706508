.terms-use-landing-top-heading {
  font-family: Poppins;
  font-size: 2.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.terms-use-landing-top-subheading {
  text-align: center;
  font-family: Poppins;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.terms-use-landing-mid-time-update {
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.terms-title-top-heading-main {
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
}

@media screen and (max-width: 525px) {
  .terms-use-landing-top-heading {
    font-size: 2rem;
    text-align: center;
  }
  .terms-use-landing-top-subheading {
    font-size: 1.4rem;
  }
}
