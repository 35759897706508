.testimonal-section-container {
  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3.3125rem;
}

.testimonal-section-heading {
  color: var(--light-mode-black, #000000);
  text-align: center;

  /* Body/Heading/semibold02 */
  font-family: Poppins;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.testimonal-section-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem !important;
  align-self: stretch;
}

.testimonial-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .testimonial-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .testimonial-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.testimonial-card {
  padding: 10px;
}

@media screen and (min-width: 1441px) and (max-width: 1590px) {
  .testinomial-main-responsive {
    max-width: 60rem !important;
  }
}
