.verify-otp-btn {
  background: var(--primary-purple-heart, #5f22d9) !important;
  border-radius: 3.625rem !important;
  color: white !important;
  font-family: Poppins !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
  width: 10rem !important;
  height: 2.5rem !important;
}

.form-container-otp-box {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
