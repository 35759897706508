.blog-container {
  display: flex;
  width: 75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4375rem;
}
.blog-heading-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.9375rem;
  align-self: stretch;
}
.blog-heading-desc-text {
  color: var(--light-mode-black, #000);
  text-align: justify;
  font-family: Source Serif Pro;
  font-size: 1.75rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.0525rem;
  align-self: stretch;
}

.blog-heading {
  display: flex;
  flex-direction: column;
  align-items: center;

  align-self: stretch;
}

.blog-heading-title {
  color: var(--light-mode-black, #000);
  font-family: Helvetica Now Display;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.075rem;
}
.blog-heading-desc {
  margin-top: 1rem;
  padding-bottom: 1rem;
  color: var(--light-mode-black, #000);
  font-family: Helvetica Now Display;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.075rem;
}
.blog-names-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.blog-name-details-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;
}

.blog-name-details-text img {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  background: lightgray 50% / cover no-repeat;
}

.blog-name-deatils {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.blog-name-deatils span {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.blog-name-deatils:last-child {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.follow-btn {
  display: flex;
  padding: 0.25rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: var(--primary-purple-heart, #5f22d9);
}
.follow-btn button {
  color: var(--light-mode-white, #fff);
  text-align: right;
  font-family: Helvetica Now Display;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--primary-purple-heart, #5f22d9);
  border: 0px;
}

.blog-deatils-time {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
.blog-deatils-time-min {
  color: var(--light-mode-black, #000);
  text-align: right;
  font-family: Helvetica Now Display;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog-deatils-time-dot {
  width: 0.3125rem;
  height: 0.3125rem;
  fill: var(--light-mode-black, #000);
}
.blog-deatils-time-date {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog-image-container {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
}
.blog-image-container-background {
  width: 75rem;
  height: 40rem;
  border-radius: 0.5rem;
  background: var(--light-mode-grey-200, #e5e5e5);
}

.blog-post-bottom-nav {
  display: flex;
  align-items: flex-start;
  gap: 49rem;
  align-self: stretch;
}

.blog-post-bottom-nav-tags {
  display: flex;
  align-items: flex-start;
  gap: 1.5625rem;
}

.blog-post-bottom-nav-bookmarks-like-options {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.blog-post-nav-tags-serach {
  display: flex;
  padding: 0.25rem 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 2.875rem;
  background: var(--light-mode-grey-200, #e5e5e5);
}
.blog-post-nav-tags-serach span {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.blog-post-bottom-nav-bookmarks-like-options {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.blog-post-bottom-nav-bookmarks-like-options-icons {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.blog-post-horizontal-line {
  width: 75rem;
  height: 0.09375rem;
  background: #000;
}
.blog-heading-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.big-blog-post {
  margin-top: 0.5rem;
}
@media screen and (min-width: 380px) and (max-width: 560px) {
  .blog-heading-desc {
    padding: 0.8rem 1rem;
  }
}
@media (min-width: 475px) and (max-width: 768px) {
  .blog-heading-title {
    font-size: 1.2rem !important;
  }
  .blog-heading-desc {
    font-size: 1rem;
  }
}
@media screen and (max-width: 475px) {
  .blog-heading-title {
    font-size: 18px;
    padding: 0px 10px;
    font-weight: 700;
  }
  .blog-heading-desc {
    font-size: 0.8rem;
    padding: 10px 10px;
  }
}
