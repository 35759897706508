.pricing-card-tag-box {
  background-color: #5f22d9 !important;
  border-radius: 10px !important;
  width: 100% !important;
}
.pricing-card-tag-lable {
  padding: 5px !important;
  justify-content: center !important;
  width: 100% !important;
  color: white !important;
  text-align: center !important;
  background-color: transparent;
  font-family: Poppins !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}
.pricing-card-plan-title-text {
  color: #5f22d9 !important;
  font-family: Poppins !important;
  font-size: 2.2rem !important;
  font-style: normal !important;
  font-weight: 580 !important;
  line-height: normal !important;
}
.pricing-card-plan-price-text {
  color: black !important;
  font-family: Poppins !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.pricing-card-plan-price-expiry {
  color: black !important;
  font-family: Poppins !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}
.pricing-card-plan-price-description {
  color: black !important;
  font-family: Poppins !important;
  font-size: 1.1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100% !important;
  text-align: center !important;
}

.pricing-card-plan-features-headline-text {
  color: black !important;
  font-family: Poppins !important;
  font-size: 1.7rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.pricing-card-plan-features-explain-text {
  color: black !important;
  font-family: Poppins !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}

.pricing-card-plan-get-stated-btn {
  background-color: #5f22d9;
  color: white;
  font-family: Poppins !important;
  font-size: 1.1rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  border-radius: 50px !important;
  padding: 0.3rem 2rem !important;
  transition: all 0.3s;
}
.pricing-card-plan-get-stated-btn-desc {
  color: black !important;
  font-family: Poppins !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}

.price-compare-sub-headline {
  color: black !important;
  font-family: Poppins !important;
  font-size: 1.8rem !important;
  font-style: normal !important;
  font-weight: 550 !important;
  line-height: normal !important;
}

.price-compare-content-point-text {
  color: black !important;
  font-family: Poppins !important;
  font-size: 1.3rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

@media only screen and (max-width: 375px) {
  .pricing-headling-text-headline {
    font-size: 1.95rem !important;
  }
  .pricing-headling-text-description {
    font-size: 1.25rem !important;
  }
  .pricing-headling-text-description-box {
    padding: 0.5rem !important;
  }
  .pricing-page-tabs-heading-text {
    font-size: 1.4rem !important;
  }
  .pricing-card-plan-title-text,
  .price-compare-sub-headline {
    font-size: 1.65rem !important;
  }
  .pricing-card-plan-features-explain-text {
    font-size: 1rem !important;
  }
}
