.suggestion-container-main {
  display: flex;
  width: 100%;
  padding: 3rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}

.suggestion-navigation {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
}
.suggestion-navigation-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--base-black, #000);
  font-family: Helvetica Now Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06rem;
}
.suggestion-navigation-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100% !important;
}

.suggestion-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}
@media only screen and (max-width: 480px) {
  .suggestion-container-main {
    padding: 1rem;
  }
  .suggestion-navigation-item {
    font-size: 18px;
  }
  .view-image-icon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 520px) {
  .suggestion-container-main {
    padding: 1.5rem;
  }
  .suggestion-navigation-item {
    font-size: 20px;
  }
  .view-image-icon {
    width: 22px;
    height: 22px;
  }
}
@media only screen and (min-width: 520px) and (max-width: 768px) {
  .suggestion-container-main {
    padding: 2rem;
  }
  .suggestion-navigation-item {
    font-size: 22px;
  }
  .view-image-icon {
    width: 25px;
    height: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .suggestion-container-main {
    padding: 2rem;
  }
  .suggestion-navigation-item {
    font-size: 1.7rem;
  }
  .view-image-icon {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1824px) {
  .suggestion-navigation-item {
    font-size: 1.9rem;
  }
  .view-image-icon {
    width: 33px;
    height: 33px;
  }
}
