.mentor-section-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--light-mode-white, #fff);
}

.mentor-section-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9.9375rem;
  flex-shrink: 0;
}

.mentor-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.mentor-section-content span {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 4.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all 0.3s ease-in-out;
  text-wrap: balance;
}
.dark-mode-sec span {
  color: white;
}
.mentor-section-content b {
  color: var(--primary-purple-heart, #5f22d9);
  font-family: Helvetica Now Display;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mentor-section-content p {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: stretch;
}
.dark-mode-sec p {
  color: white;
}
.mentor-section-content button {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4.1875rem;
  background: var(--primary-purple-heart, #5f22d9);

  flex-shrink: 0;
  cursor: pointer;
}

@media only screen and (max-width: 380px) {
  .mentor-section-head {
    gap: 2rem;
  }
  .mentor-section-content {
    gap: 1rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
  }
  .mentor-section-content span {
    font-size: 1.7rem !important;
  }
  .mentor-section-content b {
    font-size: 1.8rem;
  }
  .mentor-section-content p {
    padding: 0rem 0.2rem;
    font-size: 1.1rem;
  }
  .mentor-section-content button {
    width: 10rem;
    height: 2rem;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 380px) and (max-width: 430px) {
  .mentor-section-head {
    gap: 2.3rem;
  }
  .mentor-section-content {
    gap: 1rem;
    padding-bottom: 2.8rem;
    margin-top: 1rem;
  }
  .mentor-section-content span {
    padding: 0rem 1rem;
    font-size: 2rem !important;
  }
  .mentor-section-content b {
    font-size: 2.1rem;
  }
  .mentor-section-content p {
    padding: 0rem 0.5rem;
    font-size: 1.4rem;
  }
  .mentor-section-content button {
    width: 10rem;
    height: 2rem;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 430px) and (max-width: 520px) {
  .mentor-section-head {
    gap: 2.3rem;
  }
  .mentor-section-content {
    gap: 1rem;
    padding-bottom: 3rem;
    margin-top: 1rem;
  }
  .mentor-section-content span {
    padding: 0rem 1.1rem;
    font-size: 2.4rem !important;
  }
  .mentor-section-content b {
    font-size: 3.5rem;
  }
  .mentor-section-content p {
    padding: 0rem 0.7rem;
    font-size: 1.3rem;
  }
  .mentor-section-content button {
    width: 11rem;
    height: 2rem;
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 520px) and (max-width: 680px) {
  .mentor-section-head {
    gap: 2.3rem;
  }
  .mentor-section-content {
    gap: 1rem;
    padding: 3rem 0 7rem;
  }
  .mentor-section-content span {
    padding: 0rem 1.2rem;
    font-size: 2.7rem !important;
  }
  .mentor-section-content b {
    font-size: 2.8rem;
  }
  .mentor-section-content p {
    padding: 0rem 0.8rem;
    font-size: 1.4rem;
  }
  .mentor-section-content button {
    width: 11rem;
    height: 2rem;
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 680px) and (max-width: 768px) {
  .mentor-section-head {
    gap: 2.3rem;
  }
  .mentor-section-content {
    gap: 1.8rem;
    padding: 3rem 0 7rem;
  }
  .mentor-section-content span {
    padding: 0rem 2rem;
    font-size: 3rem !important;
  }
  .mentor-section-content b {
    font-size: 3.1rem;
  }
  .mentor-section-content p {
    padding: 0rem 1rem;
    font-size: 1.6rem;
  }
  .mentor-section-content button {
    width: 11rem;
    height: 2rem;
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mentor-section-head {
    gap: 2.4rem;
  }
  .mentor-section-content {
    gap: 2rem;
    padding: 3rem 0 7rem;
  }
  .mentor-section-content span {
    padding: 0rem 4rem;
    font-size: 3.2rem !important;
  }
  .mentor-section-content b {
    font-size: 3.3rem;
  }
  .mentor-section-content p {
    padding: 0rem 5rem;
    font-size: 1.6rem;
  }
  .mentor-section-content button {
    width: 14rem;
    height: 2rem;
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .mentor-section-head {
    gap: 2.5rem;
  }
  .mentor-section-content {
    gap: 2.5rem;
    padding: 3rem 0 7rem;
  }
  .mentor-section-content span {
    padding: 0rem 4rem;
    font-size: 3.4rem !important;
  }
  .mentor-section-content b {
    font-size: 3.5rem;
  }
  .mentor-section-content p {
    padding: 0rem 5rem;
    font-size: 1.6rem;
  }
  .mentor-section-content button {
    width: 16rem;
    height: 3rem;
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1220px) and (max-width: 1440px) {
  .mentor-section-head {
    gap: 2.6rem;
  }
  .mentor-section-content {
    gap: 2.5rem;
    padding: 3rem 2rem 7rem;
  }
  .mentor-section-content span {
    padding: 0rem 4rem;
    font-size: 3.7rem !important;
  }
  .mentor-section-content b {
    font-size: 3.8rem;
  }
  .mentor-section-content p {
    padding: 0rem 6rem;
    font-size: 1.6rem;
  }
  .mentor-section-content button {
    width: 18rem;
    height: 3rem;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1520px) {
  .mentor-section-head {
    gap: 2.7rem;
  }
  .mentor-section-content {
    gap: 2.8rem;
    padding: 3rem 0 7rem;
  }
  .mentor-section-content span {
    padding: 0rem 4rem;
    font-size: 3.7rem !important;
  }
  .mentor-section-content b {
    font-size: 3.8rem;
  }
  .mentor-section-content p {
    padding: 0rem 5rem;
    font-size: 1.6rem;
  }
  .mentor-section-content button {
    width: 18rem;
    height: 3rem;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1520px) and (max-width: 1750px) {
  .mentor-section-head {
    gap: 3rem;
  }
  .mentor-section-content {
    gap: 3rem;
    padding: 5rem 1rem 8rem;
  }
  .mentor-section-content span {
    padding: 0rem 4rem;
    font-size: 4.7rem !important;
  }
  .mentor-section-content b {
    font-size: 4.8rem;
  }
  .mentor-section-content p {
    padding: 0rem 5rem;
    font-size: 2rem;
  }
  .mentor-section-content button {
    width: 20rem;
    height: 3.1rem;
    font-size: 1.7rem;
  }
}
