.faq-section-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  padding: 1rem;
  background: var(--light-mode-grey-100, #ffffff);
}

.faq-section-title {
  color: var(--light-mode-black, #000000);
  text-align: center;

  /* Body/Heading/semibold02 */
  font-family: Poppins;
  font-size: 3.1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.faq-section-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
}

.faq-additional-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.faq-additional-question-text-one {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.faq-additional-question-text-two {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.faq-more-help-opt {
  color: #000;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
/* .faq-additional-question-text-two a {
  color: #000;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
} */
.dark-mode-sec-help {
  color: white !important;
}
/* @media (min-width: 300px) and (max-width: 768px) {
  .faq-section-title {
    font-size: 1.3rem !important;
  }
  .faq-additional-question-text-one {
    font-size: 1rem;
  }
  .faq-additional-question-text-two {
    font-size: 0.99rem;
  }
  .faq-additional-question-text-two a {
    font-size: 1rem;
  }
} */

@media screen and (max-width: 320px) {
  .faq-section-title {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 321px) and (max-width: 480px) {
  .faq-section-title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 481px) and (max-width: 769px) {
  .faq-section-title {
    font-size: 1.67rem;
  }
}
