.community-step-section {
  width: 100%;
}
.community-step-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.community-step-text {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-bottom: 2rem;
}
.community-step-content-heading {
  padding: 0.5rem 2rem;
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: block;
  letter-spacing: 0.06rem;
}
.community-step-content-desc {
  padding: 0rem 2rem;
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: block;
}
.community-step-content {
  max-width: 600px;
}

.community-step-text {
  padding: 0rem 5rem;
  font-family: Poppins;
  color: #5f22d9;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  font-style: italic;
}
.community-step-row {
  padding: 0.2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.community-step-img-left,
.community-step-img-right {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.community-step-img-left {
  order: 1;
}

.community-step-img-right {
  order: 2;
}

@media (min-width: 768px) {
  .community-step-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .community-step-row {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    gap: 3rem;
    margin-bottom: 4rem;
  }

  .community-step-img-left,
  .community-step-img-right {
    max-width: 45%;
    align-items: center;
    text-align: center;
  }

  .community-step-content {
    margin: 0 20px;
    text-align: left;
  }

  .community-step-img-left {
    order: inherit;
  }

  .community-step-img-right {
    order: 2;
  }
}

@media only screen and (max-width: 768px) {
  .community-step-row {
    padding: 1rem 0.5rem;
  }
  .community-step-content-heading {
    padding: 0.3rem 0.2rem;
    font-size: 1.1rem;
  }
  .community-step-content-desc {
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  .community-step-text span {
    font-size: 1.2rem;
    padding: 1rem 0.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .community-step-section {
    margin-top: 3rem;
  }
}
