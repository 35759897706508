.blog-post-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.blog-post-herosection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-post-herosection-first {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.135rem;
}

.blog-post-herosection-second-text {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.blog-post-herosection-second-text b {
  color: var(--primary-purple-heart, #5f22d9);
  font-family: Helvetica Now Display;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog-post-search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-post-search-box-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.1875rem;
}

.blog-post-search-box-top-section span {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06rem;
}

.blog-post-search-box-container {
  display: flex;
  padding: 0.8125rem 1.8125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
  background: var(--primary-purple-heart, #5f22d9);
}

.blog-post-search-box-frame {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.blog-post-search-box-frame input {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #5f22d9;
  border: 0px;
}

.blog-post-search-box-frame input:focus {
  outline: none;
}
.blog-post-search-box-frame input::placeholder {
  color: var(--base-white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.blog-post-recent-search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  gap: 2.75rem;
  flex-wrap: wrap;
}

.blog-post-search-box {
  padding: 10px;
}

.blog-post-search-box-top-section {
  text-align: center;
  margin: 2rem 0rem;
}

.blog-post-search-box-top-section span {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06rem;
}

.blog-post-search-box-container {
  display: flex;
  justify-content: center;
}

.blog-post-search-box-frame {
  display: flex;
  align-items: center;
  padding: 10px;
}

.blog-post-search-box-frame input[type="text"] {
  border: none;
  outline: none;
  width: 100%;
  padding: 5px;
  margin-left: 10px;
}

.blog-post-hero-section-content {
  text-align: center;
}

.blog-post-hero-section-title {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.135rem;
}

.blog-post-hero-section-subtitle {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Helvetica Now Display;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dark-mode-blog-page-texts {
  color: white !important;
}
.dark-mode-blog-page-texts span {
  color: #5f22d9 !important;
}

.blog-post-hero-section-subtitle span {
  color: var(--primary-purple-heart, #5f22d9);
  font-family: Helvetica Now Display;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (max-width: 768px) {
  .blog-post-search-box-top-section span {
    font-size: 1.3rem;
  }

  .blog-post-search-box-frame {
    gap: 5px;
    padding: 5px;
  }
  .blog-post-search-box-frame input[type="text"] {
    margin-left: 0;
    font-size: 15px;
  }
  .blog-post-hero-section-title {
    font-size: 1.8rem;
    padding-bottom: 0.4rem;
  }
  .blog-post-hero-section-subtitle {
    padding: 0.3rem 0rem;
    font-size: 1.3rem;
  }
  .blog-post-hero-section-subtitle span {
    font-size: 1.33rem;
  }
  .blog-post-search-box-top-section span {
    font-size: 1.3rem;
  }
  .blog-post-search-box-frame input[type="text"]::placeholder {
    font-size: 16px !important;
  }
  .blog-post-search-box-container {
    padding: 0;
    gap: 5px;
    max-width: 190px;
  }
  .blog-post-search-box-frame svg {
    padding: 3px;
    width: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .blog-post-hero-section-content {
    padding: 1rem 0.1rem;
  }
  .blog-post-hero-section-title {
    font-size: 1.3rem;
    padding-bottom: 0.4rem;
  }
  .blog-post-hero-section-subtitle {
    padding: 0.3rem 0rem;
    font-size: 1.1rem;
  }
  .blog-post-hero-section-subtitle span {
    font-size: 1.13rem;
  }
  .blog-post-search-box-top-section span {
    font-size: 1.2rem;
    width: 100%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .blog-post-hero-section-content {
    padding: 1rem 0.1rem;
  }
  .blog-post-hero-section-title {
    font-size: 2.7rem;
    padding-bottom: 0.4rem;
  }
  .blog-post-hero-section-subtitle {
    padding: 0.3rem 0rem;
    font-size: 1.9rem;
  }
  .blog-post-hero-section-subtitle span {
    font-size: 1.99rem;
  }
  .blog-post-search-box-top-section span {
    font-size: 2rem;
    width: 100%;
  }
  .blog-post-search-box-frame {
    gap: 5px;
    padding: 5px;
  }
  .blog-post-search-box-frame input[type="text"] {
    margin-left: 0;
    font-size: 15px;
  }
  .blog-post-search-box-frame input[type="text"]::placeholder {
    font-size: 18px !important;
  }
  .blog-post-search-box-container {
    padding: 0;
    gap: 5px;
    max-width: 200px;
  }
  .blog-post-search-box-frame svg {
    padding: 1px;
    width: 40px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .blog-post-hero-section-content {
    padding: 1.3rem 0.1rem;
  }
  .blog-post-hero-section-title {
    font-size: 2.9rem;
    padding-bottom: 0.4rem;
  }
  .blog-post-hero-section-subtitle {
    padding: 0.3rem 0rem;
    font-size: 2rem;
  }
  .blog-post-hero-section-subtitle span {
    font-size: 2.19rem;
  }
  .blog-post-search-box-top-section span {
    font-size: 2.1rem;
    width: 100%;
  }
  .blog-post-search-box-frame {
    gap: 5px;
    padding: 5px;
  }
  .blog-post-search-box-frame input[type="text"] {
    margin-left: 0;
    font-size: 20px;
  }
  .blog-post-search-box-frame input[type="text"]::placeholder {
    font-size: 18px !important;
  }
  .blog-post-search-box-container {
    padding: 0;
    gap: 5px;
    max-width: 200px;
  }
  .blog-post-search-box-frame svg {
    padding: 1px;
    width: 45px;
  }
}

@media only screen and (min-width: 1224px) and (max-width: 1440px) {
  .blog-post-hero-section-content {
    padding: 1.5rem 0.1rem;
  }
  .blog-post-hero-section-title {
    font-size: 3.7rem;
    padding-bottom: 0.5rem;
  }
  .blog-post-hero-section-subtitle {
    padding: 0.3rem 0rem;
    font-size: 2.5rem;
  }
  .blog-post-hero-section-subtitle span {
    font-size: 2.59rem;
  }
  .blog-post-search-box-top-section span {
    font-size: 2.3rem;
    width: 100%;
    margin-bottom: 2rem;
  }
  .blog-post-search-box-frame {
    gap: 5px;
    padding: 5px;
  }
  .blog-post-search-box-frame input[type="text"] {
    margin-left: 0;
    font-size: 22px;
  }
  .blog-post-search-box-frame input[type="text"]::placeholder {
    font-size: 22px !important;
  }
  .blog-post-search-box-container {
    padding: 0;
    gap: 5px;
    max-width: 250px;
  }
  .blog-post-search-box-frame svg {
    padding: 1px;
    width: 50px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1740px) {
  .blog-post-hero-section-content {
    padding: 1.5rem 0.1rem;
  }
  .blog-post-hero-section-title {
    font-size: 4.2rem;
    padding-bottom: 0.5rem;
  }
  .blog-post-hero-section-subtitle {
    padding: 0.5rem 0rem;
    font-size: 2.7rem;
  }
  .blog-post-hero-section-subtitle span {
    font-size: 2.79rem;
  }
  .blog-post-search-box-top-section span {
    font-size: 2.3rem;
    width: 100%;
    margin-bottom: 2rem;
  }
  .blog-post-search-box-frame {
    gap: 7px;
    padding: 7px;
  }
  .blog-post-search-box-frame input[type="text"] {
    margin-left: 0;
    font-size: 22px;
  }
  .blog-post-search-box-frame input[type="text"]::placeholder {
    font-size: 22px !important;
  }
  .blog-post-search-box-container {
    padding: 0;
    gap: 5px;
    max-width: 260px;
  }
  .blog-post-search-box-frame svg {
    padding: 1px;
    width: 50px;
  }
}
