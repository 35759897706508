.mentor-booking-details-name-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  white-space: nowrap;
}

.mentor-booking-ratings-text {
  background-color: #5f22d9;
  border-radius: 40px;
  color: white;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  padding: 2px 10px;
}

.mentor-booking-heading-title-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 580;
  line-height: normal;
  white-space: nowrap;
}

.mentor-booking-heading-decription-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mentor-booking-heading-call-duration-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.mentor-booking-time-zone-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.55rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mentor-booking-time-zone-description {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.35rem;
  font-style: normal;
  font-weight: 380;
  line-height: normal;
}

.timezone-select-option-lable {
  background-color: #f6f6f6;
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 480;
  line-height: normal;
}

.timezone-select-option-lable-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 480;
  line-height: normal;
}

.booking-page-select-date-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.booking-page-selected-date-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 480;
  line-height: normal;
}

.booking-confirm-details-btn {
  color: white;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 480;
  line-height: normal;
  background-color: #5f22d9de;
  border-radius: 10px;
}

.calender-react-booking-start {
  max-width: 450px !important;
}

.mentor-booking-second-section-heading-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 2.35rem;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  white-space: nowrap;
}

.feedback-cards-scroll::-webkit-scrollbar {
  width: 0px !important;
  background-color: transparent !important;
  color: transparent !important;
}

.feedback-cards-scroll::-webkit-scrollbar-thumb {
  width: 0px !important;
  background-color: transparent !important;
  color: transparent !important;
}

.feedback-cards-scroll::-webkit-scrollbar-track {
  width: 0px !important;
  background-color: transparent !important;
  color: transparent !important;
}

.dark-lable-name {
  color: white !important;
}

.mentee-booking-info-modal-details-back-btn-text {
  font-size: 1.4rem !important;
  font-family: Poppins;
}

.mentee-booking-settings-preview-card-booking-tag {
  background-color: #f6f6f6 !important;
  padding: 10px 20px !important;
  border-radius: 50px !important;
}

.mentee-booking-settings-preview-card-booking-tag-lable {
  font-family: Poppins !important;
  text-transform: capitalize !important;
  font-size: 1.1rem !important;
}

.mentee-booking-info-modal-details-cost-badge-text {
  background-color: #5f22d9 !important;
  padding: 8px 14px !important;
  font-family: Poppins;
  color: #fff !important;
}