.overview-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
}

.overview-section-container-heading {
  /* width: 23.75rem; */
  height: 6rem;
  color: var(--base-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.3s ease-in-out;
}
.dark-mode-sec {
  color: white !important;
}
.overview-section-content {
  display: flex;
  padding: 1.3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.1875rem;
  background: var(--light-mode-grey-200, #e5e5e5);
}

.overview-section-content-icons {
  display: flex;
  align-items: flex-start;
  gap: 8.9375rem;
}

.overview-section-content-icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
}

.overview-section-content-icons-container-image {
  display: flex;
  padding: 1.9375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.5625rem;
  border: 1px solid var(--base-white, #fff);
  background: var(--base-white, #fff);
}

.overview-section-content-icons-container-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -0.5rem;
}

.overview-section-icons-images {
  width: 3.75rem;
  height: 3.75rem;
  /* background:  lightgray 50% / cover no-repeat; */
}

.title {
  color: var(--light-mode-black, #000000);
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.count {
  color: var(--light-mode-black, #000000);
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.overview-section-content-icons {
  padding: 1rem;
}
@media (max-width: 600px) {
  .overview-section-content-icons {
    gap: 5px;
  }
}
@media (min-width: 510px) and (max-width: 768px) {
  .overview-section-content-icons {
    gap: 4rem;
  }
  .overview-section-content {
    padding: 1rem;
  }
  .title {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 820px) {
  .overview-section-content {
    padding: 1rem !important;
  }
  .overview-section-content-icons {
    gap: 3rem !important;
  }
}
@media screen and (min-width: 820px) and (max-width: 950px) {
  .overview-section-content {
    padding: 1rem !important;
  }
  .overview-section-content-icons {
    gap: 2rem !important;
  }
}
@media screen and (min-width: 950px) and (max-width: 1150px) {
  .overview-section-content {
    padding: 1rem !important;
  }
  .overview-section-content-icons {
    gap: 4rem !important;
  }
}
@media screen and (max-width: 425px) {
  .overview-section-container-heading {
    font-size: 2rem;
    height: 2rem;
  }
}
