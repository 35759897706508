.service-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.service-container-heading {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.service-container-heading-text {
  margin-bottom: 0.5rem;
  color: var(--light-mode-black, #000);
  font-family: Helvetica Now Display;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.045rem;
}

@media screen and (max-width: 1025px) {
  .service-container-heading-text {
    font-size: 1.6rem !important;
  }
}
