.hero-section-main {
  display: flex;
  height: 50.375rem;
  flex-direction: column;
  align-items: center;
  gap: 13.5rem;
  margin-top: -1rem;
  align-self: stretch;
  background: #5f22d9;
}
.hero-section-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 10rem;
}
.hero-section-about-us-title {
  color: white;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.hero-section-about-us-sub-desc {
  text-align: center;
  padding: 1rem 1rem;
  color: white;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 320px) {
  .hero-section-main {
    height: auto;
    padding-bottom: 5rem;
  }
  .hero-section-about-us-title {
    font-size: 2rem;
  }
  .hero-section-about-us-sub-desc {
    font-size: 0.96rem;
    padding: 0.4rem;
  }
}

@media screen and (min-width: 321px) and (max-width: 420px) {
  .hero-section-main {
    height: auto;
    padding-bottom: 6rem;
  }
  .hero-section-about-us-title {
    font-size: 2.2rem;
    padding: 0.8rem;
  }
  .hero-section-about-us-sub-desc {
    font-size: 1rem;
    padding: 0.7rem;
  }
}
@media screen and (min-width: 421px) and (max-width: 520px) {
  .hero-section-main {
    height: auto;
    padding-bottom: 7rem;
  }
  .hero-section-about-us-title {
    font-size: 2.4rem;
    padding: 1rem;
  }
  .hero-section-about-us-sub-desc {
    font-size: 1.2rem;
    padding: 0.8rem;
  }
}
@media screen and (min-width: 521px) and (max-width: 768px) {
  .hero-section-main {
    height: auto;
    padding-bottom: 8rem;
  }
  .hero-section-about-us-title {
    font-size: 2.45rem;
    padding: 1.2rem;
  }
  .hero-section-about-us-sub-desc {
    font-size: 1.24rem;
    padding: 0.89rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .hero-section-main {
    height: auto;
    padding-bottom: 10rem;
  }
  .hero-section-about-us-title {
    font-size: 2.6rem;
    padding: 1.34rem;
  }
  .hero-section-about-us-sub-desc {
    font-size: 1.34rem;
    padding: 0.99rem;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1880px) {
  .hero-section-main {
    height: auto;
    padding-bottom: 18rem;
  }
  .hero-section-about-us-title {
    font-size: 3.57rem;
    padding: 2rem;
  }
  .hero-section-about-us-sub-desc {
    font-size: 2rem;
    padding: 1rem;
  }
}
