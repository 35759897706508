.testimonal-card-container {
  display: flex;
  padding: 1rem;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.25rem;
  background: var(--neutrals-mercury, #e4e4e4);
}

.testimonal-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8125rem;
}

.testimonal-profile-image {
  width: 6.5625rem;
  height: 6.5625rem;
  border-radius: 4.875rem;
  background: url("../../assets/images/testimonalprofile.png"),
    lightgray 50% / cover no-repeat;
}

.testimonal-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.testimonal-card-header-title {
  display: flex;
  align-items: flex-end;
}
.testimonal-card-header-title-name {
  color: var(--primary-purple-heart, #5f22d9);
  text-align: center;

  /* Compotent/Heading/Semibold/03 */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.testimonal-card-header-title-profile {
  color: var(--base-black, #000);
  text-align: center;

  /* Compotent/Text/Regular/06 */
  font-family: Poppins;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 177%; /* 1.77rem */
}

.testimonal-card-role {
  color: var(--base-black, #000);
  text-align: center;

  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 177%; /* 1.99125rem */
}
.testimonal-card-description {
  color: var(--base-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 177%; /* 1.77rem */
}
.testimonal-card-starts-container {
  display: flex;
  align-items: flex-start;
  gap: 0.125rem;
}
.testimonal-card-starts-container img {
  width: 1.375rem;
  height: 1.375rem;
  fill: var(--selective-yellow, #ffb703);
}
.transparent-star {
  width: 1.375rem;
  height: 1.375rem;
  fill: transparent;
}
.testimonal-card-transparent-container img {
  width: 1.375rem;
  height: 1.375rem;
  fill: transparent;
}
@media screen and (max-width: 768px) {
  .testimonial-card-container {
    max-width: 100%;
    margin-left: 0;
  }
  .testimonal-section-heading {
    font-size: 2.4rem;
  }
  .testimonal-card-header-title-name {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .testimonial-card-container {
    padding: 1rem;
  }
  .testimonal-section-heading {
    font-size: 2rem;
  }

  .testimonial-profile-image {
    width: 4rem;
    height: 4rem;
  }
  .testimonal-card-header-title-name {
    font-size: 1.1rem;
  }
  .testimonal-card-header-title-profile {
    font-size: 0.9rem;
    margin-left: 5px;
  }
}

.leftIcon,
.rightIcon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2.5rem;
  color: #000000;
  outline: none;
  position: relative;
  top: -13rem;
  font-weight: bold;
}
.leftIcon {
  left: -0.7rem;
}
.leftIcon:hover,
.rightIcon:hover {
  color: #555;
}

.slide-dots-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.slide-dot {
  width: 12px;
  height: 12px;
  background-color: #c0baba;
  border-radius: 50%;
  margin: 0 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slide-dot.active {
  background-color: #333;
}
.quote-icon {
  padding: 5px;
  font-size: 24px;
  color: rgb(255, 72, 0);
}

.testimonial-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .testimonial-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .testimonial-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
