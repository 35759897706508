.profile-card-name-user-heading {
  text-align: center;
  font-family: Poppins !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal;
}
.profile-card-name-user-heading-usertype {
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 177% !important;
}
.mentor-view-details-btn {
  text-align: right;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mentor-connect-btn {
  text-align: right;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
