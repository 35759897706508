.mentee-booking-details-input {
  background: #e5e5e5 !important;
  border-radius: 15px !important;
  font-size: 1rem !important;
  color: black !important;
  font-family: Poppins !important;
  height: 3.2rem !important;
}

.mentee-booking-details-input::placeholder {
  font-size: 1rem !important;
  color: black !important;
  font-family: Poppins !important;
}
.css-edb818[aria-checked="true"]:hover,
.css-edb818[data-checked]:hover,
.css-edb818[aria-checked="true"][data-hover],
.css-edb818[data-checked][data-hover] {
  background: inherit !important;
  border-color: inherit !important;
}
.css-edb818[aria-checked="true"],
.css-edb818[data-checked] {
  background: inherit !important;
  border-color: inherit !important;
  color: var(--chakra-colors-white);
}
.css-5jdg10 {
  color: black !important;
}
.mentee-details-booking-title {
  color: black;
  font-size: 25px;
  font-family: Poppins;
  font-weight: 600;
  word-wrap: break-word;
}
.mentee-details-booking-day {
  color: black;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  word-wrap: break-word;
}
.mentee-details-booking-duration {
  color: black;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  word-wrap: break-word;
}
.mentee-details-booking-duration-time {
  color: black;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 400;
  word-wrap: break-word;
}
.mentee-booking-details-lable-text {
  color: var(--neutrals-black, #000000);
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}
.mentee-booking-details-extra-check-text {
  color: var(--neutrals-black, #000000);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.error-message {
  color: red;
  font-family: Poppins;
  font-size: 0.86rem;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.booking-confirmation-model-heading-desc {
  color: black;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 400;
  word-wrap: break-word;
  text-align: center;
}

.booking-confirmation-model-heading-confirm-text {
  color: black;
  font-size: 30px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
  text-align: center;
}
