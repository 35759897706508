.hero-section-container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem;
  margin-top: 3rem;
}

.hero-section-container-heading {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  align-items: center;
  gap: 1.125rem;
}
.hero-section-heading-text {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hero-section-heading-text-desc {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hero-section-numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}
.hero-event-numbers-sec {
  width: 100%;
  background: var(--light-mode-grey-200, #e5e5e5);
}

.hero-section-events {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section-events-number {
  color: var(--light-mode-black, #000);
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
.hero-section-events-number-desc {
  color: var(--light-mode-black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 320px) {
  .hero-section-numbers-content {
    gap: 0.92rem !important;
  }
  .hero-section-heading-text {
    font-size: 1.7rem;
    font-weight: 500;
  }
  .hero-section-heading-text-desc {
    font-size: 1.16rem;
    font-weight: normal;
  }
  .hero-section-events-number {
    font-size: 1.2rem;
  }
  .hero-section-events-number-desc {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .hero-section-numbers-content {
    gap: 1.4rem !important;
  }
  .hero-section-heading-text {
    font-size: 1.76rem;
    font-weight: 500;
  }
  .hero-section-heading-text-desc {
    font-size: 1.2rem;
    font-weight: normal;
  }
  .hero-section-events-number {
    font-size: 1.28rem;
  }
  .hero-section-events-number-desc {
    font-size: 1.1rem;
    text-align: start;
    white-space: pre;
  }
}
@media only screen and (min-width: 481px) and (max-width: 820px) {
  .hero-section-heading-text {
    font-size: 2.2rem;
    font-weight: 510;
  }
  .hero-section-heading-text-desc {
    font-size: 1.28rem;
    font-weight: normal;
  }
  .hero-section-events-number {
    font-size: 1.8rem;
  }
  .hero-section-events-number-desc {
    font-size: 1.4rem;
  }
  .hero-section-numbers-content {
    gap: 5rem !important;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .hero-section-heading-text {
    font-size: 2.8rem;
    font-weight: 500;
  }
  .hero-section-heading-text-desc {
    font-size: 1.66rem;
    font-weight: normal;
  }
  .hero-section-events-number {
    font-size: 2rem;
  }
  .hero-section-events-number-desc {
    font-size: 1.54rem;
  }
  .hero-section-numbers-content {
    gap: 8rem !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1240px) {
  .hero-section-heading-text {
    font-size: 2.89rem;
    font-weight: 500;
  }
  .hero-section-heading-text-desc {
    font-size: 1.786rem;
    font-weight: normal;
  }
  .hero-section-events-number {
    font-size: 2.3rem;
  }
  .hero-section-events-number-desc {
    font-size: 1.8rem;
  }
  .hero-section-numbers-content {
    gap: 10rem !important;
  }
}
@media only screen and (min-width: 1241px) and (max-width: 1460px) {
  .hero-section-heading-text {
    font-size: 3.5rem;
    font-weight: 500;
  }
  .hero-section-heading-text-desc {
    font-size: 1.834rem;
    font-weight: normal;
  }
  .hero-section-events-number {
    font-size: 2.58rem;
  }
  .hero-section-events-number-desc {
    font-size: 1.9rem;
  }
  .hero-section-numbers-content {
    gap: 10.3rem !important;
  }
}
.hero-section-heading-text b {
  font-weight: bold !important;
}
