.mentor-onboarding-page-heading-text {
  color: #333333;
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
  word-wrap: break-word;
}

.mentor-onboarding-page-sub-heading-text {
  color: #333333;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}

.mentor-onboarding-page-heading-input-text {
  color: #333333;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}

.mentor-onboarding-page-heading-input {
  background-color: #e5e5e5;
  border-radius: 15px;
  height: 3.1rem !important;

  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
}

.mentor-onboarding-page-heading-input::placeholder {
  color: #333333;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
}

.css-14iyyou {
  color: #333333 !important;
  font-size: 16px !important;
  font-family: Poppins !important;
  font-weight: 450 !important;
  word-wrap: break-word !important;
}

.css-vtg3j9[aria-checked="true"],
.css-vtg3j9[data-checked] {
  background: rgb(255, 255, 255) !important;
  border-color: rgb(0, 0, 0) !important;
  color: black !important;
}
.css-vtg3j9 {
  border-color: #e5e5e5 !important;
}
.mentor-onboarding-page--personal-heading-text {
  color: #333333;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}

.mentor-onboarding-service-choose-heading {
  color: #333333;
  font-size: 30px;
  font-family: Poppins;
  font-weight: 600;
  word-wrap: break-word;
}

.wrap-choose-cat ul {
  padding: 0px !important;
}
.wrap-choose-cat ul li span {
  padding: 10px !important;
}

.mentor-onboard-cat-tag {
  font-size: 15px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}

.back-to-home-mentor-onboard {
  background-color: #5f22d9;
  color: white;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word;
}
